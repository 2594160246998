import React, { useMemo } from 'react';
import { getDateDiaryData } from './statUtil';
import { DiaryPeriod } from './DiaryPeriod';
import { useTimesAsDecimal } from './useTimesAsDecimal';
import { StatisticDay } from '../TherapyCourseOverview/types';

/**
 * The properties of the main diary period.
 * @property statisticDate The date to render the diary period for.
 * @property cutDateAsStr The cut-off date as a string in the format 'YYYY-MM-DD'.
 * @property statistics The array of statistics to search for the given date.
 * @property dayHeight The height of the day in pixels.
 * @property hourWidth The width of an hour in pixels.
 * @property isNextDay Whether the diary period is for the next day.
 */
type MainDiaryPeriodProps = {
  statisticDate: Date;
  cutDateAsStr: string;
  statistics: StatisticDay[];
  dayHeight: number;
  hourWidth: number;
  isNextDay: boolean;
};

/**
 * Renders the main diary period for a given date, including bedtime, sleeptime, wakeuptime, and gettinguptime.
 * Handles wrap-around if midnight is between laydown and wakeup times.
 * @param props The properties of the main diary period.
 * @returns The rendered diary period component.
 */
export function MainDiaryPeriod(props: MainDiaryPeriodProps) {
  const {
    statisticDate,
    cutDateAsStr,
    statistics,
    dayHeight,
    hourWidth,
    isNextDay,
  } = props;

  // get the diary data for the given date
  const { laydowntime, bedtime, sleeptime, wakeuptime, gettinguptime } =
    useMemo(
      () => getDateDiaryData(statisticDate, statistics),
      [statisticDate, statistics]
    );

  // calculate the decimal values for the diary data
  const defaultDecimal = isNextDay ? 24 : 0;

  const {
    laydowntimeDecimal,
    bedtimeDecimal,
    gettinguptimeDecimal,
    sleeptimeDecimal,
    wakeuptimeDecimal,
  } = useTimesAsDecimal(
    laydowntime,
    cutDateAsStr,
    defaultDecimal,
    bedtime,
    sleeptime,
    wakeuptime,
    gettinguptime
  );

  return (
    <DiaryPeriod
      key={`${cutDateAsStr}-${isNextDay ? 'next' : 'current'}`}
      laydownDecimal={laydowntimeDecimal}
      bedtimeDecimal={bedtimeDecimal}
      gettinguptimeDecimal={gettinguptimeDecimal}
      dayHeight={dayHeight}
      hourWidth={hourWidth}
      sleeptimeDecimal={sleeptimeDecimal}
      wakeuptimeDecimal={wakeuptimeDecimal}
    />
  );
}
