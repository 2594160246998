import { useContext } from 'react';
import { AdminContext, TAdminData } from '../contexts/AdminContext';

export const usePortalUsers = (): TAdminData => {
  const context = useContext(AdminContext);

  if (!context) {
    throw new Error('usePortalUsers must be used within an AdminProvider');
  }

  return context;
};
