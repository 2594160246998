/**
 * @module TherapyCourseEditor/PhasePatient
 * @desc This module provides a form for editing a patient's tasks in a phase of a therapy course.
 */
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { DocumentReference } from 'firebase/firestore';
import {
  Patient,
  TherapyCoursePhasePatient,
  TherapyCoursePhase,
} from '../../firebase/firebaseModels';
import { CircularProgress, Switch, Typography } from '@mui/material';
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
} from 'react';
import useTaskPoolCollection from '../../firebase/useTaskPoolCollection';
import { getTranslatableField } from '../../services/i18n';
import { TaskPoolOption } from '../../hooks/useTaskPoolOptions';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { useTheme } from '@mui/material/styles';
import { useStrictDroppable } from '../../hooks/useStrictDroppable';

type PhasePatientProps = {
  patient: Patient;
  patientRef: DocumentReference<Patient>;
  currentPhase: number;
  phase: TherapyCoursePhase;
  setPhases: Dispatch<SetStateAction<TherapyCoursePhase[]>>;
  taskPoolOptions: TaskPoolOption[];
  language: string;
};

export function PhasePatient(props: PhasePatientProps) {
  const { status: taskPoolStatus, data: taskPoolData } =
    useTaskPoolCollection();

  const { patient, currentPhase, patientRef, phase, setPhases } = props;

  const phasePatients = useMemo(() => phase.patients || [], [phase.patients]);

  const phasePatient = useMemo(
    () =>
      phasePatients.find((p) => p.id === patientRef.id) ||
      ({
        id: patientRef.id,
        patient: patientRef,
        activeTasks: {},
      } as TherapyCoursePhasePatient),
    [phasePatients, patientRef]
  );

  const phaseTasks = phase.phaseTasks || [];

  let patientActiveTasks = useMemo(
    () => phasePatient.activeTasks || ({} as Record<string, boolean>),
    [phasePatient.activeTasks]
  );

  const handleCheckboxChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, taskId: string) => {
      const newPatient = {
        ...phasePatient,
        activeTasks: { ...patientActiveTasks, [taskId]: e.target.checked },
      };
      const newPatients = [
        ...phasePatients.filter((p) => p.id !== phasePatient.id),
        newPatient,
      ];
      setPhases((phases) => {
        const newPhases = phases.map((p) => {
          if (p.id === phase.id) {
            return {
              ...p,
              patients: newPatients,
            };
          } else {
            return p;
          }
        });
        console.log('newPhases', newPhases);
        return newPhases;
      });
    },
    [patientActiveTasks, phase.id, phasePatient, phasePatients, setPhases]
  );

  const theme = useTheme();
  const [enabled] = useStrictDroppable(!taskPoolData);

  const getItemStyle = useCallback(
    (isDragging: boolean, draggableStyle?: any): React.CSSProperties => ({
      userSelect: 'none',
      padding: theme.spacing(1),
      background: isDragging ? theme.palette.action.hover : 'transparent',
      ...draggableStyle,
      display: 'flex',
      alignItems: 'center',
      border: '1px solid',
      borderColor: theme.palette.divider,
      borderRadius: theme.shape.borderRadius,
    }),
    [theme]
  );

  const getListStyle = useCallback(
    (isDraggingOver: boolean): React.CSSProperties => ({
      padding: theme.spacing(1),
    }),
    [theme]
  );

  const onDragEnd = useCallback(
    (result: any) => {
      if (!result.destination) return;

      // Get current task order
      const currentTaskOrder = phasePatient?.taskOrder || {};

      // Get all task IDs and sort them by current order
      const taskIds = phaseTasks.map((task) => task.id);
      const sortedTaskIds = [...taskIds].sort(
        (a, b) => (currentTaskOrder[a] || 999) - (currentTaskOrder[b] || 999)
      );

      // Reorder the sorted array based on drag result
      const [movedItem] = sortedTaskIds.splice(result.source.index, 1);
      sortedTaskIds.splice(result.destination.index, 0, movedItem);

      // Calculate new order values
      const newTaskOrder: Record<string, number> = {};
      sortedTaskIds.forEach((taskId, index) => {
        newTaskOrder[taskId] = (index + 1) * 10;
      });

      // Update phase patient with new task order
      const newPhasePatient = {
        ...phasePatient,
        taskOrder: newTaskOrder,
      };

      // Update phases state
      setPhases((phases) => {
        return phases.map((p, idx) => {
          if (idx === currentPhase) {
            return {
              ...p,
              patients: p.patients.map((pat) =>
                pat.id === patient.id ? newPhasePatient : pat
              ),
            };
          }
          return p;
        });
      });
    },
    [currentPhase, phasePatient, phaseTasks, patient.id, setPhases]
  );

  return (
    <Card
      key={`${patient.licenceCode}-phase-${currentPhase}`}
      variant="outlined"
    >
      <CardContent>
        {taskPoolStatus === 'loading' ? (
          <CircularProgress />
        ) : (
          <>
            <Typography gutterBottom variant="h6">
              {patient.licenceCode}{' '}
              {patient.remark ? `- ${patient.remark}` : ''}
            </Typography>

            <DragDropContext onDragEnd={onDragEnd}>
              {enabled && (
                <Droppable droppableId={`tasks-${patient.id}`}>
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                    >
                      {phaseTasks
                        .sort((a, b) => {
                          const orderA = phasePatient?.taskOrder?.[a.id] || 999;
                          const orderB = phasePatient?.taskOrder?.[b.id] || 999;
                          return orderA - orderB;
                        })
                        .map((task, index) => {
                          const taskId = task.id;
                          const taskPoolTask = taskPoolData?.find(
                            (t) => t.id === taskId
                          );

                          return (
                            taskPoolTask && (
                              <Draggable
                                key={taskId}
                                draggableId={taskId}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                  >
                                    <div {...provided.dragHandleProps}>
                                      <DragIndicatorIcon
                                        sx={{ mr: 1, color: 'text.secondary' }}
                                      />
                                    </div>
                                    <FormGroup row>
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            checked={
                                              taskId in patientActiveTasks
                                                ? patientActiveTasks[taskId]
                                                : true
                                            }
                                            onChange={(e) =>
                                              handleCheckboxChange(e, taskId)
                                            }
                                          />
                                        }
                                        label={getTranslatableField(
                                          taskPoolTask.title
                                        )}
                                      />
                                    </FormGroup>
                                  </div>
                                )}
                              </Draggable>
                            )
                          );
                        })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              )}
            </DragDropContext>
          </>
        )}
      </CardContent>
    </Card>
  );
}
