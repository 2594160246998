import { useMemo } from 'react';
import { getTranslatableField } from '../services/i18n';
import { Breadcrumbs, CircularProgress } from '@mui/material';
import { UIMatch, useMatches, Link } from 'react-router-dom';
import { CrumbData } from '../hooks/useDashboardRoutes';
import { useTranslation } from 'react-i18next';
import useTherapyCourseCollection from '../firebase/useTherapyCourseCollection';
import usePatientCollection from '../firebase/usePatientCollection';
import useTherapyPlanCollection from '../firebase/useTherapyPlanCollection';
import useTaskPoolCollection from '../firebase/useTaskPoolCollection';
import useTaskPoolTaskCategoryCollection from '../firebase/useTaskPoolTaskCategoryCollection';

export default function BreadcrumbTrail() {
  const { t } = useTranslation();
  const matches = useMatches() as UIMatch<any, any>[];
  const {
    status: therapyCourseCollectionStatus,
    data: therapyCourseCollectionData,
  } = useTherapyCourseCollection('all', false);
  const { status: patientCollectionStatus, data: patientCollectionData } =
    usePatientCollection();
  const {
    status: therapyPlanCollectionStatus,
    data: therapyPlanCollectionData,
  } = useTherapyPlanCollection();
  const { status: taskPoolStatus, data: taskPoolData } =
    useTaskPoolCollection();

  const { status: taskPoolTaskCategoryStatus, data: taskPoolTaskCategoryData } =
    useTaskPoolTaskCategoryCollection();

  let crumbs = useMemo(
    () =>
      matches
        .filter((match) => Boolean(match.handle?.crumb))
        .map((match) => {
          const params = match.params as CrumbData;
          const pathname = match.pathname;

          if (pathname.endsWith('/new')) {
            return {
              label: t('Add New'),
              to: match.pathname,
            };
          }
          const {
            therapyCourseId,
            patientId,
            therapyPlanId,
            taskPoolTaskId,
            taskPoolTaskCategoryId,
          } = params;
          let therapyCourseTitle =
            therapyCourseCollectionData?.find(
              (course) => course.id === therapyCourseId
            )?.title || null;

          let patientTitle =
            patientCollectionData?.find((patient) => patient.id === patientId)
              ?.licenceCode || '';

          let therapyPlanTitle =
            therapyPlanCollectionData?.find((plan) => plan.id === therapyPlanId)
              ?.title || null;

          let taskPoolTaskTitle =
            taskPoolData?.find((task) => task.id === taskPoolTaskId)?.title ||
            null;

          let taskPoolTaskCategoryTitle =
            taskPoolTaskCategoryData?.find(
              (category) => category.id === taskPoolTaskCategoryId
            )?.title || null;

          const label = match.handle.crumb({
            ...params,
            therapyCourseTitle: therapyCourseTitle
              ? getTranslatableField(therapyCourseTitle)
              : therapyCourseId,
            patientTitle: patientTitle,
            therapyPlanTitle: therapyPlanTitle
              ? getTranslatableField(therapyPlanTitle)
              : therapyPlanId,
            taskPoolTaskTitle: taskPoolTaskTitle
              ? getTranslatableField(taskPoolTaskTitle)
              : taskPoolTaskId,
            taskPoolTaskCategoryTitle: taskPoolTaskCategoryTitle
              ? getTranslatableField(taskPoolTaskCategoryTitle)
              : taskPoolTaskCategoryId,
          });
          return {
            label,
            to: match.pathname,
          };
        }),
    [
      matches,
      patientCollectionData,
      t,
      taskPoolData,
      taskPoolTaskCategoryData,
      therapyCourseCollectionData,
      therapyPlanCollectionData,
    ]
  );

  const numCrumbs = crumbs.length;

  if (crumbs.length === 0) {
    return null;
  }
  if (
    therapyCourseCollectionStatus === 'loading' ||
    patientCollectionStatus === 'loading' ||
    therapyPlanCollectionStatus === 'loading' ||
    taskPoolStatus === 'loading' ||
    taskPoolTaskCategoryStatus === 'loading'
  ) {
    return (
      <Breadcrumbs aria-label="breadcrumb">
        <span key={0}>
          <CircularProgress size="1em" />
        </span>
      </Breadcrumbs>
    );
  }
  return (
    <Breadcrumbs aria-label="breadcrumb">
      {crumbs.map((crumb, index) =>
        index === numCrumbs - 1 ? (
          <span key={index}>{crumb.label}</span>
        ) : (
          <Link key={index} to={crumb.to}>
            {crumb.label}
          </Link>
        )
      )}
    </Breadcrumbs>
  );
}
