import { useTranslation, Trans } from 'react-i18next';
import { Paper, Typography, CircularProgress } from '@mui/material';
import {
  DataGrid,
  GridRowId,
  GridToolbar,
  GridColDef,
  GridRowModel,
  GridCellParams,
  GridActionsCellItem,
  GridRowModes,
  GridRowModesModel,
  GridEventListener,
  GridRowEditStopReasons,
  GridCellModes,
} from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { useCallback, useState, MouseEvent, useMemo } from 'react';
import { useFirestore } from 'reactfire';
import { Patient } from '../firebase/firebaseModels';
import Alert from '@mui/material/Alert';
import usePatientCollection from '../firebase/usePatientCollection';
import useUserProvider from '../firebase/useUserProvider';
import LoadingButton from '@mui/lab/LoadingButton';
import { doc, deleteDoc, updateDoc } from 'firebase/firestore';
import { useAddPatient } from '../firebase/useAddPatient';
import { useFirebaseUser } from '../firebase/useFirebaseUser';
import Tooltip from '@mui/material/Tooltip';
import PendingIcon from '@mui/icons-material/Pending';

export default function Patients() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const firestore = useFirestore();
  const { email } = useFirebaseUser();

  const { data: userProviderData, status: userProviderStatus } =
    useUserProvider();
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteId, setDeleteId] = useState<string | null>(null);
  const [dialogTitle, setDialogTitle] = useState<string | null>(null);
  const [dialogContent, setDialogContent] = useState<string | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const {
    status: patientStatus,
    data,
    error: patientError,
  } = usePatientCollection();

  const [licenceCode, setLicenceCode] = useState('');
  const [remark, setRemark] = useState('');

  const handleDelete = useCallback(
    (patientId: string, patientLicenceCode: string) => {
      setDeleteId(patientId);
      setDialogTitle(t('Delete Patient') + ' ' + patientLicenceCode);
      setDialogContent(t('Are you sure you want to delete this patient?'));
      setDialogOpen(true);
    },
    [t]
  );

  const handleCloseDialog = useCallback(() => setDialogOpen(false), []);

  const currentLocale = i18n.language;

  const confirmDelete = useCallback(() => {
    setDialogOpen(false);
    if (!deleteId || !userProviderData?.id || !email) {
      return;
    }
    console.log('Delete patient with id', deleteId);
    const ref = doc(
      firestore,
      'ServiceProvider',
      userProviderData.id,
      'UserData',
      email,
      'Patient',
      deleteId
    );
    deleteDoc(ref)
      .then(() => {
        console.log('Document successfully deleted!');
      })
      .catch((error) => {
        console.error('Error removing document: ', error);
        setError(error);
      });
  }, [deleteId, firestore, userProviderData, email]);

  const {
    addPatient,
    addPatientError,
    isUpdating: isAddingPatient,
  } = useAddPatient(email);

  const onEditPatient = useCallback(
    async (updateId: string, updateData: Partial<Patient>) => {
      if (!updateId || !userProviderData?.id || !email) {
        return;
      }
      const ref = doc(
        firestore,
        'ServiceProvider',
        userProviderData.id,
        'UserData',
        email,
        'Patient',
        updateId
      );
      updateDoc(ref, updateData)
        .then(() => {
          console.log('Document successfully updated!');
        })
        .catch((error) => {
          console.error('Error updating document: ', error);
          setError(error);
        });
    },
    [firestore, userProviderData, email]
  );

  const handleRowEditStop: GridEventListener<'rowEditStop'> = useCallback(
    (params, event) => {
      if (params.reason === GridRowEditStopReasons.rowFocusOut) {
        event.defaultMuiPrevented = true;
      }
    },
    []
  );

  const handleCancelClick = useCallback(
    (id: GridRowId) => () => {
      setRowModesModel({
        ...rowModesModel,
        [id]: { mode: GridRowModes.View, ignoreModifications: true },
      });
    },
    [rowModesModel]
  );

  const handleSaveClick = useCallback(
    (id: GridRowId) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    },
    [rowModesModel]
  );

  const processRowUpdate = useCallback(
    (newRow: GridRowModel<Patient>) => {
      const updatedRow = { ...newRow };
      if (newRow.id) {
        onEditPatient(newRow.id, {
          remark: newRow.remark,
        });
      }
      return updatedRow;
    },
    [onEditPatient]
  );

  const handleRowModesModelChange = useCallback(
    (newRowModesModel: GridRowModesModel) => {
      setRowModesModel(newRowModesModel);
    },
    []
  );

  const handleCellClick = useCallback(
    (params: GridCellParams, event: MouseEvent) => {
      if (!params.isEditable) {
        return;
      }

      // Ignore portal
      if (
        (event.target as any).nodeType === 1 &&
        !event.currentTarget.contains(event.target as Element)
      ) {
        return;
      }

      const rowId = params.id;

      setRowModesModel((prevModel) => {
        return {
          // Revert the mode of the other cells from other rows
          ...Object.keys(prevModel).reduce(
            (acc, id) => ({
              ...acc,
              [id]: Object.keys(prevModel[id]).reduce(
                (acc2, field) => ({
                  ...acc2,
                  [field]: { mode: GridRowModes.View },
                }),
                {}
              ),
            }),
            {}
          ),
          [rowId]: {
            // Revert the mode of other cells in the same row
            ...Object.keys(prevModel[params.id] || {}).reduce(
              (acc, field) => ({
                ...acc,
                [field]: { mode: GridCellModes.View },
              }),
              {}
            ),
            [params.field]: { mode: GridCellModes.Edit },
            mode: GridRowModes.Edit,
          },
        };
      });
    },
    []
  );

  const columns: GridColDef<Patient>[] = useMemo(
    () =>
      [
        {
          field: 'licenceCode',
          headerName: t('License Code'),
          width: 140,
          valueGetter: (_, row) => row.licenceCode,
          renderCell: (params) => (
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              {params.row.pending && (
                <Tooltip title={t('Patient has not yet registered in the app')}>
                  <PendingIcon
                    sx={{
                      color: 'warning.main',
                      fontSize: '1.2rem',
                    }}
                  />
                </Tooltip>
              )}
              {params.value}
            </div>
          ),
        },
        {
          field: 'remark',
          headerName: t('Personal Identifier'),
          flex: 1,
          editable: true,
          valueGetter: (_, row) => row.remark,
          cellClassName: 'editable-cell',
          renderCell: (params) => (
            <div className="editable-cell">
              {params.value}
              <EditIcon className="edit-icon" fontSize="small" />
            </div>
          ),
        },
        {
          field: 'created',
          type: 'dateTime',
          headerName: t('Added'),
          width: 180,
          valueGetter: (_, row) => row.created.toDate(),
          renderCell: (params) => {
            return params.value.toLocaleString(currentLocale);
          },
        },
        {
          field: 'actions',
          type: 'actions',
          width: 100,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          getActions: ({ id, row }) => {
            const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

            if (isInEditMode) {
              return [
                <Tooltip title={t('Save')}>
                  <GridActionsCellItem
                    icon={<SaveIcon />}
                    label={t('Save')}
                    sx={{
                      color: 'primary.main',
                    }}
                    onClick={handleSaveClick(id)}
                  />
                </Tooltip>,
                <Tooltip title={t('Cancel')}>
                  <GridActionsCellItem
                    icon={<CancelIcon />}
                    label={t('Cancel')}
                    className="textPrimary"
                    onClick={handleCancelClick(id)}
                    color="inherit"
                  />
                </Tooltip>,
              ];
            }
            const route = `/admin/patients/${id}`;

            return [
              <Tooltip title={t('Edit')}>
                <GridActionsCellItem
                  disabled={!row.id}
                  icon={<EditIcon />}
                  label={t('Edit')}
                  className="textPrimary"
                  color="inherit"
                  onClick={() => navigate(route)}
                />
              </Tooltip>,
              <Tooltip title={t('Delete')}>
                <GridActionsCellItem
                  disabled={!row.id}
                  icon={<DeleteIcon />}
                  label={t('Delete')}
                  onClick={() =>
                    handleDelete(row.id || '', row.licenceCode || '')
                  }
                  color="inherit"
                />
              </Tooltip>,
            ];
          },
        },
      ] as GridColDef<Patient>[],
    [
      t,
      currentLocale,
      rowModesModel,
      handleSaveClick,
      handleCancelClick,
      navigate,
      handleDelete,
    ]
  );

  const handleAddPatient = useCallback(
    () =>
      addPatient(licenceCode, remark, () => {
        setLicenceCode('');
        setRemark('');
      }),
    [addPatient, licenceCode, remark]
  );

  if (userProviderStatus === 'loading') {
    return <CircularProgress />;
  }

  if (patientStatus === 'loading') {
    return <CircularProgress />;
  }

  return (
    <>
      <Paper variant="outlined" sx={{ my: 2, p: 2 }}>
        <Typography variant="h5" component="h2" sx={{ mb: 2 }}>
          <Trans>New Patient</Trans>
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          <Trans>Add a new patient by entering the license code.</Trans>
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <TextField
              name="licence-code"
              label={t('License Code to Add')}
              placeholder={t('Enter License Code')}
              value={licenceCode}
              onChange={(event) =>
                setLicenceCode(event.target.value.toLocaleUpperCase())
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={9}>
            <TextField
              name="personal-identifier"
              label={t('Personal Identifier')}
              placeholder={t('Enter Personal Identifier')}
              value={remark}
              onChange={(event) => setRemark(event.target.value)}
              fullWidth
            />
          </Grid>
        </Grid>
        {addPatientError && (
          <Alert severity="error" sx={{ mt: 2 }}>
            <Trans>{addPatientError.message}</Trans>
          </Alert>
        )}
        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error.message}
          </Alert>
        )}
        <LoadingButton
          disabled={!licenceCode && licenceCode.length < 6}
          loading={isAddingPatient}
          variant="contained"
          startIcon={<AddIcon />}
          sx={{ mt: 2 }}
          onClick={handleAddPatient}
        >
          <Trans>Add Patient</Trans>
        </LoadingButton>
      </Paper>
      <Paper variant="outlined" sx={{ my: { xs: 3 }, p: { xs: 2, md: 3 } }}>
        <Typography variant="h5" component="h2" sx={{ mb: 2 }}>
          {t('Patients')}
        </Typography>
        {patientError ? (
          <Alert severity="error" sx={{ mb: 2 }}>
            <Typography variant="body1">
              <Trans>There was an error loading the data</Trans>
            </Typography>
            {patientError.code === 'permission-denied' && (
              <Typography variant="body2">
                <Trans>
                  You do not have permission to view this data. Please contact
                  your administrator.
                </Trans>
              </Typography>
            )}
          </Alert>
        ) : (
          <DataGrid
            initialState={{
              sorting: {
                sortModel: [{ field: 'created', sort: 'desc' }],
              },
            }}
            sx={{
              '& .editable-cell': {
                display: 'flex',
                alignItems: 'center',
                '& .edit-icon': {
                  opacity: 0,
                  marginLeft: 1,
                  transition: 'opacity 0.2s',
                },
                '&:hover': {
                  backgroundColor: 'action.hover',
                  cursor: 'pointer',
                  '& .edit-icon': {
                    opacity: 1,
                  },
                },
              },
            }}
            autoHeight
            editMode="cell"
            rows={data || []}
            columns={columns}
            rowSelection={false}
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            onCellClick={handleCellClick}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
          />
        )}
      </Paper>

      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {dialogTitle || t('Delete Patient')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogContent || (
              <Trans>Are you sure you want to delete this patient?</Trans>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            <Trans>Cancel</Trans>
          </Button>
          <Button onClick={confirmDelete} color="primary" autoFocus>
            <Trans>Confirm</Trans>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
