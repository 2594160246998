import { useContext } from 'react';
import {
  TUserProvider,
  UserProviderContext,
} from '../contexts/UserProviderContext';

export default function useUserProvider(): TUserProvider {
  const context = useContext(UserProviderContext);
  if (context === null) {
    throw new Error(
      'useUserProvider must be used within a UserProviderProvider'
    );
  }
  return context;
}
