import { useCallback, useState, useEffect } from 'react';
import { updateDoc, doc, Timestamp } from 'firebase/firestore';
import { useFirestore, useFunctions } from 'reactfire';
import { Patient, UserViewRequest } from './firebaseModels';
import { httpsCallable } from '@firebase/functions';
import usePortalUserDoc from './usePortalUserDoc';
import { useAppUser } from '../contexts/PatientContext';

export default function useRequestToViewPatientData(
  patient: Patient | null,
  therapistEmail: string | null
) {
  const [requestSent, setRequestSent] = useState<boolean | null>(null);
  const [requestError, setRequestError] = useState<string | null>(null);
  const [approved, setApproved] = useState<boolean | null>(null);
  const [denied, setDenied] = useState<boolean | null>(null);
  const functions = useFunctions();
  const sendNotification = httpsCallable(functions, 'sendnotification');
  const firestore = useFirestore();

  const patientUserDoc = useAppUser(patient);

  const { data: therapistData, status: therapistDataStatus } =
    usePortalUserDoc(therapistEmail);

  useEffect(() => {
    if (therapistDataStatus === 'error') {
      setRequestError('Therapist data not found');
    }
  }, [therapistDataStatus]);

  useEffect(() => {
    if (!patientUserDoc || !therapistData) {
      return;
    }

    const existingRequest = patientUserDoc.viewRequests?.find(
      (request) => request.requestedBy === therapistData.email
    );

    if (existingRequest) {
      setApproved(existingRequest.approved);
      setDenied(existingRequest.denied);
      setRequestSent(true);
    } else {
      setRequestSent(false);
    }
  }, [patientUserDoc, therapistData]);

  const sendRequest = useCallback(async () => {
    if (!therapistData?.email) {
      setRequestError('Therapist ID not found');
      return;
    }

    if (!patientUserDoc || !patient) {
      setRequestError('Patient user not found');
      return;
    }

    if (!patientUserDoc.id) {
      setRequestError('Patient user ID not found');
      return;
    }

    // request already sent?
    const existingRequest = patientUserDoc.viewRequests?.find(
      (request) => request.requestedBy === therapistData.email
    );

    if (existingRequest && existingRequest.approved) {
      setRequestSent(true);
      setApproved(existingRequest.approved);
      setDenied(existingRequest.denied);
      setRequestError('Request already approved');
      return;
    }

    const request: UserViewRequest = {
      approved: null,
      denied: null,
      requestedAt: Timestamp.now(),
      requestedBy: therapistData.email,
      therapistName: therapistData.name,
      validFrom: null,
      validTo: null,
    };

    try {
      const patientUserRef = doc(firestore, 'Users', patientUserDoc.id);
      await updateDoc(patientUserRef, {
        viewRequests: [
          ...(patientUserDoc.viewRequests?.filter(
            (request) => request.requestedBy !== therapistData.email
          ) || []),
          request,
        ],
      });
      const patientLanguage = patientUserDoc.language || 'en';
      let messages: { [key: string]: { title: string; body: string } } = {
        en: {
          title: 'Request to View Data',
          body: `${therapistData.name} has requested to view your data.`,
        },
        fi: {
          title: 'Pyyntö tietojen katseluun',
          body: `${therapistData.name} pyytää nähdä tietosi.`,
        },
        sv: {
          title: 'Begäran om att se data',
          body: `${therapistData.name} har begärt att se dina data.`,
        },
      };
      const { title, body } = messages[patientLanguage] || messages['en'];

      const response = await sendNotification({
        userId: patientUserDoc?.id,
        title,
        body,
        therapistName: therapistData.name,
        type: 'requestToViewPatientData',
      });

      console.log('sendNotification response', response);
      setRequestSent(true);
    } catch (error: any) {
      setRequestError(error.message);
    }
  }, [
    firestore,
    patient,
    patientUserDoc,
    sendNotification,
    therapistData?.email,
    therapistData?.name,
  ]);

  return {
    sendRequest,
    requestSent,
    requestError,
    approved,
    denied,
  };
}
