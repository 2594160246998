import React, { useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getFormattedDate } from '../../utils';
import { getTimeAsDecimal } from './statUtil';
import { DATE_LABEL_WIDTH } from './DayView';
import HourDividers from './HourDividers';

type AverageDayViewProps = {
  fullWidth: number;
  dayWidth: number;
  hourWidth: number;
  dayHeight: number;
  averageLayDownTime: Date | null;
  averageWakeupTime: Date | null;
};

export function AverageDayView(props: AverageDayViewProps) {
  const { t } = useTranslation();
  const {
    dayWidth,
    hourWidth,
    dayHeight,
    averageLayDownTime,
    averageWakeupTime,
  } = props;

  const yesterDayAsStr = getFormattedDate(
    new Date(new Date().getTime() - 86400000)
  );
  const curDatAsStr = getFormattedDate(new Date());

  return useMemo(() => {
    const avgLaydownDecimal = getTimeAsDecimal(
      averageLayDownTime,
      yesterDayAsStr,
      24
    );
    const avgLaydownDecimalLeft = getTimeAsDecimal(
      averageLayDownTime,
      curDatAsStr,
      0
    );

    const avgWakeupTimeDecimal = getTimeAsDecimal(
      averageWakeupTime,
      curDatAsStr,
      0
    );

    const laydownPeriod1 = avgLaydownDecimal !== null &&
      avgWakeupTimeDecimal !== null && (
        <Box
          sx={{
            position: 'absolute',
            height: dayHeight,
            left: `${avgLaydownDecimal * hourWidth}%`,
            width: `${(24 - avgLaydownDecimal) * hourWidth}%`,
            backgroundColor: '#c1d5ec',
          }}
        />
      );

    const laydownPeriod2 = avgLaydownDecimalLeft !== null &&
      avgLaydownDecimal !== null &&
      avgWakeupTimeDecimal !== null && (
        <Box
          sx={{
            position: 'absolute',
            height: dayHeight,
            left: `${avgLaydownDecimalLeft * hourWidth}%`,
            width: `${
              (avgWakeupTimeDecimal - avgLaydownDecimalLeft) * hourWidth
            }%`,
            backgroundColor: '#c1d5ec',
          }}
        />
      );

    return (
      <Box
        sx={{
          width: `${dayWidth}%`,
          position: 'relative',
          overflow: 'hidden',
          mb: '2px',
        }}
      >
        <Box
          sx={{
            width: `${dayWidth}%`,
            height: dayHeight,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              height: dayHeight,
              mb: 0.25,
            }}
          >
            {/* Date label */}
            <Box
              sx={{
                display: 'inline-flex',
                position: 'absolute',
                width: `${DATE_LABEL_WIDTH}px`,
                height: dayHeight,
                alignItems: 'center',
              }}
            >
              <Typography variant="caption">{t('Average')}</Typography>
            </Box>
            {/* Chart area containing all sleep and activity indicators */}
            <Box
              sx={{
                display: 'flex',
                position: 'relative',
                overflow: 'hidden',
                height: dayHeight,
                width: `${dayWidth}%`,
                ml: `${DATE_LABEL_WIDTH}px`,
              }}
            >
              {laydownPeriod1}
              {laydownPeriod2}
              {/* Visual dividers for hours */}
              <HourDividers hourWidth={hourWidth} />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: `${dayWidth}%`,
            ml: `${DATE_LABEL_WIDTH}px`,
            pr: `${DATE_LABEL_WIDTH}px`,
          }}
        >
          {['00', '04', '08', '12', '16', '20', '24'].map((label) => (
            <Typography key={label} variant="caption">
              {label}
            </Typography>
          ))}
        </Box>
      </Box>
    );
  }, [
    averageLayDownTime,
    yesterDayAsStr,
    averageWakeupTime,
    curDatAsStr,
    dayHeight,
    hourWidth,
    t,
    dayWidth,
  ]);
}
