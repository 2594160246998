import React, { useMemo } from 'react';
import { Box, Typography } from '@mui/material';

/**
 * Renders the label for the diary period.
 * Creates a label with text shadow for better visibility.
 */
export default function DiaryPeriodLabel(props: {
  label: string | null;
  dayHeight: number;
  left: number;
  width: number;
}) {
  const { label, dayHeight, left, width } = props;
  const fontHeight = dayHeight - 10;

  const labelContainerStyle = useMemo(
    () => ({
      height: dayHeight,
      left: `${left - 10}%`,
      width: `${width + 20}%`,
      backgroundColor: 'transparent',
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [dayHeight, left, width]
  );

  const labelTextStyle = useMemo(
    () => ({
      position: 'absolute',
      overflow: 'visible',
      padding: 4,
      color: 'white',
      fontWeight: 600,
      textAlign: 'center',
      textShadow:
        '1px 1px 1px black, -1px -1px 1px black, -1px 1px 1px black, 1px -1px 1px black',
      fontSize: fontHeight,
    }),
    [fontHeight]
  );

  if (!label) {
    return null;
  }

  return (
    <Box sx={labelContainerStyle}>
      <Typography sx={labelTextStyle}>{label}</Typography>
    </Box>
  );
}
