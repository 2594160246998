import React from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface HourDividersProps {
  hourWidth: number; // The width of each hour segment in pixels.
}

/**
 * HourDividers component renders vertical dividers for each hour in a 24-hour period.
 *
 * @param props - The component props.
 * @returns An array of React nodes representing hour dividers.
 */
export default function HourDividers(props: HourDividersProps) {
  const { hourWidth } = props;
  const theme = useTheme();
  const hourDividers: React.ReactNode[] = [];

  // Generate dividers for each hour (0-24)
  for (let hour = 0; hour <= 24; hour++) {
    const left = hour === 24 ? undefined : `${hour * hourWidth}%`;
    const right = hour !== 24 ? undefined : 0;
    hourDividers.push(
      <Box
        key={`hourdiv-${hour}`}
        sx={{
          position: 'absolute',
          height: '100%',
          width: '1px',
          opacity: 0.2,
          left,
          right,
          backgroundColor: theme.palette.text.primary,
        }}
      />
    );
  }

  return <>{hourDividers}</>;
}
