import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { TranslatableField } from '../firebase/firebaseModels';

export const LANGUAGE_NAMES: { [key: string]: string } = {
  en: 'English',
  fi: 'Suomi',
  sv: 'Svenska',
};

export const UI_LANGUAGE_OPTIONS = [
  {
    code: 'en-US',
    name: 'English (American)',
  },
  {
    code: 'fi-FI',
    name: 'Suomi',
  },
];

export const THERAPY_LANGUAGE_OPTIONS = [
  {
    code: 'en',
    name: 'English',
  },
  {
    code: 'fi',
    name: 'Finnish',
  },
  {
    code: 'sv',
    name: 'Swedish',
  },
];

export const getCurrentLanguageCode = () => {
  const code = i18next.language.split('-')[0];
  if (code in LANGUAGE_NAMES) {
    return code;
  }

  return 'en';
};
/**
 * Translations for the application.
 */
const resources = {
  en: {
    translation: {
      'Sign In': 'Sign In',
      'Email Address': 'Email Address',
      Password: 'Password',
      'Remember me': 'Remember me',
      Cancel: 'Cancel',
      'Forgot password?': 'Forgot password?',
      Console: 'Console',
      'Front Page': 'Front Page',
      'User Settings': 'User Settings',
      'First Name': 'First Name',
      'Last Name': 'Last Name',
      Email: 'Email',
      Settings: 'Settings',
      'User Data': 'User Data',
      'Display Name': 'Display Name',
      'Change Password': 'Change Password',
      'Current Password': 'Current Password',
      'New Password': 'New Password',
      'Confirm New Password': 'Confirm New Password',
      'Nothing here yet': 'Nothing here yet',
      'Copyright © ': 'Copyright © ',
      Name: 'Name',
      'Therapy Courses': 'Therapy Courses',
      'Therapy Plans': 'Therapy Plans',
      'Title Done': 'Title When Done',
      'Upload Image': 'Upload Image',
      Order: 'Order',
      Type: 'Type',
      Task: 'Task',
      Generic: 'Generic',
      Questionnaire: 'Questionnaire',
      Homework: 'Homework',
      Morning: 'Morning',
      Evening: 'Evening',
      Actions: 'Actions',
      'Delete Task': 'Delete Task',
      'Are you sure you want to delete this task?':
        'Are you sure you want to delete this task?',
      Confirm: 'Confirm',
      'New Task': 'New Task',
      'Allow User to Set A Reminder Notification':
        'Allow User to Set A Reminder Notification',
      Translation: 'Translation',
      Translations: 'Translations',
      'Select the language to edit': 'Select the language to edit',
      Image: 'Image',
      Other: 'Other',
      Options: 'Options',
      Question: 'Question',
      Info: 'Info',
      Audio: 'Audio',
      Video: 'Video',
      URL: 'URL',
      Sections: 'Sections',
      Section: 'Section',
      'Upload Audio': 'Upload Audio',
      'Upload Video': 'Upload Video',
      'New Therapy Course': 'New Therapy Course',
      'New Therapy Plan': 'New Therapy Plan',
      'Home Task': 'Home Task',
      'TherapyCourseTask not found': 'Therapy course not found',
      'Completed Date': 'Completed Date',
      'vertexAI/fetch-error': 'Error fetching translation suggestion',
      'auth/invalid-email': 'Invalid email address',
      'auth/user-not-found': 'User not found',
      HHMM: 'HH:MM',
      Help: 'Help',
      'help-docs': {
        'user-guide': 'User Guide',
        'admin-guide': 'Administrator Guide',
        'user-guide-url':
          'https://wiki.oneirostherapies.com/en/public/K%C3%A4ytt%C3%B6ohjeet/K%C3%A4ytt%C3%B6ohjeLoppuk%C3%A4ytt%C3%A4j%C3%A4',
        'admin-guide-url':
          'https://wiki.oneirostherapies.com/en/public/K%C3%A4ytt%C3%B6ohjeet/KonsolinYll%C3%A4pit%C3%A4j%C3%A4nOhje',
      },
      'Portal Users': 'Console Users',
      Role: 'Role',
      user: 'User',
      admin: 'Admin',
      superadmin: 'Superadmin',
      reseller: 'Reseller',
      'Filter by Role': 'Filter by Role',
      'All Roles': 'All Roles',
      Provider: 'Provider',
      'All Providers': 'All Providers',
      Trial: 'Trial',
      'New User': 'New User',
      'Delete User': 'Delete User',
      'Search provider': 'Search provider',
      'User Details': 'User Details',
      'Email is required': 'Email is required',
      'You do not have permission to edit users':
        'You do not have permission to edit users',
      'Update successful': 'Update successful',
      'Send Invitation': 'Send Invitation',
      Phone: 'Phone',
      None: 'None',
      Save: 'Save',
      'Are you sure you want to delete this user?':
        'Are you sure you want to delete this user?',
      'Invitation sent successfully': 'Invitation sent successfully',
      'Invite New Patient': 'Invite New Patient',
      'Add Existing Patient': 'Add Existing Patient',
      'Add a patient to the course either by inviting them or by selecting an existing patient.':
        'Add a patient to the course either by inviting them or by selecting an existing patient.',
      'Invite Patient': 'Invite Patient',
      'or enter manually': 'or enter manually',
      'User already exists. SMS invitation sent.':
        'User already exists. SMS invitation sent.',
      'One or more selected patients have not yet registered in the app.':
        'One or more selected patients have not yet registered in the app.',
      Pending: 'Pending',
      'Patient has not yet registered in the app':
        'Patient has not yet registered in the app',
      'This patient has not yet registered in the app':
        'This patient has not yet registered in the app',
      'Delete App User': 'Delete App User',
      'Warning: This action cannot be undone':
        'Warning: This action cannot be undone',
      'Danger Zone': 'Danger Zone',
      'This will permanently delete the user and all associated data, including:':
        'This will permanently delete the user and all associated data, including:',
      'User profile and authentication': 'User profile and authentication',
      'Statistics and diary entries': 'Statistics and diary entries',
      'Activity logs': 'Activity logs',
      'License association': 'License association',
      'Phone Number': 'Phone Number',
      'Enter phone number in the international format (e.g., +358401234567)':
        'Enter phone number in the international format (e.g., +358401234567)',
      'Phone number is required': 'Phone number is required',
      'User Deleted Successfully': 'User Deleted Successfully',
      'Deletion Failed': 'Deletion Failed',
      'Statistics deleted': 'Statistics deleted',
      'Diary entries deleted': 'Diary entries deleted',
      'Logs deleted': 'Logs deleted',
      'License released': 'License released',
      'User data deleted': 'User data deleted',
      'Auth user deleted': 'Auth user deleted',
      Yes: 'Yes',
      No: 'No',
      History: 'History',
      pts: 'pts',
      Summary: 'Summary',
      'Copy All': 'Copy All',
      'Error loading task history': 'Error loading task history',
      'No task selected': 'No task selected',
      'No markings found for this task': 'No markings found for this task',
      'Copied!': 'Copied!',
      'Generic Tasks': 'Generic Tasks',
      Questionnaires: 'Questionnaires',
      'Homework Tasks': 'Homework Tasks',
      'Morning Tasks': 'Morning Tasks',
      'Evening Tasks': 'Evening Tasks',
      'Sleep Restriction Tasks': 'Sleep Restriction Tasks',
      'Select Tasks': 'Select Tasks',
      'Search tasks...': 'Search tasks...',
    },
  },
  fi: {
    translation: {
      'Sign In': 'Kirjaudu sisään',
      'Email Address': 'Sähköpostiosoite',
      Password: 'Salasana',
      'Remember me': 'Muista minut',
      Cancel: 'Peruuta',
      'Forgot password?': 'Unohditko salasanasi?',
      Console: 'Konsoli',
      'Front Page': 'Etusivu',
      'User Settings': 'Käyttäjäasetukset',
      'First Name': 'Etunimi',
      'Last Name': 'Sukunimi',
      Email: 'Sähköposti',
      Settings: 'Asetukset',
      'User Data': 'Käyttäjätiedot',
      'Display Name': 'Näyttönimi',
      'Change Password': 'Vaihda salasana',
      'Current Password': 'Nykyinen salasana',
      'New Password': 'Uusi salasana',
      'Confirm New Password': 'Vahvista uusi salasana',
      'Nothing here yet': 'Ei vielä mitään',
      'Copyright © ': 'Copyright © ',
      Name: 'Nimi',
      'Therapy Courses': 'Terapiajaksot',
      'Therapy Plans': 'Terapiamallit',
      'Task Pool': 'Tehtävävalikoima',
      ID: 'ID',
      Title: 'Otsikko',
      Description: 'Kuvaus',
      Languages: 'Kielet',
      Save: 'Tallenna',
      'Task Pool Task Editor': 'Tehtävän muokkaus',
      Language: 'Kieli',
      Finnish: 'Suomi',
      English: 'Englanti',
      Swedish: 'Ruotsi',
      Edit: 'Muokkaa',
      'Title Done': 'Otsikko valmiina',
      'Upload Image': 'Lataa kuva',
      Order: 'Järjestys',
      Type: 'Tyyppi',
      Generic: 'Yleinen',
      Task: 'Tehtävä',
      Questionnaire: 'Kysely',
      Homework: 'Kotitehtävä',
      Morning: 'Aamu',
      Evening: 'Ilta',
      'Sleep Restriction': 'Vuoteessaolosuunnitelma',
      Actions: 'Toiminnot',
      'Delete Task': 'Poista tehtävä',
      'Are you sure you want to delete this task?':
        'Haluatko varmasti poistaa tämän tehtävän?',
      Confirm: 'Vahvista',
      'New Task': 'Uusi tehtävä',
      'Allow User to Set A Reminder Notification':
        'Salli käyttäjän asettaa muistutusilmoitus',
      Translation: 'Käännös',
      Translations: 'Käännökset',
      'Select the language to edit': 'Valitse kieli muokataksesi',
      Image: 'Kuva',
      Other: 'Muu',
      Options: 'Asetukset',
      Question: 'Kysymys',
      Info: 'Info',
      Audio: 'Ääni',
      Video: 'Video',
      URL: 'URL',
      Sections: 'Osiot',
      Section: 'Osio',
      'Upload Audio': 'Lataa ääni',
      'Upload Video': 'Lataa video',
      'Upload PDF': 'Lataa PDF',
      'New Empty Therapy Course': 'Uusi tyhjä terapiajakso',
      'New Therapy Plan': 'Uusi terapiamalli',
      'Therapy Course Editor': 'Terapiajakson muokkaus',
      'Therapy Plan Editor': 'Terapiamallin muokkaus',
      'Licence Code To Add': 'Lisättävä lisenssikoodi',
      Patients: 'Potilaat',
      'Select the patients for the group': 'Valitse jakson potilaat',
      'License Code to Add': 'Lisättävä lisenssikoodi',
      Add: 'Lisää',
      Delete: 'Poista',
      Tasks: 'Tehtävät',
      'Select the tasks for the interval': "'Valitse tehtävät aikajaksolle",
      'Add Section': 'Lisää osio',
      Remark: 'Huomautus',
      'Personal Identifier': 'Henkilökohtainen tunniste',
      'Error loading patient': 'Virhe ladattaessa potilasta',
      'Patient updated successfully': 'Potilas päivitetty',
      'Delete Therapy Course': 'Poista terapiajakso',
      'Delete Therapy Plan': 'Poista terapiamalli',
      'Are you sure you want to delete this therapy course?':
        'Haluatko varmasti poistaa tämän terapiajakson?',
      'Are you sure you want to delete this therapy plan?':
        'Haluatko varmasti poistaa tämän terapiamallin?',
      'Update successful': 'Päivitys onnistui',
      'Send Invitation': 'Lähetä kutsu',
      'Save successful': 'Tallennus onnistui',
      Basics: 'Perustiedot',
      'Therapy Course Name': 'Terapiajakson nimi',
      'Therapy Plan Name': 'Terapiamallin nimi',
      'Invalid password': 'Virheellinen salasana',
      'Passwords do not match': 'Salasanat eivät täsmää',
      'Password must be at least 8 characters long.':
        'Salasanan tulee olla vähintään 8 merkkiä pitkä.',
      'Password must contain at least one lowercase letter.':
        'Salasanan tulee sisältää vähintään yksi pieni kirjain.',
      'Password must contain at least one uppercase letter.':
        'Salasanan tulee sisältää vähintään yksi iso kirjain.',
      'Password must contain at least one number.':
        'Salasanan tulee sisältää vähintään yksi numero.',
      'Password must contain at least one special character.':
        'Salasanan tulee sisältää vähintään yksi erikoismerkki.',
      'License Code': 'Lisenssikoodi',
      'Add Patient': 'Lisää potilas',
      'New Patient': 'Uusi potilas',
      'Licence not found': 'Lisenssiä ei löytynyt',
      'Licence code does not match': 'Lisenssikoodi ei täsmää',
      'Delete Patient': 'Poista potilas',
      'Are you sure you want to delete this patient?':
        'Haluatko varmasti poistaa tämän potilaan?',
      'Add a new patient by entering the license code.':
        'Lisää uusi potilas syöttämällä lisenssikoodi.',
      Created: 'Luotu',
      Added: 'Lisätty',
      Provider: 'Tarjoaja',
      Organization: 'Organisaatio',
      Default: 'Vakio',
      'Add New Therapy Course': 'Lisää uusi terapiajakso',
      'Add New Therapy Plan': 'Lisää uusi terapiamalli',
      'Open Pdf': 'Avaa PDF',
      'Single Patients': 'Yksilöterapia',
      'Personal Tasks': 'Henkilökohtaiset tehtävät',
      'Edit Patient': 'Muokkaa potilasta',
      'Default Tasks': 'Oletustehtävät',
      'Edit Therapy Course': 'Muokkaa terapiajaksoa',
      'Edit Therapy Plan': 'Muokkaa terapiamallia',
      'Edit Task': 'Muokkaa tehtävää',
      'Task updated successfully': 'Tehtävä päivitetty',
      'Error uploading image': 'Virhe ladattaessa kuvaa',
      'Error uploading video': 'Virhe ladattaessa videota',
      'Error uploading audio': 'Virhe ladattaessa ääntä',
      'Error getting download URL': 'Virhe ladattaessa URL-osoitetta',
      'Error uploading pdf': 'Virhe ladattaessa PDF-tiedostoa',
      'Modified by': 'Muokannut',
      Modified: 'Muokattu',
      Phase: 'Vaihe',
      'Phase Name': 'Vaiheen nimi',
      'Start Date': 'Aloituspäivä',
      'End Date': 'Lopetuspäivä',
      'Add Phase': 'Lisää vaihe',
      'Add...': 'Lisää...',
      'Tasks for this Phase': 'Tehtävät tälle vaiheelle',
      'Patient Specific Tasks for this Phase':
        'Potilaskohtaiset tehtävät tälle vaiheelle',
      'Follow Phase Tasks': 'Seuraa vaiheen tehtäviä',
      'Start time is required': 'Aloitusaika vaaditaan',
      'Start time is in the past': 'Aloitusaika on menneisyydessä',
      'Start time should be at least a day after the previous phase start time':
        'Aloitusajan täytyy olla vähintään vuorokautta edellisen vaiheen aloitusajan jälkeen',
      'End time is recommended for the last phase':
        'Lopetusaika on suositeltava viimeiselle vaiheelle',
      'End time must be after the start time':
        'Lopetusajan on oltava aloitusajan jälkeen',
      'Please correct the errors in the phases': 'Korjaa vaiheiden virheet',
      Errors: 'Virheet',
      'Title is required in primary language':
        'Otsikko vaaditaan ensisijaisella kielellä',
      'Default Interval (Days)': 'Oletusväli (päivää)',
      'Default Session Time': 'Oletuskellonaika',
      'Default Tasks for this Phase': 'Oletustehtävät tälle vaiheelle',
      'Phases for this Plan': 'Vaiheet tälle mallille',
      'Phase Title': 'Vaiheen otsikko',
      'Are you sure you want to generate a therapy course?':
        'Haluatko varmasti luoda terapiajakson?',
      'Create Therapy Course': 'Luo terapiajakso',
      'Start Time': 'Aloitusaika',
      'End Time': 'Lopetusaika',
      Create: 'Luo',
      'Please select a start time': 'Valitse aloitusaika',
      'Please enter a title and start time for the course':
        'Anna otsikko ja aloitusaika jaksolle',
      Course: 'Jakso',
      'Create Therapy Course From Plan': 'Luo terapiajakso mallista',
      'Current Courses Overview': 'Käynnissä olevat jaksot',
      'Sleep Efficiency': 'Unen tehokkuus',
      'Task Completion': 'Tehtävien suorittaminen',
      "Confirm that you want to request access to this patient's data":
        'Vahvista, että haluat pyytää pääsyä tämän potilaan tietoihin',
      'Request Access': 'Pyydä pääsyä',
      'Request Access to Patient Data': 'Pyydä pääsyä potilaan tietoihin',
      Request: 'Pyydä',
      'Patient data access request pending':
        'Potilaan tietojen pääsypyyntö odottaa',
      'Patient has denied access request': 'Potilas on kieltänyt pääsypyynnön',
      'Request Access Again': 'Pyydä pääsyä uudelleen',
      'No patients in this course': 'Ei potilaita tässä jaksossa',
      'View Course': 'Näytä jakso',
      Patient: 'Potilas',
      'No access': 'Ei pääsyä',
      Statistics: 'Tilastot',
      Home: 'Koti',
      'Progress Over Time': 'Edistyminen ajan myötä',
      'Therapy Course': 'Terapiajakso',
      'Statistics Table': 'Tilastotaulukko',
      'Day Graph': 'Unijaksot',
      'Pending approval': 'Odottaa hyväksyntää',
      'Access denied': 'Pääsy evätty',
      'Current Phase': 'Nykyinen vaihe',
      Label: 'Kuvaus',
      Average: 'Keskiarvo',
      'N Values': 'N arvot',
      'Laid Down': 'Meni levolle',
      'Turned Off Light': 'Sammutti valot',
      'Got Out of Bed Time': 'Nousi sängystä',
      'Time in Bed': 'Aika sängyssä',
      'Sleep Onset Latency': 'Nukahtamisviive',
      'Wake After Sleep Onset': 'Heräily nukahtamisen jälkeen',
      'Total Sleep Time': 'Kokonaisuniaika',
      'Daytime Rest': 'Päivälepo',
      'Daytime Nap': 'Unessa päivälevon aikana',
      'Row #': 'Rivinro.',
      'No data': 'Ei dataa',
      "You do not have permission to view this patient's data":
        'Sinulla ei ole lupaa nähdä tämän potilaan tietoja',
      'Your request to view this patient data has been sent. Please wait for approval.':
        'Pyyntösi nähdä tämän potilaan tiedot on lähetetty. Odota hyväksyntää.',
      "Your request to view this patient's data has been denied.":
        'Pyyntösi nähdä tämän potilaan tiedot on hylätty.',
      'Delete Task Category': 'Poista tehtäväkategoria',
      'Are you sure you want to delete this task category?':
        'Haluatko varmasti poistaa tämän tehtäväkategorian?',
      'Task Categories': 'Tehtäväkategoriat',
      'New Task Category': 'Uusi tehtäväkategoria',
      'Edit Task Category': 'Muokkaa tehtäväkategoriaa',
      'Add New Task Category': 'Lisää uusi tehtäväkategoria',
      'Task Category Editor': 'Tehtäväkategorian muokkaus',
      'Task Category Name': 'Tehtäväkategorian nimi',
      Color: 'Väri',
      New: 'Uusi',
      Own: 'Omat',
      'Task Category': 'Tehtäväkategoria',
      Category: 'Kategoria',
      'Select task category...': 'Valitse tehtäväkategoria...',
      'Show to other users': 'Näytä muille käyttäjille',
      'Select Therapy Plan...': 'Valitse terapiamalli...',
      'Create From Therapy Plan...': 'Luo terapiamallista...',
      'Therapy Plan': 'Terapiamalli',
      'Create New Therapy Course': 'Luo uusi terapiajakso',
      'App Options': 'Sovellusasetukset',
      'Console Options': 'Hallinta-asetukset',
      'Web Link': 'Verkkolinkki',
      'Question Section': 'Kysymysosio',
      'Multiple Question': 'Monivalintakysymys',
      'Range Question': 'Vaihteluvälikysymys',
      'Free Text Question': 'Vapaatekstikysymys',
      Questions: 'Kysymykset',
      'Add Question': 'Lisää kysymys',
      Answers: 'Vastaukset',
      'Add Answer': 'Lisää vastaus',
      'Answer Title': 'Vastauksen otsikko',
      'Answer Value': 'Vastauksen arvo',
      'Home Task': 'Kotitehtävä',
      'Sleep Efficiency (lights off)': 'Unen tehokkuus (valot pois)',
      'Sleep Efficiency (inc. lights on)': 'Unen tehokkuus (sis. valot päällä)',
      'Time in Bed (lights off)': 'Aika sängyssä (valot pois)',
      'Time in Bed (inc. lights on)': 'Aika sängyssä (sis. valot päällä)',
      'Add New': 'Lisää uusi',
      'Therapy course not found': 'Terapiajaksoa ei löytynyt',
      'Current Courses': 'Käynnissä olevat',
      'Course View': 'Jakson tarkastelu',
      'Ongoing phase': 'Käynnissä oleva vaihe',
      'Upcoming phase': 'Tuleva vaihe',
      'Completed phase': 'Valmis vaihe',
      'Phase {{phase}}': 'Vaihe {{phase}}',
      'Activate this phase': 'Siirry tähän vaiheeseen',
      'Planned Date Interval': 'Suunniteltu päiväväli',
      'End date is in the past': 'Lopetuspäivä on menneisyydessä',
      'Complete therapy course': 'Päätä terapiajakso',
      'Are you sure you want to end this therapy course?':
        'Haluatko varmasti päättää tämän terapiajakson?',
      'Therapy course updated': 'Terapiajakso päivitetty',
      'Are you sure you want to restart this therapy course?':
        'Haluatko varmasti aloittaa tämän terapiajakson uudelleen?',
      'Course completed': 'Jakso päätetty',
      'TherapyCourseTask not found': 'Terapiajaksoa ei löytynyt',
      'Refresh patient statistics': 'Päivitä potilaan tilastot',
      Uncategorized: 'Luokittelematon',
      'Show this task to all users of organization':
        'Näytä tämä tehtävä organisaation kaikille käyttäjille',
      'Show this category to all users of organization':
        'Näytä tämä kategoria organisaation kaikille käyttäjille',
      'Show this therapy plan to all users of organization':
        'Näytä tämä terapiamalli organisaation kaikille käyttäjille',
      Administration: 'Hallinta',
      'User Profile': 'Käyttäjäprofiili',
      'Group Therapies': 'Ryhmäterapiat',
      'Individual Therapies': 'Yksilöterapiat',
      'Yes/No Question': 'Kyllä/Ei -kysymys',
      'Show Current Courses Only': 'Näytä vain käynnissä olevat jaksot',
      'No therapy courses found.': 'Yhtään terapiajaksoa ei löytynyt.',
      'Create a therapy course': 'Luo terapiajakso',
      'a group therapy': 'ryhmäterapia',
      or: 'tai',
      'an individual therapy': 'yksilöterapia',
      'Completed Date': 'Valmistumispäivä',
      Content: 'Sisältö',
      'Info Popup': 'Info Popup',
      'Upcoming Courses': 'Tulevat',
      'All Therapies': 'Kaikki terapiat',
      group: 'ryhmä',
      individual: 'yksilö',
      Group: 'Ryhmä',
      Individual: 'Yksilö',
      'Group Therapy': 'Ryhmäterapia',
      'Individual Therapy': 'Yksilöterapia',
      'Start course immediately': 'Käynnistä terapiajakso välittömästi',
      'Restart course': 'Käynnistä terapiajakso uudelleen',
      'Create New Course': 'Tee uusi',
      'New Therapy Course': 'Uusi terapiajakso',
      'Create blank course': 'Luo tyhjä jakso',
      'Please select a therapy plan to generate the course from or leave empty to create a blank course.':
        'Valitse terapiamalli, jonka mukaisen jakson haluat luoda, tai jätä tyhjäksi tehdäksesi tyhjän jakson.',
      'a therapy course': 'terapiajakso',
      'Edit therapy course': 'Muokkaa terapiajaksoa',
      'Waiting to Start': 'Alkamista odottavat',
      'Error adding patient': 'Virhe lisättäessä potilasta',
      'User not found': 'Käyttäjää ei löytynyt',
      'Licence data not found': 'Lisenssidataa ei löytynyt',
      'Group Name': 'Ryhmän nimi',
      'Please enter a name the group therapy course.':
        'Anna nimi ryhmäterapiajaksolle.',
      'Select patient...': 'Valitse potilas...',
      'Select...': 'Valitse...',
      'An optional start date for the course.':
        'Valinnainen aloituspäivä jaksolle.',
      'View Patient': 'Näytä potilas',
      'The number of days that will be automatically added to the start date of each phase.':
        'Päivien määrä, joka lisätään automaattisesti jokaisen vaiheen aloituspäivämäärään.',
      'Start Course Immediately': 'Käynnistä terapiajakso välittömästi',
      'View Course Session Overview': 'Näytä jakson istuntonäkymä',
      'Create New Course From Plan': 'Luo uusi jakso mallista',
      'Add a patient to the course either by entering their license code and optional personal identifier, or by selecting an existing patient.':
        'Lisää potilas jaksoon syöttämällä hänen lisenssikoodinsa ja valinnainen henkilökohtainen tunniste tai valitsemalla olemassa oleva potilas.',
      'The license code that has given to the patient':
        'Potilaalle annettu lisenssikoodi',
      'Information used to distinguish or identify an individual, without necessarily being as specific or formal as full legal names or government-issued identification numbers.':
        'Tieto, jota käytetään yksilön erottamiseen tai tunnistamiseen, ilman että sen tarvitsee olla yhtä tarkka tai virallinen kuin täydellinen virallinen nimi tai jokin viranomaisen myöntämä tunnistenumero.',
      'The date of the first session of the course. The field can be left empty.':
        'Jakson ensimmäisen istunnon päivämäärä. Kenttä voidaan jättää tyhjäksi.',
      'The therapy plan to generate the course from. If left empty, a blank course with no pre-defined tasks and just the first phase will be created.':
        'Terapiamalli, jonka mukaisen jakson haluat luoda. Jos jätetään tyhjäksi, luodaan tyhjä jakso ilman valmiita tehäviä ja vain ensimmäisellä vaiheella.',
      'The name of the group therapy course that will be created. Choose a name which helps you identify the group therapy course.':
        'Ryhmäterapiajaksolle annettava nimi. Valitse nimi, joka auttaa tunnistamaan ryhmäterapiajakson.',
      'Choose a patient who has already been entered into the system.':
        'Valitse potilas, joka on jo syötetty järjestelmään.',
      'Therapy Session Date': 'Terapiaistunnon pvm.',
      'Therapy session date is optional. If the date is left blank, it will be determined automatically based on when the phase is started.':
        'Terapiaistunnon päivämäärä on valinnainen. Jos päivämäärä jätetään tyhjäksi, se määritetään automaattisesti sen perusteella, milloin vaihe käynnistetään.',
      'Course End Date': 'Jakson päättymispvm.',
      'Patient Specific Selections For the Phase':
        'Potilaskohtaiset valinnat vaiheelle',
      'Therapy course end date is optional. If the date is left blank, it will be determined automatically based on the last phase end date.':
        'Terapiajakson lopetuspäivämäärä on valinnainen. Jos päivämäärä jätetään tyhjäksi, se määritetään automaattisesti viimeisen vaiheen lopetuspäivämäärän perusteella.',
      'No title available': 'Ei otsikkoa saatavilla',
      Status: 'Tila',
      Completed: 'Valmis',
      Ongoing: 'Käynnissä',
      'Translation suggestion': 'Käännösehdotus',
      Use: 'Käytä',
      'Enter Name': 'Anna nimi',
      'Enter Title': 'Anna otsikko',
      'Enter Question': 'Anna kysymys',
      'Enter Answer': 'Anna vastaus',
      "Enter Value (e.g. '1')": "Anna arvo (esim. '1')",
      "Enter URL (e.g. 'https://example.com')":
        "Anna URL (esim. 'https://esimerkki.fi')",
      'Enter Current Password': 'Anna nykyinen salasana',
      'Enter New Password': 'Anna uusi salasana',
      'Enter New Password Again': 'Anna uusi salasana uudelleen',
      'Enter phase name': 'Anna vaiheen nimi',
      'Enter License Code': 'Anna lisenssikoodi',
      'Enter Personal Identifier': 'Anna henkilökohtainen tunniste',
      'Enter Group Name': 'Anna ryhmän nimi',
      'Enter Email Address': 'Anna sähköpostiosoite',
      'Enter Password': 'Anna salasana',
      'Enter Task Category Name': 'Anna tehtäväkategorian nimi',
      'Enter Title When Task is Done': 'Anna nimi, kun tehtävä on tehty',
      'Choose Completed Date': 'Valitse valmistumispäivä',
      'Enter Therapy Plan Name': 'Anna terapiamallin nimi',
      "Enter Default Interval, e.g. 7'": 'Anna oletusväli, esim. 7 (päivää)',
      'Use Suggestion': 'Käytä ehdotusta',
      "Therapist's Name": 'Terapeutin nimi',
      'Fetch Translation Suggestion': 'Hae käännösehdotus',
      'Name is required in primary language':
        'Nimi vaaaditaan ensisijaisella kielellä',
      'Task Name': 'Tehtävän nimi',
      'Task Name When Done': 'Tehtävän nimi, kun tehty',
      'Enter Name When Done': 'Anna nimi tehtävän valmistuttua',
      'Audio Title': 'Äänen otsikko',
      'Question Title': 'Kysymyksen otsikko',
      'Question Description': 'Kysymyksen kuvaus',
      'vertexAI/fetch-error': 'Virhe noudettaessa tietoja AI-palvelusta',
      'Question Section Title': 'Kysymysosion otsikko',
      'Question Section Description': 'Kysymysosion kuvaus',
      'Audio Section Title': 'Ääniosion otsikko',
      'Audio Section Description': 'Ääniosion kuvaus',
      'Click to select audio file': 'Napauta valitaksesi äänitiedosto',
      'Image Section Title': 'Kuvaosion otsikko',
      'Image Section Description': 'Kuvaosion kuvaus',
      'Info Popup Section Title': 'Info Popup -osion otsikko',
      'Info Popup Section Description': 'Info Popup -osion kuvaus',
      'PDF Section Title': 'PDF-osion otsikko',
      'PDF Section Description': 'PDF-osion kuvaus',
      'Checkbox Section Title': 'Kyllä/Ei -osion otsikko',
      'Checkbox Section Description': 'Kyllä/Ei -osion kuvaus',
      'Radio Section Title': 'Monivalintakysymyksen otsikko',
      'Radio Section Description': 'Monivalintakysymyksen kuvaus',
      'Range Section Title': 'Vaihtelualueen otsikko',
      'Range Section Description': 'Vaihtelualueen kuvaus',
      'URL Section Title': 'URL-osion otsikko',
      'URL Section Description': 'URL-osion kuvaus',
      'Video Section Title': 'Video-osion otsikko',
      'Video Section Description': 'Video-osion kuvaus',
      'Primary Language': 'Ensisijainen kieli',
      'Set as Primary Language': 'Aseta ensisijaiseksi',
      'Are you sure?': 'Oletko varma?',
      'You are not the owner of this task category. Are you sure you want to save changes?':
        'Et ole tämän tehtäväkategorian omistaja. Oletko varma, että haluat tallentaa muutokset?',
      'You are not the owner of this task. Are you sure you want to save changes?':
        'Et ole tämän tehtävän omistaja. Oletko varma, että haluat tallentaa muutokset?',
      'You are not the owner of this plan. Are you sure you want to save changes?':
        'Et ole tämän suunnitelman omistaja. Oletko varma, että haluat tallentaa muutokset?',
      'Unknown Date': 'Tuntematon päivämäärä',
      Administrator: 'Ylläpitäjä',
      'Make Private': 'Muuta omaksi tehtäväksi',
      'Make Default': 'Muuta vakiotehtäväksi',
      'Are you sure you want to make this task global?':
        'Haluatko varmasti tehdä tästä tehtävästä vakiotehtävän?',
      'Are you sure you want to make this task private?':
        'Haluatko varmasti tehdä tästä tehtävästä yksityisen?',
      'Are you sure you want to make this plan global?':
        'Haluatko varmasti tehdä tästä suunnitelmasta vakiosuunnitelman?',
      'Are you sure you want to make this plan private?':
        'Haluatko varmasti tehdä tästä suunnitelmasta yksityisen?',
      'Are you sure you want to make this category public?':
        'Haluatko varmasti tehdä tästä kategoriasta julkisen?',
      'Are you sure you want to make this category private?':
        'Haluatko varmasti tehdä tästä kategoriasta yksityisen?',
      'Task Frequency': 'Tehtävän toistuvuus',
      Daily: 'Päivittäin',
      Once: 'Kerran',
      'You are not allowed to access this app.':
        'Sinulla ei ole oikeutta käyttää tätä sovellusta.',
      Logout: 'Kirjaudu ulos',
      'There was an error loading the data':
        'Tietoja ladattaessa tapahtui virhe',
      'You do not have permission to view this data. Please contact your administrator.':
        'Sinulla ei ole lupaa nähdä näitä tietoja. Ota yhteys ylläpitäjään.',
      'UI Language': 'Käyttöliittymän kieli',
      'Select UI Language': 'Valitse käyttöliittymän kieli',
      'Therapy Languages': 'Terapiakielet',
      'Select Therapy Languages': 'Valitse terapiakielet',
      'Patient data updated': 'Potilaan tiedot päivitetty',
      'Error updating patient data': 'Virhe päivitettäessä potilaan tietoja',
      'Missing or insufficient permissions.':
        'Puuttuvat tai riittämättömät oikeudet.',
      'Error fetching task pool data':
        'Virhe haeessa tehtävävalikoiman tietoja',
      'Error requesting access to patient data':
        'Virhe pyydettäessä pääsyä potilaan tietoihin',
      'Error Getting User': 'Virhe käyttäjää haettaessa',
      'Error Getting Task Pool': 'Virhe tehtävävalikoimaa haettaessa',
      'Error Getting Task Categories': 'Virhe tehtäväkategorioita haettaessa',
      'Error Getting Therapy Plan': 'Virhe terapiamallia haettaessa',
      'Who can see this plan': 'Kuka voi nähdä tämän suunnitelman',
      Duplicate: 'Kopioi',
      'Show/Hide': 'Näytä/Piilota',
      'Forgot Password?': 'Unohditko salasanasi?',
      'Request Password Reset': 'Pyydä salasanan nollaus',
      'Back to Sign In': 'Takaisin kirjautumiseen',
      'Password reset email sent!': 'Salasanan nollausviesti lähetetty!',
      Success: 'Onnistui',
      'auth/invalid-email': 'Virheellinen sähköpostiosoite',
      'auth/user-not-found': 'Käyttäjää ei löytynyt',
      View: 'Näytä',
      'Not done': 'Ei tehty',
      Done: 'Tehty',
      Error: 'Virhe',
      'Invalid patient id': 'Virheellinen potilaan tunniste',
      'Error updating patient': 'Virhe päivitettäessä potilaan tietoja',
      Search: 'Hae',
      'No therapy courses found with the search term.':
        'Yhtään terapiajaksoa ei löytynyt hakutermillä.',
      'The plan is not available in the selected language.':
        'Malli ei ole saatavilla valitsemallasi kielellä.',
      Warning: 'Varoitus',
      'Some tasks in the plan are not available in the selected language.':
        'Jotkin mallin tehtävät eivät ole saatavilla valitsemallasi kielellä.',
      'The plan has no phases.': 'Malli ei sisällä vaiheita.',
      'The plan has no tasks.': 'Malli ei sisällä tehtäviä.',
      'Course is active': 'Jakso on aktiivinen',
      'Course is not active': 'Jakso ei ole aktiivinen',
      'Active Therapy Course': 'Aktiivinen terapiajakso',
      'Inactive Therapy Course': 'Epäaktiivinen terapiajakso',
      'UserId not valid': 'Virheellinen käyttäjätunniste',
      "User's current license code doesn't match license":
        'Käyttäjän nykyinen lisenssikoodi ei vastaa',
      'Invalid user level': 'Virheellinen käyttäjätaso',
      'No licence code provided': 'Lisenssikoodia ei annettu',
      'Error finding licence': 'Lisenssiä ei löytynyt',
      'Error getting license': 'Virhe lisenssin hakemisessa',
      'Task Name When User Has Marked is Done':
        'Tehtävän nimi, kun käyttäjä on merkinut sen tehdyksi',
      'Enter the name the app user sees when task is done (optional)':
        'Anna nimi, kun käyttäjä näkee, että tehtävä on tehty (valinnainen)',
      'My Clinic': 'Klinikkani',
      'My Own': 'Omat',
      All: 'Kaikki',
      Standard: 'Vakiokokonaisuus',
      'Error Saving Therapy Plan': 'Virhe tallentamassa terapiamallia',
      'Error Adding Therapy Plan': 'Virhe lisättäessä terapiamallia',
      'Error Adding Task': 'Virhe lisättäessä tehtävää',
      'Error Updating Task': 'Virhe päivitettäessä tehtävää',
      'Statistics cache is updating': 'Tilastoja päivitetään',
      'Sleep Efficiency (Moving Average)': 'Unen tehokkuus (lka)',
      'Task Completion Percentage': 'Tehtävien suoritustaso',
      'Task Completion Percentage (Moving Average)':
        'Tehtävien suoritus-% (lka)',
      'Delete Image': 'Poista kuva',
      'Select from Library': 'Valitse kirjastosta',
      'Select Image': 'Valitse kuva',
      'Last 7 Days': 'Viimeiset 7 päivää',
      'No sleep statistics available for the last 7 days.':
        'Ei tilastotietoja saatavilla viimeiseltä 7 vuorokaudelta.',
      'Restricting Time in Bed': 'Vuoteessaolosuunnitelma',
      "Whole week's sleep efficiency: {{efficiency}}%":
        'Koko viikon unen tehokkuus: {{efficiency}}%',
      'Average bedtime: {{bedtime}}':
        'Sammuttanut valot keskimäärin klo: {{bedtime}}',
      'Average time asleep: {{timeAsleep}}':
        'Nukkunut keskimäärin: {{timeAsleep}}',
      'Average time in bed: {{timeInBed}}':
        'Vuoteessa keskimäärin: {{timeInBed}}',
      'Average wake up time: {{wakeuptime}}':
        'Herännyt keskimäärin klo: {{wakeuptime}}',
      'Suggested new target wake up time: {{wakeuptime}}':
        'Ehdotettu uusi heräämisaika: {{wakeuptime}}',
      'My Target Times': 'Tavoiteajat',
      'Target Bedtime': 'Nukahtamisen tavoiteaika',
      'Set target bedtime': 'Aseta tavoiteaika nukahtaa',
      'Target Wake Up Time': 'Tavoiteaika herätä',
      'Set target wake up time': 'Aseta tavoiteaika herätä',
      'Suggested target lay down time: {{suggestedTargetLaydownTime}}':
        'Ehdotettu tavoiteaika mennä levolle: {{suggestedTargetLaydownTime}}',
      'Suggested new target bed time: {{suggestedTargetBedtime}}':
        'Ehdotettu uusi valojensammutusaika: {{suggestedTargetBedtime}}',
      'Suggested new target wake up time: {{suggestedTargetWakeupTime}}':
        'Ehdotettu uusi tavoiteaika herätä: {{suggestedTargetWakeupTime}}',
      'Average lay down time: {{laydowntime}}':
        'Keskimääräinen levollemenoaika: {{laydowntime}}',
      'Target Lay Down Time': 'Tavoiteaika mennä levolle',

      'Plan Until Next Therapy Session':
        'Suunnitelma seuraavaan tapaamiseen asti',
      'I will get up from bed at': 'Nousen vuoteesta klo',
      'I will go to bed at': 'Menen vuoteeseen klo',
      'Statistics not available yet.': 'Tilastoja ei vielä saatavilla.',
      HHMM: 'HH:MM',
      'View ': 'Näytä',
      'Target time in bed: {{layDownTime}} - {{wakeUpTime}}':
        'Tavoiteaika vuoteessa: {{layDownTime}} - {{wakeUpTime}}',
      'Please enter a valid time (HH:MM)': 'Anna kelvollinen aika (HH:MM)',
      'Updating Statistics': 'Päivitetään tilastoja',
      'Please wait while we refresh the data...':
        'Odota kunnes tilastot on päivitetty...',
      '1 Week': '1 viikko',
      '2 Weeks': '2 viikkoa',
      'Move back 1 week': 'Siirry 1 viikkoa taaksepäin',
      'Move back 1 day': 'Siirry 1 päivä taaksepäin',
      'Move forward 1 day': 'Siirry 1 päivä eteenpäin',
      'Move forward 1 week': 'Siirry 1 viikkoa eteenpäin',
      'View data for the past week': 'Näytä viimeisen viikon tilastot',
      'View data for the past two weeks':
        'Näytä viimeisten kahden viikon tilastot',
      'View all available data': 'Näytä kaikki saatavilla olevat tilastot',
      'Take into account when calculating statistics':
        'Huomioi tilastojen laskennassa',
      Help: 'Ohje',
      'help-docs': {
        'user-guide': 'Käyttöohje',
        'admin-guide': 'Ylläpitäjän ohje',
        'user-guide-url':
          'https://wiki.oneirostherapies.com/fi/public/Käyttöohjeet/KäyttöohjeLoppukäyttäjä',
        'admin-guide-url':
          'https://wiki.oneirostherapies.com/fi/public/Käyttöohjeet/KonsolinYlläpitäjänOhje',
      },
      'Portal Users': 'Konsolin käyttäjät',
      Role: 'Rooli',
      user: 'Käyttäjä',
      admin: 'Ylläpitäjä',
      superadmin: 'Superadmin',
      reseller: 'Jälleenmyyjä',
      'Filter by Role': 'Suodata roolin mukaan',
      'All Roles': 'Kaikki roolit',
      'All Providers': 'Kaikki jälleenmyyjät',
      Trial: 'Kokeilu',
      'New User': 'Uusi käyttäjä',
      'Delete User': 'Poista käyttäjä',
      'Search provider': 'Hae tarjoajaa',
      'User Details': 'Käyttäjän tiedot',
      'Email is required': 'Sähköposti vaaditaan',
      'You do not have permission to edit users':
        'Sinulla ei ole oikeutta muokata käyttäjiä',
      Phone: 'Puhelin',
      None: 'Ei mitään',
      'Are you sure you want to delete this user?':
        'Haluatko varmasti poistaa tämän käyttäjän?',
      'Invitation sent successfully': 'Kutsu lähetetty onnistuneesti',
      'Invite New Patient': 'Kutsu uusi potilas',
      'Add Existing Patient': 'Lisää olemassa oleva potilas',
      'Add a patient to the course either by inviting them or by selecting an existing patient.':
        'Lisää potilas joko kutsumalla tai valitsemalla olemassa oleva potilas.',
      'Invite Patient': 'Kutsu potilas',
      'or enter manually': 'tai syötä manuaalisesti',
      'User already exists. SMS invitation sent.':
        'Käyttäjä on jo olemassa. SMS-kutsu lähetetty.',
      'One or more selected patients have not yet registered in the app.':
        'Yksi tai useampi valittu potilas ei ole vielä rekisteröitynyt sovellukseen.',
      Pending: 'Odottava',
      'Patient has not yet registered in the app':
        'Potilas ei ole vielä rekisteröitynyt sovellukseen',
      'This patient has not yet registered in the app':
        'Potilas ei ole vielä rekisteröitynyt sovellukseen',
      'Delete App User': 'Poista appikäyttäjä',
      'Warning: This action cannot be undone':
        'Varoitus: Tätä toimintoa ei voi kumota',
      'Danger Zone': 'Vaaravyöhyke',
      'This will permanently delete the user and all associated data, including:':
        'Tämä poistaa pysyvästi käyttäjän ja kaikki siihen liittyvät tiedot, mukaan lukien:',
      'User profile and authentication': 'Käyttäjäprofiili ja tunnistautuminen',
      'Statistics and diary entries': 'Tilastot ja päiväkirjamerkinnät',
      'Activity logs': 'Toimintalokit',
      'License association': 'Lisenssiyhteys',
      'Phone Number': 'Puhelinnumero',
      'Enter phone number in the international format (e.g., +358401234567)':
        'Anna puhelinnumero kansainvälisessä muodossa (esim. +358401234567)',
      'Phone number is required': 'Puhelinnumero vaaditaan',
      'User Deleted Successfully': 'Käyttäjä poistettu onnistuneesti',
      'Deletion Failed': 'Poisto epäonnistui',
      'Statistics deleted': 'Tilastoja poistettu',
      'Diary entries deleted': 'Päiväkirjamerkintöjä poistettu',
      'Logs deleted': 'Lokeja poistettu',
      'License released': 'Lisenssi vapautettu',
      'User data deleted': 'Käyttäjätiedot poistettu',
      'Auth user deleted': 'Tunnistautumistiedot poistettu',
      Yes: 'Kyllä',
      No: 'Ei',
      'User does not have admin privileges':
        'Käyttäjällä ei ole ylläpitäjän oikeuksia',
      History: 'Historia',
      pts: 'pistettä',
      'Total Points': 'Yhteispistemäärä',
      Summary: 'Yhteenveto',
      'Copy All': 'Kopioi kaikki',
      'Error loading task history': 'Virhe ladattaessa tehtävähistoriaa',
      'No task selected': 'Ei valittua tehtävää',
      'No markings found for this task': 'Ei merkintöjä tälle tehtävälle',
      'Copied!': 'Kopioitu!',
      'Generic Tasks': 'Yleiset tehtävät',
      Questionnaires: 'Kyselyt',
      'Homework Tasks': 'Kotitehtävät',
      'Morning Tasks': 'Aamutehtävät',
      'Evening Tasks': 'Iltatehtävät',
      'Sleep Restriction Tasks': 'Vuoteessaolosuunnitelman tehtävät',
      'Select Tasks': 'Valitse tehtävät',
      'Search tasks...': 'Hae tehtäviä...',
    },
  },
};

/**
 * i18next instance. Used for translations.
 */
i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection: {
      convertDetectedLanguage: (lng: string) => {
        console.log('convertDetectedLanguage', lng);
        return lng;
      },
    },

    resources,
    fallbackLng: 'en',
    // debug only when not in production
    // debug: process.env.NODE_ENV !== 'production',

    interpolation: {
      escapeValue: false,
    },
  });

export default i18next;

/**
 * Get the translatable field in the user's preferred language.
 */
export function getTranslatableField(
  field: TranslatableField,
  editorLanguage?: string
): string {
  const userLang = editorLanguage || getCurrentLanguageCode();

  if (!field) return '';
  if (userLang in field) {
    return field[userLang];
  }
  if (editorLanguage) {
    return '';
  }
  const firstLanguageOfField = Object.keys(field)[0];
  return field[firstLanguageOfField] + ' [' + firstLanguageOfField + ']';
}
