import { useContext } from 'react';
import { FirebaseUserContext } from '../contexts/FirebaseUserContext';

export function useFirebaseUser() {
  const context = useContext(FirebaseUserContext);
  if (context === undefined) {
    throw new Error(
      'useFirebaseUser must be used within a FirebaseUserProvider'
    );
  }
  return context;
}
