import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { useTranslation } from 'react-i18next';

/**
 * Component for displaying the footer of the application
 *
 * @param props Typography props
 * @returns JSX.Element
 */
export default function Copyright(props: any): JSX.Element {
  const { t } = useTranslation();
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {t('Copyright © ')}
      <Link color="inherit" href="https://www.oneirostherapies.com">
        {t('Oneiros Therapies Ltd.')}
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
