import { useTranslation } from 'react-i18next';
import {
  Box,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Typography,
  Alert,
  IconButton,
  Collapse,
  Paper,
  Divider,
  Button,
} from '@mui/material';
import { Patient, TaskPoolTask } from '../../firebase/firebaseModels';
import { useTaskMarkingHistory } from '../../hooks/useTaskMarkingHistory';
import { getTranslatableField } from '../../services/i18n';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useState } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

type TaskMarkingHistoryProps = {
  userId: string | null;
  task: TaskPoolTask | null;
  patient: Patient | null;
};

function TaskMarkingHistory({
  userId,
  task,
  patient,
}: TaskMarkingHistoryProps) {
  const { t } = useTranslation();
  const { markings, loading, error } = useTaskMarkingHistory(userId, task);
  const [expandedItem, setExpandedItem] = useState<number | null>(null);
  const [copiedIndex, setCopiedIndex] = useState<number | null>(null);
  const [copiedAll, setCopiedAll] = useState(false);

  const handleToggleExpand = (index: number) => {
    setExpandedItem(expandedItem === index ? null : index);
  };

  const handleCopyMarking = (marking: any, index: number) => {
    const text = formatMarkingAsText(marking);
    navigator.clipboard.writeText(text).then(() => {
      setCopiedIndex(index);
      setTimeout(() => setCopiedIndex(null), 2000);
    });
  };

  const handleCopyAll = () => {
    const text = markings.map(formatMarkingAsText).join('\n\n');
    navigator.clipboard.writeText(text).then(() => {
      setCopiedAll(true);
      setTimeout(() => setCopiedAll(false), 2000);
    });
  };

  const formatMarkingAsText = (marking: any) => {
    let text = '';

    // Add patient info if available
    if (patient) {
      text += `${patient.licenceCode}${
        patient.remark ? ` - ${patient.remark}` : ''
      }\n`;
    }

    // Add task title if available
    if (task) {
      text += `${getTranslatableField(task.title)}\n`;
    }

    // Add completion date/time
    text += `${t(
      'Completed Date'
    )}: ${marking.completedTime.toLocaleDateString()} ${marking.completedTime.toLocaleTimeString()}\n`;

    // Add points if available
    if (marking.totalPoints !== undefined) {
      text += `${t('Total Points')}: ${marking.totalPoints}/${
        marking.totalMaxPoints
      }\n`;
      text += `${t('Average')}: ${(
        marking.totalPoints / Object.keys(marking.answers).length
      ).toFixed(1)}/${(
        marking.totalMaxPoints / Object.keys(marking.answers).length
      ).toFixed(1)}\n`;
    }

    // Add answers
    if (marking.answers) {
      text += `\n${t('Answers')}:\n`;
      Object.entries(marking.answers)
        .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
        .forEach(([key, value]: [string, any]) => {
          const title =
            typeof value.title === 'object'
              ? getTranslatableField(value.title)
              : '';
          const valueText =
            value.type === 'range'
              ? `${value.value}/${value.maxValue}`
              : String(value.value);
          text += `${title}: ${valueText}\n`;
        });
    }
    return text;
  };

  const renderMarkingDetails = (marking: any) => {
    if (!marking.answers) return null;

    return (
      <Box sx={{ p: 2, bgcolor: 'background.default' }}>
        {marking.totalPoints !== undefined && marking.totalPoints > 0 && (
          <Paper
            elevation={0}
            sx={{
              p: 2,
              mb: 3,
              bgcolor: 'primary.light',
              color: 'primary.contrastText',
            }}
          >
            <Typography variant="h6" sx={{ mb: 1 }}>
              {t('Summary')}
            </Typography>
            <Box sx={{ display: 'flex', gap: 4 }}>
              <Box>
                <Typography variant="subtitle2">{t('Total Points')}</Typography>
                <Typography variant="h5">
                  {marking.totalPoints} / {marking.totalMaxPoints}
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle2">{t('Average')}</Typography>
                <Typography variant="h5">
                  {(
                    marking.totalPoints / Object.keys(marking.answers).length
                  ).toFixed(1)}{' '}
                  /{' '}
                  {(
                    marking.totalMaxPoints / Object.keys(marking.answers).length
                  ).toFixed(1)}
                </Typography>
              </Box>
            </Box>
          </Paper>
        )}

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {Object.entries(marking.answers)
            .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
            .map(([key, value]: [string, any]) => (
              <Paper
                key={key}
                elevation={0}
                sx={{ p: 2, bgcolor: 'background.paper' }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: 2,
                  }}
                >
                  <Typography variant="body1" sx={{ fontWeight: 500 }}>
                    {typeof value.title === 'object'
                      ? getTranslatableField(value.title)
                      : value.type === 'range'
                      ? `${value.value} / ${value.maxValue}`
                      : String(value.value)}
                  </Typography>
                  {value.maxValue && (
                    <Typography
                      variant="body2"
                      sx={{
                        color: 'primary.main',
                        px: 2,
                        py: 0.5,
                        borderRadius: 1,
                        fontWeight: 500,
                        textAlign: 'right',
                        minWidth: 100,
                      }}
                    >
                      {value.value} / {value.maxValue}
                    </Typography>
                  )}
                </Box>
              </Paper>
            ))}
        </Box>
      </Box>
    );
  };

  const hasAnswers = (marking: any) => {
    return marking.answers && Object.keys(marking.answers).length > 0;
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" p={2}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error">
        {t('Error loading task history')}: {error.message}
      </Alert>
    );
  }

  if (!task) {
    return <Alert severity="info">{t('No task selected')}</Alert>;
  }

  if (markings.length === 0) {
    return (
      <Alert severity="info">{t('No markings found for this task')}</Alert>
    );
  }

  return (
    <Box>
      {markings.some(hasAnswers) && (
        <Box sx={{ mb: 2 }}>
          <Button
            startIcon={copiedAll ? '✓' : <ContentCopyIcon />}
            onClick={handleCopyAll}
            disabled={markings.length === 0}
            variant="outlined"
            size="small"
            color={copiedAll ? 'success' : 'primary'}
          >
            {copiedAll ? t('Copied!') : t('Copy All')}
          </Button>
        </Box>
      )}
      <Paper variant="outlined">
        <List disablePadding>
          {markings.map((marking, index) => (
            <Box key={index}>
              <ListItem
                sx={{
                  '&:hover': { bgcolor: 'action.hover' },
                  cursor: 'pointer',
                  py: 2,
                  px: 3,
                  pr: 12,
                }}
                onClick={() => handleToggleExpand(index)}
                secondaryAction={
                  <Box sx={{ display: 'flex', gap: 1, mr: 1 }}>
                    {hasAnswers(marking) && (
                      <IconButton
                        edge="end"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleCopyMarking(marking, index);
                        }}
                        aria-label="copy"
                        size="small"
                        sx={{
                          color:
                            copiedIndex === index
                              ? 'success.main'
                              : 'action.active',
                        }}
                      >
                        {copiedIndex === index ? (
                          '✓'
                        ) : (
                          <ContentCopyIcon fontSize="small" />
                        )}
                      </IconButton>
                    )}
                    <IconButton
                      edge="end"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleToggleExpand(index);
                      }}
                      aria-label="expand"
                      size="small"
                      sx={{ color: 'action.active' }}
                    >
                      {expandedItem === index ? (
                        <KeyboardArrowUpIcon fontSize="small" />
                      ) : (
                        <KeyboardArrowDownIcon fontSize="small" />
                      )}
                    </IconButton>
                  </Box>
                }
              >
                <ListItemText
                  primary={
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        gap: 2,
                      }}
                    >
                      <Box>
                        <Typography
                          variant="subtitle1"
                          sx={{ fontWeight: 500, mb: 0.5 }}
                        >
                          {marking.completedTime.toLocaleDateString()}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {marking.completedTime.toLocaleTimeString()}
                        </Typography>
                      </Box>
                      {marking.totalPoints !== undefined &&
                        hasAnswers(marking) && (
                          <Typography
                            variant="h6"
                            color="primary"
                            sx={{
                              fontWeight: 600,
                              bgcolor: 'primary.50',
                              px: 2,
                              py: 1,
                              borderRadius: 1,
                            }}
                          >
                            {marking.totalPoints} / {marking.totalMaxPoints}{' '}
                            {t('pts')}
                          </Typography>
                        )}
                    </Box>
                  }
                />
              </ListItem>
              <Collapse
                in={expandedItem === index}
                timeout="auto"
                unmountOnExit
              >
                {renderMarkingDetails(marking)}
              </Collapse>
              {index !== markings.length - 1 && <Divider />}
            </Box>
          ))}
        </List>
      </Paper>
    </Box>
  );
}

export default TaskMarkingHistory;
