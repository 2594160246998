import { Trans, useTranslation } from 'react-i18next';
import { AlertTitle, CircularProgress, Typography } from '@mui/material';
import useTaskPoolCollection from '../firebase/useTaskPoolCollection';
import {
  DataGrid,
  GridToolbar,
  GridColDef,
  GridActionsCellItem,
} from '@mui/x-data-grid';
import { getTranslatableField } from '../services/i18n';
import { Link, useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import AddIcon from '@mui/icons-material/Add';
import LinkIcon from '@mui/icons-material/Link';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { useCallback, useMemo, useState } from 'react';
import { deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { useFirestore } from 'reactfire';
import Alert from '@mui/material/Alert';
import { htmlToText } from 'html-to-text';
import useTaskPoolTaskCategoryCollection from '../firebase/useTaskPoolTaskCategoryCollection';
import Tooltip from '@mui/material/Tooltip';
import usePortalUser from '../firebase/usePortalUser';

type TaskPoolProps = {
  taskpooltype: string | null;
};

export default function TaskPool(props: TaskPoolProps) {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const firestore = useFirestore();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteId, setDeleteId] = useState<string | null>(null);
  const [dialogTitle, setDialogTitle] = useState<string | null>(null);
  const [dialogContent, setDialogContent] = useState<string | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const { taskpooltype } = props;

  const {
    data: portalUserData,
    userRef,
    isSuperAdmin,
    isOrganizationAdmin,
    providerRef,
  } = usePortalUser();

  const hiddenTaskPoolTasks = useMemo(() => {
    if (portalUserData?.hiddenTaskPoolTasks) {
      return portalUserData.hiddenTaskPoolTasks;
    }
    return [];
  }, [portalUserData]);

  const therapyLanguages = useMemo(() => {
    return portalUserData?.therapyLanguages || [];
  }, [portalUserData]);

  const {
    status: taskPoolStatus,
    data,
    error: taskPoolError,
  } = useTaskPoolCollection(taskpooltype, therapyLanguages);

  const {
    status: categoryStatus,
    data: categoryData,
    error: categoryError,
  } = useTaskPoolTaskCategoryCollection();

  const handleDelete = useCallback(
    (taskId: string, taskName: string) => {
      setDeleteId(taskId);
      setDialogTitle(t('Delete Task') + ' ' + taskName);
      setDialogContent(t('Are you sure you want to delete this task?'));
      setDialogOpen(true);
    },
    [t]
  );

  const handleCloseDialog = useCallback(() => {
    setDialogOpen(false);
  }, []);

  const confirmDelete = useCallback(() => {
    setDialogOpen(false);
    if (!deleteId) {
      return;
    }
    const ref = doc(firestore, 'TaskPool', deleteId);
    deleteDoc(ref)
      .then(() => {
        console.log('Document successfully deleted!');
      })
      .catch((error) => {
        console.error('Error removing document: ', error);
        setError(error);
      });
  }, [deleteId, firestore]);

  const canAdd = useMemo(() => {
    return (
      (taskpooltype === 'default' && isSuperAdmin) ||
      (taskpooltype === 'organization' && isOrganizationAdmin) ||
      taskpooltype === 'own'
    );
  }, [taskpooltype, isSuperAdmin, isOrganizationAdmin]);

  const columns: GridColDef[] = useMemo(() => {
    if (categoryStatus === 'loading' || taskPoolStatus === 'loading') {
      return [];
    }

    return [
      {
        field: 'title',
        headerName: t('Title'),
        width: 280,
        valueGetter: (_, row) => getTranslatableField(row.title),
      },
      {
        field: 'description',
        headerName: t('Description'),
        flex: 1,
        valueGetter: (_, row) =>
          htmlToText(getTranslatableField(row.description), { wordwrap: 130 }),
      },
      {
        field: 'category',
        headerName: t('Category'),
        width: 200,
        valueGetter: (_, row) => {
          const category = categoryData?.find(
            (category) => category.id === row.category?.id
          );
          return category ? getTranslatableField(category.title) : '';
        },
        renderCell: ({ colDef, row }) => {
          const category = categoryData?.find(
            (category) => category.id === row.category?.id
          );
          const value = category ? getTranslatableField(category.title) : '';

          return (
            <div
              style={{
                display: 'inline-flex',
                width: colDef.computedWidth,
                alignItems: 'center',
                height: '100%',
              }}
            >
              <div
                style={{
                  width: 16,
                  height: 16,
                  borderRadius: '50%',
                  backgroundColor: category?.color,
                  display: 'inline-flex',
                }}
              />
              <Typography
                variant="body2"
                component="span"
                sx={{ ml: 1 }}
                noWrap
              >
                {value}
              </Typography>
            </div>
          );
        },
      },
      { field: 'languages', headerName: t('Languages'), width: 150 },
      {
        field: 'actions',
        type: 'actions',
        width: 140,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        getActions: ({ id, row }) => {
          const route = `/admin/taskpool/${taskpooltype}/${row.id}`;
          const { amOwner, provider } = row;
          const canEdit =
            amOwner ||
            isSuperAdmin ||
            (provider?.id === providerRef?.id && isOrganizationAdmin);
          return canEdit
            ? [
                <Tooltip title={t('Show/Hide')} key="show-hide">
                  <GridActionsCellItem
                    icon={
                      hiddenTaskPoolTasks.includes(row.id) ? (
                        <VisibilityOffIcon color="primary" />
                      ) : (
                        <VisibilityIcon />
                      )
                    }
                    label={t('Show/Hide')}
                    className="textPrimary"
                    color="inherit"
                    onClick={() => {
                      if (!userRef) {
                        return;
                      }
                      const hidden = hiddenTaskPoolTasks.includes(row.id);
                      const newHiddenTaskPoolTasks = hidden
                        ? hiddenTaskPoolTasks.filter((id) => id !== row.id)
                        : [...hiddenTaskPoolTasks, row.id];
                      updateDoc(userRef, {
                        hiddenTaskPoolTasks: newHiddenTaskPoolTasks,
                      });
                    }}
                  />
                </Tooltip>,
                <Tooltip title={t('Duplicate')} key="duplicate">
                  <GridActionsCellItem
                    icon={<ContentCopyIcon />}
                    label={t('Duplicate')}
                    className="textPrimary"
                    color="inherit"
                    onClick={() =>
                      navigate(`/admin/taskpool/own/new/${row.id}`)
                    }
                  />
                </Tooltip>,
                <Tooltip title={t('Edit')}>
                  <GridActionsCellItem
                    disabled={!row.id}
                    icon={<EditIcon />}
                    label={t('Edit')}
                    className="textPrimary"
                    color="inherit"
                    onClick={() => navigate(route)}
                  />
                </Tooltip>,
                <Tooltip title={t('Delete')}>
                  <GridActionsCellItem
                    disabled={
                      !row.id ||
                      (row.type !== 'generic' && row.type !== 'questionnaire')
                    }
                    icon={<DeleteIcon />}
                    label={t('Delete')}
                    onClick={() =>
                      handleDelete(
                        row.id || '',
                        getTranslatableField(row.title)
                      )
                    }
                    color="inherit"
                  />
                </Tooltip>,
              ]
            : [
                <Tooltip title={t('Show/Hide')} key="show-hide">
                  <GridActionsCellItem
                    icon={
                      hiddenTaskPoolTasks.includes(row.id) ? (
                        <VisibilityOffIcon color="primary" />
                      ) : (
                        <VisibilityIcon />
                      )
                    }
                    label={t('Show/Hide')}
                    className="textPrimary"
                    onClick={() => {
                      if (!userRef) {
                        return;
                      }
                      const hidden = hiddenTaskPoolTasks.includes(row.id);
                      const newHiddenTaskPoolTasks = hidden
                        ? hiddenTaskPoolTasks.filter((id) => id !== row.id)
                        : [...hiddenTaskPoolTasks, row.id];
                      updateDoc(userRef, {
                        hiddenTaskPoolTasks: newHiddenTaskPoolTasks,
                      });
                    }}
                  />
                </Tooltip>,
                <Tooltip title={t('Duplicate')} key="duplicate">
                  <GridActionsCellItem
                    icon={<ContentCopyIcon />}
                    label={t('Duplicate')}
                    className="textPrimary"
                    color="inherit"
                    onClick={() =>
                      navigate(`/admin/taskpool/own/new/${row.id}`)
                    }
                  />
                </Tooltip>,
                <Tooltip title={t('View')}>
                  <GridActionsCellItem
                    disabled={!row.id}
                    icon={<LinkIcon />}
                    label={t('View')}
                    className="textPrimary"
                    color="inherit"
                    onClick={() => navigate(route)}
                  />
                </Tooltip>,
                <Tooltip title={t('Delete')}>
                  <GridActionsCellItem
                    disabled
                    icon={<DeleteIcon />}
                    label={t('Delete')}
                    color="inherit"
                  />
                </Tooltip>,
              ];
        },
      },
    ];
  }, [
    categoryStatus,
    taskPoolStatus,
    t,
    categoryData,
    taskpooltype,
    isSuperAdmin,
    providerRef?.id,
    isOrganizationAdmin,
    hiddenTaskPoolTasks,
    userRef,
    navigate,
    handleDelete,
  ]);

  if (categoryStatus === 'loading' || taskPoolStatus === 'loading') {
    return <CircularProgress />;
  }

  return (
    <>
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error.message}
        </Alert>
      )}
      {taskPoolError && (
        <Alert severity="error" sx={{ mb: 2 }}>
          <AlertTitle>
            <Trans>Error Getting Task Pool</Trans>
          </AlertTitle>
          {taskPoolError.message}
        </Alert>
      )}
      {categoryError && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {categoryError.message}
        </Alert>
      )}
      <Typography variant="h5" component="h2" sx={{ mb: 2 }}>
        <Trans>Task Pool</Trans>
      </Typography>
      <DataGrid
        initialState={{
          sorting: {
            sortModel: [{ field: 'category', sort: 'asc' }],
          },
        }}
        autoHeight
        rows={data || []}
        columns={columns}
        rowSelection={false}
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
      />
      {canAdd && (
        <Button
          component={Link}
          to={`/admin/taskpool/${taskpooltype}/new`}
          variant="contained"
          startIcon={<AddIcon />}
          sx={{ mt: 2 }}
        >
          <Trans>New Task</Trans>
        </Button>
      )}
      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {dialogTitle || t('Delete Task')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogContent || t('Are you sure you want to delete this task?')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            <Trans>Cancel</Trans>
          </Button>
          <Button onClick={confirmDelete} color="primary" autoFocus>
            <Trans>Confirm</Trans>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
