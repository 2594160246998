import { useEffect, useState } from 'react';
import { useFirestore } from 'reactfire';
import uuid from 'react-native-uuid';
import {
  doc,
  onSnapshot,
  DocumentReference,
  Timestamp,
} from 'firebase/firestore';
import {
  Patient,
  TherapyCourse,
  TherapyCoursePhase,
  TranslatableField,
} from './firebaseModels';
import useUserProvider from './useUserProvider';
import { getCurrentLanguageCode } from '../services/i18n';
import { useFirebaseUser } from './useFirebaseUser';
import usePatientCollection from './usePatientCollection';

type TTherapyCourseGroupData = {
  status: 'loading' | 'success' | 'error' | 'not-found';
  error: Error | null;
  data: TherapyCourse | null;
};

/**
 * Custom hook for getting a TherapyCourseTask from Firestore.
 * @param id The ID of the TherapyCourseTask to fetch.
 * @returns TTherapyCourseTaskData
 */
export default function useTherapyCourseDoc(
  id: string | null,
  therapycoursetype: 'group' | 'individual' = 'group'
): TTherapyCourseGroupData {
  const firestore = useFirestore();

  const { email } = useFirebaseUser();
  const { isPatientValidById } = usePatientCollection();

  const { data: userProviderData, status: userProviderStatus } =
    useUserProvider();

  const defaultLanguage = getCurrentLanguageCode();

  const [status, setStatus] = useState<TTherapyCourseGroupData['status']>(
    id ? 'loading' : 'not-found'
  );
  const [data, setData] = useState<TTherapyCourseGroupData['data']>(null);
  const [error, setError] = useState<TTherapyCourseGroupData['error']>(null);
  const [nTimeouts, setNTimeouts] = useState(0);
  useEffect(() => {
    if (
      !id ||
      !userProviderData?.id ||
      !email ||
      userProviderStatus !== 'success'
    ) {
      return;
    }

    if (id === 'new') {
      setStatus('success');
      const newId = uuid.v4() as string;
      const initialPhases: TherapyCoursePhase[] = [
        {
          id: newId,
          title: { [defaultLanguage]: '' },
          order: 0,
          patients: [],
          phaseTasks: [],
          startDate: null,
          endDate: null,
        },
      ];
      setData({
        title: { [defaultLanguage]: '' } as TranslatableField,
        description: { [defaultLanguage]: '' } as TranslatableField,
        patients: [] as DocumentReference<Patient>[],
        phases: initialPhases,
        serviceProviderId: userProviderData.id,
        defaultLanguage,
        languages: [defaultLanguage],
        created: Timestamp.now(),
        createdBy: email,
        defaultInterval: 7,
        ongoingPhase: null,
        completed: false,
        type: therapycoursetype,
      });
      return;
    }

    const taskPoolTaskRef = doc(
      firestore,
      'ServiceProvider',
      userProviderData.id,
      'UserData',
      email,
      'TherapyCourse',
      id
    );
    const unsubscribe = onSnapshot(
      taskPoolTaskRef,
      (doc) => {
        setNTimeouts(0);
        if (doc.exists()) {
          let docData = doc.data() as TherapyCourse;
          if (!docData.type) {
            docData.type = therapycoursetype;
          }
          if (docData.patients) {
            docData.patients = docData.patients.filter(
              (patientRef: DocumentReference<Patient>) =>
                isPatientValidById(patientRef.id)
            );
          }
          // add missing phase ids if not present
          if (docData.phases) {
            docData.phases = docData.phases.map((phase: TherapyCoursePhase) => {
              if (!phase.id) {
                phase.id = uuid.v4() as string;
              }
              phase.patients = phase.patients?.filter((tcpp) =>
                isPatientValidById(tcpp.patient.id)
              );
              return phase;
            });
          }
          setData({
            completed: false,
            ongoingPhase: null,
            ...docData,
          } as TherapyCourse);
          setStatus('success');
        } else {
          setStatus('error');
          setError(new Error('TherapyCourseTask not found'));
        }
      },
      (error) => {
        setStatus('error');
        setError(error);

        // Incremental backoff for retrying
        const retryDelay = 1000 * (nTimeouts + 1);
        setTimeout(() => {
          console.log('useTherapyCourseDoc retrying after delay');
          setNTimeouts((prevTimeouts) => prevTimeouts + 1);
        }, retryDelay);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [
    defaultLanguage,
    email,
    firestore,
    id,
    isPatientValidById,
    nTimeouts,
    therapycoursetype,
    userProviderData?.id,
    userProviderStatus,
  ]);

  return { status, error, data };
}
