import { styled } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { drawerWidth } from '../consts';

/**
 * Props of the {@link AppBar} component.
 */
interface AppBarProps extends MuiAppBarProps {
  /**
   * Whether the drawer is open.
   * @see {@link Dashboard}
   */
  open?: boolean;
}

/**
 * Custom AppBar component. Extends MUI's {@link https://mui.com/api/app-bar/ | AppBar}.
 *
 * @component Component for the top app bar.
 * @subcategory Custom
 */
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default AppBar;
