import { useMemo } from 'react';
import {
  TaskOrder,
  TaskPoolTask,
  TherapyCoursePhasePatient,
} from '../firebase/firebaseModels';
import { DocumentReference } from 'firebase/firestore';

/**
 * Creates an ordered mapping of task IDs to their display order.
 * @param phaseTasks - Array of references to tasks in the current phase
 * @param taskPoolData - Array of task data from the task pool
 * @returns Object mapping task IDs to their order numbers
 * @remarks
 * - Excludes sleep restriction tasks from ordering
 * - Assigns order 999 to tasks without an explicit order
 * - Ensures unique ordering by incrementing subsequent tasks with duplicate orders
 */
export function createTaskOrder(
  phaseTasks: DocumentReference<TaskPoolTask>[],
  taskPoolData: TaskPoolTask[] | null
): TaskOrder {
  let ret: TaskOrder = {};
  phaseTasks.forEach((t) => {
    const taskData = taskPoolData?.find((tpt) => tpt.id === t.id);
    if (taskData && taskData.type !== 'sleepRestriction') {
      ret[t.id] = taskData.order || 999;
    }
  });

  // Sort and ensure unique ordering
  const taskKeys = Object.keys(ret);
  taskKeys.sort((a, b) => ret[a] - ret[b]);
  // if two consecutive tasks have the same order, add 1 to the one with the higher index
  for (let i = 0; i < taskKeys.length - 1; i++) {
    if (ret[taskKeys[i]] === ret[taskKeys[i + 1]]) {
      ret[taskKeys[i + 1]] += 1;
    }
  }
  return ret;
}

/**
 * Hook that manages the ordering of patient tasks within a therapy phase.
 * @param phasePatient - Current phase patient data containing optional task order
 * @param phaseTasks - Array of references to tasks in the current phase
 * @param taskPoolData - Array of task data from the task pool
 * @returns Object mapping task IDs to their order numbers
 * @remarks
 * Returns existing task order from phasePatient if available,
 * otherwise generates a new task order using createTaskOrder
 */
export function usePatientTaskOrder(
  phasePatient: TherapyCoursePhasePatient | null,
  phaseTasks: DocumentReference<TaskPoolTask>[],
  taskPoolData: TaskPoolTask[] | null
): TaskOrder {
  return useMemo(() => {
    if (!phasePatient?.taskOrder) {
      return createTaskOrder(phaseTasks, taskPoolData);
    }
    return phasePatient.taskOrder;
  }, [phasePatient?.taskOrder, phaseTasks, taskPoolData]);
}
