import { Box } from '@mui/material';
import { Timestamp } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import {
  TherapyCourse,
  TherapyCoursePhase,
} from '../../firebase/firebaseModels';
import { useParams } from 'react-router-dom';
import usePatientCollection from '../../firebase/usePatientCollection';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { TabContext, TabPanel } from '@mui/lab';
import PatientView from './PatientView';

type PhasePatientsProps = {
  therapyCourseId: string;
  phase: TherapyCoursePhase;
  phaseIndex: number;
  course: TherapyCourse;
  currentPatient: number | null;
  setCurrentPatient: (index: number | null) => void;
};

/**
 * Tab for each patient in the course;
 * date range selector for statistics (prefill with previous phase dates if available)
 * statistics for the patient
 * @param props
 */
export default function PhasePatients(props: PhasePatientsProps) {
  const { patientId } = useParams<{ patientId: string }>();
  const {
    phase,
    phaseIndex,
    course,
    therapyCourseId,
    currentPatient,
    setCurrentPatient,
  } = props;
  const { data: patientCollectionData } = usePatientCollection();

  const [currentStartDate, setCurrentStartDate] = useState<Timestamp | null>(
    null
  );
  const [currentEndDate, setCurrentEndDate] = useState<Timestamp | null>(null);

  useEffect(() => {
    if (!course.patients) {
      return;
    }
    if (currentPatient === null && !!patientId) {
      const initialPatient = course.patients.findIndex(
        (patient) => patient.id === patientId
      );
      console.log('initialPatient', initialPatient);
      setCurrentPatient(initialPatient);
    }
  }, [course, course.patients, currentPatient, patientId, setCurrentPatient]);

  return (
    <TabContext value={`${currentPatient || 0}`}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          backgroundColor: 'background.paper',
          mt: 1,
        }}
      >
        <Tabs
          aria-label="patients"
          value={`${currentPatient || 0}`}
          variant="scrollable"
          sx={{ overflowX: 'auto' }}
        >
          {course.patients?.map((p, index) => {
            const patient = patientCollectionData?.find(
              (patient) => patient.id === p.id
            );
            return patient?.licenceCode ? (
              <Tab
                key={`${patient.id}-tab`}
                label={`${patient.licenceCode} - ${patient.remark}`}
                onClick={() => setCurrentPatient(index)}
                value={`${index}`}
                sx={{
                  minWidth: 100,
                }}
              />
            ) : null;
          })}
        </Tabs>
      </Box>
      <Box sx={{ backgroundColor: 'background.paper' }}>
        {course.patients?.map((p, index) => {
          const patient = patientCollectionData?.find(
            (patient) => patient.id === p.id
          );
          return patient ? (
            <TabPanel value={`${index}`} key={patient.id}>
              <PatientView
                key={`${patient.id}-statistics`}
                therapyCourseId={therapyCourseId}
                therapyCoursePatientId={p.id}
                patient={patient}
                phase={phase}
                currentStartDate={currentStartDate}
                setCurrentStartDate={setCurrentStartDate}
                currentEndDate={currentEndDate}
                setCurrentEndDate={setCurrentEndDate}
                course={course}
                phaseIndex={phaseIndex}
              />
            </TabPanel>
          ) : null;
        })}
      </Box>
    </TabContext>
  );
}
