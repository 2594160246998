import React, { useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { getFormattedDate } from '../../utils';
import { MainDiaryPeriod } from './MainDiaryPeriod';
import { NapPeriods } from './NapPeriods';
import { WakePeriod } from './WakePeriod';
// import HomeworkDot from './HomeworkDot';
import HourDividers from './HourDividers';
import { StatisticDay } from '../TherapyCourseOverview/types';
import HomeworkDot from './HomeworkDot';

export const DATE_LABEL_WIDTH = 70;

interface DayViewProps {
  statisticDate: Date;
  dateLabel: string;
  statistics: StatisticDay[];
  fullWidth: number;
  dayWidth: number;
  hourWidth: number;
  dayHeight: number;
}

export function DayView({
  statisticDate,
  dateLabel,
  statistics,
  fullWidth,
  dayWidth,
  hourWidth,
  dayHeight,
}: DayViewProps) {
  const cutDateAsStr = getFormattedDate(statisticDate);

  const nextDate = useMemo(() => {
    const nextDateTmp = new Date(statisticDate);
    nextDateTmp.setDate(nextDateTmp.getDate() + 1);
    return nextDateTmp;
  }, [statisticDate]);

  return (
    <Box
      key={`dvc-${cutDateAsStr}`}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: dayHeight,
        mb: 0.25,
      }}
    >
      {/* Date label */}
      <Box
        sx={{
          display: 'inline-flex',
          position: 'absolute',
          width: `${DATE_LABEL_WIDTH}px`,
          height: dayHeight,
          alignItems: 'center',
        }}
      >
        <Typography variant="caption">{dateLabel}</Typography>
      </Box>

      {/* Chart area containing all sleep and activity indicators */}
      <Box
        sx={{
          display: 'flex',
          position: 'relative',
          overflow: 'hidden',
          height: dayHeight,
          width: `${dayWidth}%`,
          ml: `${DATE_LABEL_WIDTH}px`,
        }}
      >
        {/* Main sleep period for the current day */}
        <MainDiaryPeriod
          key={`maindiaryperiod-${cutDateAsStr}`}
          statisticDate={statisticDate}
          cutDateAsStr={cutDateAsStr}
          statistics={statistics}
          dayHeight={dayHeight}
          hourWidth={hourWidth}
          isNextDay={false}
        />

        {/* Wake period */}
        <WakePeriod
          key={`wakeperiod-${cutDateAsStr}`}
          statisticDate={statisticDate}
          cutDateAsStr={cutDateAsStr}
          statistics={statistics}
          dayHeight={dayHeight}
          hourWidth={hourWidth}
        />

        {/* Main sleep period extending into the next day */}
        <MainDiaryPeriod
          key={`maindiaryperiod-${cutDateAsStr}-next`}
          statisticDate={nextDate}
          cutDateAsStr={cutDateAsStr}
          statistics={statistics}
          dayHeight={dayHeight}
          hourWidth={hourWidth}
          isNextDay={true}
        />

        {/* Nap periods */}
        <NapPeriods
          key={`napperiods-${cutDateAsStr}`}
          statisticDate={statisticDate}
          cutDateAsStr={cutDateAsStr}
          statistics={statistics}
          dayHeight={dayHeight}
          hourWidth={hourWidth}
        />

        {/* Visual dividers for hours */}
        <HourDividers hourWidth={hourWidth} />

        {/* Indicator for homework completion */}
        <HomeworkDot
          statistics={statistics}
          cutDateAsStr={cutDateAsStr}
          hourWidth={hourWidth}
          dayHeight={dayHeight}
        />
      </Box>
    </Box>
  );
}
