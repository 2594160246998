import { User as FirebaseUser } from 'firebase/auth';
import React, { createContext, useEffect, useMemo, useState } from 'react';
import { useAuth } from 'reactfire';

interface FirebaseUserContextType {
  email: string | null;
  user: FirebaseUser | null;
  loading: boolean;
}

export const FirebaseUserContext = createContext<
  FirebaseUserContextType | undefined
>(undefined);

export function FirebaseUserProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [user, setUser] = useState<FirebaseUser | null>(null);
  const [loading, setLoading] = useState(true);

  const auth = useAuth();
  const email = useMemo(() => user?.email || null, [user]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      setLoading(false);
    });
    return () => {
      unsubscribe();
    };
  }, [auth]);

  const value = { email, user, loading };

  return (
    <FirebaseUserContext.Provider value={value}>
      {children}
    </FirebaseUserContext.Provider>
  );
}
