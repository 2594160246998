import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import { getDateDiaryData, getTimeAsDecimal } from './statUtil';
import { timestampPropertyAsDate } from '../../utils';
import { StatisticDay } from '../TherapyCourseOverview/types';
import SleeptimePeriod from './SleeptimePeriod';

interface NapPeriodsProps {
  statisticDate: Date;
  cutDateAsStr: string;
  statistics: StatisticDay[];
  dayHeight: number;
  hourWidth: number;
}

/**
 * Renders the nap periods for a given date.
 *
 * This component displays multiple Box components, each representing a nap
 * taken on the specified date. It calculates the necessary time values and positions
 * for each nap period based on the provided statistics and layout parameters.
 */
export function NapPeriods({
  statisticDate,
  cutDateAsStr,
  statistics,
  dayHeight,
  hourWidth,
}: NapPeriodsProps) {
  const napPeriods = useMemo(() => {
    const { eveningData } = getDateDiaryData(statisticDate, statistics);

    return (
      eveningData?.raw_data?.naps?.map((nap, index) => {
        const bedtime = timestampPropertyAsDate(nap.bedtime);
        const sleeptime = timestampPropertyAsDate(nap.sleeptime);
        const wakeuptime = timestampPropertyAsDate(nap.wakeuptime);
        const gettinguptime = timestampPropertyAsDate(nap.gettinguptime);

        // calculate the decimal values for the nap period data
        const bedtimeDecimal = getTimeAsDecimal(bedtime, cutDateAsStr, 0);
        const sleeptimeDecimal = getTimeAsDecimal(sleeptime, cutDateAsStr, 0);
        const wakeuptimeDecimal = getTimeAsDecimal(wakeuptime, cutDateAsStr, 0);
        const gettinguptimeDecimal = getTimeAsDecimal(
          gettinguptime,
          cutDateAsStr,
          0
        );

        const startPosition = bedtimeDecimal ? bedtimeDecimal * hourWidth : 0;
        const width =
          (gettinguptimeDecimal && bedtimeDecimal
            ? gettinguptimeDecimal - bedtimeDecimal
            : 0) * hourWidth;

        return (
          <Box
            key={`${cutDateAsStr}-nap-${index}`}
            sx={{
              position: 'absolute',
              left: `${startPosition}%`,
              width: `${width}%`,
              height: dayHeight,
              backgroundColor: 'rgba(0, 128, 255, 0.3)', // Light blue for naps
              border: '1px solid rgba(0, 128, 255, 0.5)',
              boxSizing: 'border-box',
            }}
          >
            <SleeptimePeriod
              sleeptimeDecimal={sleeptimeDecimal}
              wakeuptimeDecimal={wakeuptimeDecimal}
              dayHeight={dayHeight}
              hourWidth={hourWidth}
            />
          </Box>
        );
      }) || []
    );
  }, [cutDateAsStr, dayHeight, hourWidth, statisticDate, statistics]);

  return <>{napPeriods}</>;
}
