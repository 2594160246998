import React, { useMemo } from 'react';
import { Box, Tooltip } from '@mui/material';
import { getTimeAsDecimal } from './statUtil';
import { timestampPropertyAsDate } from '../../utils';
import {
  SleepEfficiencyData,
  StatisticDay,
} from '../TherapyCourseOverview/types';
import i18n from '../../services/i18n';

const DOT_MARGIN = 8;

type HomeworkDotProps = {
  statistics: StatisticDay[];
  cutDateAsStr: string;
  hourWidth: number;
  dayHeight: number;
  dotColor?: string;
};

/**
 * HomeworkDot component
 *
 * Renders a dot representing the homework completion time for a specific date.
 *
 * @param {Object} props - The component props
 * @param {StatisticDay[]} props.statistics - Array of statistic data for multiple days
 * @param {string} props.cutDateAsStr - The date for which to display the homework dot
 * @param {number} props.hourWidth - The width of one hour in the parent component's scale
 * @param {number} props.dayHeight - The height of a day row in the parent component
 * @param {string} [props.dotColor='green'] - The color of the homework dot
 * @returns {React.ReactElement | null} A Box component representing the homework dot, or null if no homework data is available
 */
export default function HomeworkDot({
  statistics,
  cutDateAsStr,
  hourWidth,
  dayHeight,
  dotColor = 'green',
}: HomeworkDotProps) {
  // Find the homework completion time for the given date
  const homeworkCompletionTime = useMemo(() => {
    // Find the statistic for the specified date
    const dateData = statistics.find(
      (stat) => stat.marking_date === cutDateAsStr
    );
    // Find the homework data within the sleep efficiency data
    const sleepEfficiencyData =
      dateData?.sleep_efficiency_data || ([] as SleepEfficiencyData[]);
    const homeworkData = sleepEfficiencyData?.find(
      (data) => data.type === 'homework'
    );
    // Return the completion time if homework was completed, otherwise null
    if (homeworkData?.raw_data?.completed) {
      return homeworkData.raw_data.completedTime
        ? timestampPropertyAsDate(homeworkData.raw_data.completedTime)
        : null;
    }
    return null;
  }, [statistics, cutDateAsStr]);

  // Convert homework completion time to decimal format (e.g., 14.5 for 2:30 PM)
  const homeworkCompletionTimeAsDecimal = useMemo(
    () =>
      homeworkCompletionTime
        ? getTimeAsDecimal(homeworkCompletionTime, cutDateAsStr, 0)
        : null,
    [cutDateAsStr, homeworkCompletionTime]
  );

  /**
   * Calculates the style for the homework dot based on completion time and parent component dimensions
   */
  const homeworkDotStyle = useMemo(() => {
    if (!homeworkCompletionTimeAsDecimal) {
      return null;
    }

    const dotDiameter = dayHeight - 2 * DOT_MARGIN;
    return {
      position: 'absolute',
      left: `${homeworkCompletionTimeAsDecimal * hourWidth}%`,
      top: DOT_MARGIN,
      height: dotDiameter,
      width: dotDiameter,
      borderRadius: '50%',
      backgroundColor: dotColor,
      opacity: 0.5,
    };
  }, [homeworkCompletionTimeAsDecimal, dayHeight, hourWidth, dotColor]);

  /**
   * Formats the homework completion time for display in the tooltip
   */
  const formattedCompletionTime = useMemo(() => {
    if (!homeworkCompletionTime) return '';
    return homeworkCompletionTime.toLocaleString(i18n.language, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    });
  }, [homeworkCompletionTime]);

  // Render the homework dot with a tooltip if a valid style is calculated
  if (!homeworkDotStyle) {
    return null;
  }

  return (
    <Tooltip title={`${formattedCompletionTime}`} arrow>
      <Box sx={homeworkDotStyle} />
    </Tooltip>
  );
}
