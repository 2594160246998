import Tab from '@mui/material/Tab';
import { MouseEventHandler, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export interface LinkTabProps {
  label: string;
  href: string;
  selected: boolean;
}

export default function LinkTab(props: LinkTabProps) {
  const navigate = useNavigate();
  const { href } = props;
  const handleNavigate = useCallback<MouseEventHandler<HTMLAnchorElement>>(
    (event) => {
      event.preventDefault();

      if (href) {
        navigate(href);
      }
    },
    [href, navigate]
  );

  return (
    <Tab
      component="a"
      onClick={handleNavigate}
      aria-current={props.selected && 'page'}
      {...props}
    />
  );
}
