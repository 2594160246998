import { doc, updateDoc } from 'firebase/firestore';
import { useState } from 'react';
import { useFirestore } from 'reactfire';

/**
 * A custom hook for updating a user's data in Firestore.
 *
 * @returns An object containing:
 *   - status: The current status of the update operation ('idle', 'loading', 'success', or 'error').
 *   - error: Any error message that occurred during the update.
 *   - updateUser: A function to update the user's data.
 */
export function useUpdateAppUser(appUserId: string) {
  const firestore = useFirestore();

  const [status, setStatus] = useState<string | null>('idle');
  const [error, setError] = useState<string | null>(null);
  const [updateSuccessful, setUpdateSuccessful] = useState(false);

  return {
    status,
    error,
    updateSuccessful,
    /**
     * Updates the user's data in Firestore.
     *
     * @param updateData - An object containing the fields to update and their new values.
     * @throws Will set an error state if the user is not found or if there's an error during the update.
     */
    updateUser: (
      updateData: Partial<{
        [x: string]: any;
      }>
    ) => {
      if (!appUserId) {
        setError('User ID id null');
        setStatus('error');
        return;
      }
      setStatus('loading');
      const appUserRef = doc(firestore, 'Users', appUserId);

      updateDoc(appUserRef, updateData)
        .then(() => {
          setUpdateSuccessful(true);
          setStatus('success');
        })
        .catch((e) => {
          setError('Error updating user');
          setStatus('error');
        });
    },
  };
}
