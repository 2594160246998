import { useEffect, useState } from 'react';
import { useFirestore } from 'reactfire';
import { doc, getDoc, onSnapshot, Timestamp } from 'firebase/firestore';
import {
  TaskPoolTask,
  TaskPoolTaskType,
  TranslatableField,
} from './firebaseModels';
import { DEFAULT_IMAGE_URL } from '../consts';
import { getCurrentLanguageCode } from '../services/i18n';
import { useFirebaseUser } from './useFirebaseUser';
import useUserProvider from './useUserProvider';
import { FirebaseError } from 'firebase/app';

type TTaskPoolTaskData = {
  status: 'loading' | 'success' | 'error';
  error: Error | null;
  data: TaskPoolTask | null;
};

/**
 * Custom hook for getting a TaskPoolTask from Firestore.
 * @param id The ID of the TaskPoolTask to fetch.
 * @returns TTaskPoolTaskData
 */
export default function useTaskPoolTaskDoc(
  id: string,
  idToClone?: string
): TTaskPoolTaskData {
  const firestore = useFirestore();

  const defaultLanguage = getCurrentLanguageCode();

  const [status, setStatus] = useState<TTaskPoolTaskData['status']>('loading');
  const [data, setData] = useState<TTaskPoolTaskData['data']>(null);
  const [error, setError] = useState<TTaskPoolTaskData['error']>(null);
  const [nTimeouts, setNTimeouts] = useState(0);

  const { email } = useFirebaseUser();

  const {
    data: userProviderData,
    status: userProviderStatus,
    providerRef,
  } = useUserProvider();

  useEffect(() => {
    if (
      !providerRef ||
      !userProviderData?.id ||
      !email ||
      userProviderStatus !== 'success'
    ) {
      return;
    }
    if (id === 'new') {
      if (idToClone) {
        const duplicateDoc = doc(firestore, 'TaskPool', idToClone);
        getDoc(duplicateDoc).then((doc) => {
          if (doc.exists()) {
            const data = doc.data() as TaskPoolTask;
            setStatus('success');
            setData({
              ...data,
              id: 'new',
              public: false,
              provider: providerRef,
              created: Timestamp.now(),
              createdBy: email,
              modified: null,
              modifiedBy: null,
              amOwner: true,
            });
          } else {
            setStatus('error');
            setError(new Error('TaskPoolTask not found'));
          }
        });
      } else {
        setStatus('success');
        setData({
          defaultLanguage,
          title: { [defaultLanguage]: '' } as TranslatableField,
          titleDone: { [defaultLanguage]: '' } as TranslatableField,
          description: { [defaultLanguage]: '' } as TranslatableField,
          image: DEFAULT_IMAGE_URL,
          languages: [defaultLanguage],
          order: 0,
          type: 'generic' as TaskPoolTaskType,
          provider: providerRef,
          amOwner: true,
          public: false,
          createdBy: email,
          created: Timestamp.now(),
        });
      }
      return;
    }

    const taskPoolTaskRef = doc(firestore, 'TaskPool', id);
    const unsubscribe = onSnapshot(
      taskPoolTaskRef,
      (doc) => {
        setNTimeouts(0);
        if (doc.exists()) {
          const id = doc.id;
          const amOwner = doc.data().createdBy === email;
          setData({ id, ...doc.data(), amOwner } as TaskPoolTask);
          setStatus('success');
        } else {
          setStatus('error');
          setError(new Error('TaskPoolTask not found'));
        }
      },
      (error) => {
        setStatus('error');
        setError(error as FirebaseError);
        setData(null);

        console.error('Error getting TaskPoolTask snapshot', error);
        // Incremental backoff for retrying
        const retryDelay = 1000 * (nTimeouts + 1);
        setTimeout(() => {
          console.log('useTaskPoolTaskDoc retrying after delay');
          setNTimeouts((prevTimeouts) => prevTimeouts + 1);
        }, retryDelay);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [
    defaultLanguage,
    idToClone,
    email,
    firestore,
    id,
    nTimeouts,
    providerRef,
    userProviderData?.id,
    userProviderStatus,
  ]);

  return { status, error, data };
}
