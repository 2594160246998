import { useContext } from 'react';
import { PatientContext, TPatientData } from '../contexts/PatientContext';

export default function usePatientCollection(): TPatientData {
  const context = useContext(PatientContext);
  if (context === null) {
    throw new Error(
      'usePatientCollection must be used within a PatientProvider'
    );
  }
  return context;
}
