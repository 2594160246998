import { FirebaseError } from 'firebase/app';
import { ref, deleteObject } from 'firebase/storage';
import { useCallback } from 'react';
import { useStorage } from 'reactfire';
import useUserProvider from '../firebase/useUserProvider';

export function useDeleteObjectFromStorage() {
  const storage = useStorage();
  const { data: userProviderData } = useUserProvider();

  const deleteObjectFromStorage = useCallback(
    async (fileName: string | null) => {
      if (!userProviderData?.id || !fileName) {
        return;
      }
      const storageRef = ref(storage, fileName);
      try {
        await deleteObject(storageRef);
      } catch (e) {
        const error = e as FirebaseError;
        console.error('Error deleting object', error);
        return error;
      } finally {
        console.log('Object deleted');
      }
      return null;
    },
    [userProviderData?.id, storage]
  );

  return { deleteObjectFromStorage };
}
