import { Trans, useTranslation } from 'react-i18next';
import {
  Alert,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  IconButton,
  Link,
  Typography,
  Box,
  Tooltip,
} from '@mui/material';
import { getTranslatableField } from '../services/i18n';
import { TherapyCourse } from '../firebase/firebaseModels';
import usePatientCollection from '../firebase/usePatientCollection';
import TherapyCoursePatientColumn from '../components/TherapyCoursePatientColumn';
import { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import LinkIcon from '@mui/icons-material/Link';
import EditIcon from '@mui/icons-material/Edit';
import { GridRowsProp, DataGrid, GridColDef } from '@mui/x-data-grid';
import PendingIcon from '@mui/icons-material/Pending';

export default function TherapyCourseCard({
  course,
}: {
  course: TherapyCourse;
}) {
  const { t } = useTranslation();
  const {
    status: patientCollectionStatus,
    appUsersStatus,
    data: patientCollectionData,
  } = usePatientCollection();

  const { therapycoursetype, courseTitle, startDate, endDate } = useMemo(() => {
    const { phases } = course;
    // get the start date of the first phase
    const startDate = phases?.[0]?.startDate;
    // get the end date of the last phase
    const endDate = phases?.[phases.length - 1]?.endDate;

    return {
      therapycoursetype: course.type,
      courseTitle: getTranslatableField(course.title),
      startDate,
      endDate,
    };
  }, [course]);

  const rows = useMemo<GridRowsProp>(
    () =>
      course.patients?.map((therapyCoursePatient) => {
        const patient = patientCollectionData?.find(
          (patient) => patient.id === therapyCoursePatient.id
        );
        return {
          id: therapyCoursePatient?.id,
          name: `${patient?.licenceCode} - ${patient?.remark}`,
          pending: patient?.pending,
        };
      }) || [],
    [course.patients, patientCollectionData]
  );

  const columns: GridColDef[] = useMemo(
    () =>
      [
        {
          field: 'name',
          headerName: t('Patient'),
          flex: 1,
          renderCell: (params) => (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {params.row.pending && (
                <Tooltip title={t('Patient has not yet registered in the app')}>
                  <PendingIcon
                    sx={{
                      mr: 1,
                      color: 'warning.main',
                      fontSize: '1.2rem',
                    }}
                  />
                </Tooltip>
              )}
              {params.value}
            </Box>
          ),
        },
        {
          field: 'sleepEfficiency',
          headerName: t('Sleep Efficiency'),
          width: 200,
          renderCell: ({ row, colDef }) => (
            <TherapyCoursePatientColumn
              key={`${row.id}-sleepEfficiency`}
              colDef={colDef}
              type="sleepEfficiency"
              therapyCoursePatientId={row.id as string}
              therapyCourseId={course.id as string}
            />
          ),
        },
        {
          field: 'taskCompletion',
          headerName: t('Task Completion'),
          width: 200,
          renderCell: ({ row, colDef }) => (
            <TherapyCoursePatientColumn
              key={`${row.id}-taskCompletion`}
              colDef={colDef}
              type="taskCompletion"
              therapyCoursePatientId={row.id as string}
              therapyCourseId={course.id as string}
            />
          ),
        },
        {
          field: 'actions',
          type: 'actions',
          headerName: t('Actions'),
          width: 100,
          align: 'right',
          getActions: ({ row }) => {
            return [
              <TherapyCoursePatientColumn
                key={`${row.id}-taskCompletion`}
                type="actions"
                therapyCoursePatientId={row.id as string}
                therapyCourseId={course.id as string}
              />,
            ];
          },
        },
      ] as GridColDef[],
    [t, course.id]
  );

  const courseStartDateStr = useMemo(
    () => startDate?.toDate().toLocaleDateString() || '',
    [startDate]
  );
  const courseEndDateStr = useMemo(
    () => endDate?.toDate().toLocaleDateString() || '',
    [endDate]
  );

  const hyphen = useMemo(
    () => (!!startDate || !!endDate ? ' - ' : ''),
    [startDate, endDate]
  );
  return (
    <Card key={course.id} sx={{ my: 2 }} variant="outlined">
      <CardHeader
        title={courseTitle}
        subheader={
          <Grid container spacing={1}>
            <Grid item>
              <Typography variant="body2">
                <Trans>
                  {therapycoursetype === 'group' ? 'Group' : 'Individual'}
                </Trans>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">{courseStartDateStr}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">{hyphen}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">{courseEndDateStr}</Typography>
            </Grid>
          </Grid>
        }
        action={
          <>
            <Link
              component={RouterLink}
              to={`/therapycourses/${course.id}`}
              color="inherit"
            >
              <IconButton>
                <EditIcon />
                <Typography variant="body2">
                  <Trans>Edit Therapy Course</Trans>
                </Typography>
              </IconButton>
            </Link>
            <Link
              component={RouterLink}
              to={`/therapycourseoverview/${course.id}`}
              color="inherit"
            >
              <IconButton>
                <LinkIcon />
                <Typography variant="body2">
                  <Trans>View Course Session Overview</Trans>
                </Typography>
              </IconButton>
            </Link>
          </>
        }
      />
      <CardContent>
        {patientCollectionStatus === 'loading' ||
        appUsersStatus === 'loading' ? (
          <CircularProgress />
        ) : (
          <>
            {!course.patients || course.patients.length === 0 ? (
              <Alert severity="info" sx={{ mt: 2 }}>
                <Trans>No patients in this course</Trans>
              </Alert>
            ) : (
              <DataGrid
                initialState={{
                  sorting: {
                    sortModel: [{ field: 'name', sort: 'asc' }],
                  },
                }}
                autoHeight
                density="compact"
                rows={rows}
                rowSelection={false}
                columns={columns}
                hideFooter
              />
            )}
          </>
        )}
      </CardContent>
    </Card>
  );
}
