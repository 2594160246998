import { useTranslation } from 'react-i18next';
import { Theme, ThemeOptions, createTheme } from '@mui/material/styles';

import { fiFI } from '@mui/x-data-grid/locales';
import { fiFI as pickersFiFI } from '@mui/x-date-pickers/locales';
import { fiFI as coreFiFI } from '@mui/material/locale';

import { enUS } from '@mui/x-data-grid/locales';
import { enUS as pickersEnUS } from '@mui/x-date-pickers/locales';
import { enUS as coreEnUS } from '@mui/material/locale';

import { useEffect, useState } from 'react';

/* Custom theme for this app */
const THEME_OPTIONS: ThemeOptions = {
  typography: {
    fontFamily: 'Poppins',
    body1: {
      fontWeight: 400,
    },
    body2: {
      fontWeight: 400,
    },
    h1: {
      fontWeight: 600,
    },
    h2: {
      fontWeight: 600,
    },
    h3: {
      fontWeight: 600,
    },
    h4: {
      fontWeight: 600,
    },
    h5: {
      fontWeight: 600,
    },
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#5E72E4',
      contrastText: '#fff',
    },
    secondary: {
      main: '#172B4D',
      contrastText: '#fff',
    },
    info: {
      main: '#11CDEF',
      contrastText: '#fff',
    },
    success: {
      main: '#2DCE89',
      contrastText: '#fff',
    },
    warning: {
      main: '#FB6340',
      contrastText: '#fff',
    },
    error: {
      main: '#F5365C',
      contrastText: '#fff',
    },
    background: {
      default: '#f4f6f9',
    },
    text: {
      primary: '#32325D',
      secondary: '#8898AA',
    },
  },
};

export function useCustomTheme() {
  const { i18n } = useTranslation();
  const [themeLanguage, setThemeLanguage] = useState<string>('en');
  const [chtTheme, setTheme] = useState<Theme>(
    createTheme(THEME_OPTIONS, enUS, pickersEnUS, coreEnUS)
  );
  const currentLanguage = i18n.language;

  useEffect(() => {
    if (currentLanguage !== themeLanguage) {
      let translations = enUS;
      let pickersTranslations = pickersEnUS;
      let coreTranslations = coreEnUS;
      switch (currentLanguage) {
        case 'fi-FI':
          translations = fiFI;
          pickersTranslations = pickersFiFI;
          coreTranslations = coreFiFI;
          break;
        default:
          break;
      }
      setThemeLanguage(currentLanguage);
      setTheme(
        createTheme(
          THEME_OPTIONS,
          translations,
          pickersTranslations,
          coreTranslations
        )
      );
    }
  }, [currentLanguage, themeLanguage]);

  return chtTheme;
}

export const REACT_QUILL_MODULES = {
  toolbar: [
    [{ header: [1, 2, 3, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block'],
    [{ script: 'sub' }, { script: 'super' }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link'],
    ['clean'],
  ],
};
