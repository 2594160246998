import {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import CloseIcon from '@mui/icons-material/Close';
import {
  TaskPoolTaskSection,
  TaskPoolTaskSectionType,
  TranslatableField,
} from '../firebase/firebaseModels';
import { Alert, CardHeader, CardMedia, CircularProgress } from '@mui/material';
import { MuiFileInput } from 'mui-file-input';
import DeleteIcon from '@mui/icons-material/Delete';
import useUserProvider from '../firebase/useUserProvider';
import { SaveFuncParams, SectionEditorRef } from '../pages/TaskPoolTaskEditor';
import { useProgressiveUpload } from '../hooks/useProgressiveUpload';
import TranslatableTextField from './TranslatableTextField';
import TranslatableEditor from './TranslatableEditor';
import Button from '@mui/material/Button';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
type Props = {
  disabled?: boolean;
  section: TaskPoolTaskSection;
  language: string;
  defaultLanguage: string;
  onDelete: () => void;
};

/**
 * Editing the TaskPoolTask video section.
 */
const TaskPoolTaskVideoSectionEditor = forwardRef<SectionEditorRef, Props>(
  (props: Props, reactRef) => {
    const { t } = useTranslation();
    const { disabled, section, language, defaultLanguage } = props;

    const { data: userProviderData, status: userProviderStatus } =
      useUserProvider();

    const [videoFile, setVideoFile] = useState<File | null>(null);
    const [videoUrl, setVideoUrl] = useState<string | null>(null);
    const [title, setTitle] = useState<TranslatableField>({
      [defaultLanguage]: '',
    });
    const [description, setDescription] = useState<TranslatableField>({
      [defaultLanguage]: '',
    });

    useEffect(() => {
      if (section.url) {
        setVideoUrl(section.url);
        setVideoFile(null);
        setTitle(section.title);
        if (section.description) {
          setDescription(section.description);
        }
      }
    }, [section]);

    const {
      progressiveUpload,
      error: uploadError,
      progress,
    } = useProgressiveUpload(videoFile, section.id);

    const saveFunc = useCallback(async (): Promise<SaveFuncParams> => {
      if (!videoFile) {
        return {
          success: false,
          message: 'No file selected',
        };
      }
      if (!userProviderData?.id) {
        return {
          success: false,
          message: 'No user provider data',
        };
      }

      const url = await progressiveUpload();

      if (!url) {
        return {
          success: false,
          message: 'Error uploading video',
          error: new Error('Error uploading video'),
        };
      }
      const newSection = {
        ...section,
        title,
        description,
        url,
      };

      return { success: true, message: url, newSection };
    }, [
      videoFile,
      userProviderData?.id,
      progressiveUpload,
      section,
      title,
      description,
    ]);

    useImperativeHandle(reactRef, () => ({
      sectionType: 'video' as TaskPoolTaskSectionType,
      save: saveFunc,
    }));

    const handleFileInputChange = useCallback((file: File | null) => {
      setVideoFile(file);
      console.log('file', file);
    }, []);

    const onDeleteSection = useCallback(() => {
      props.onDelete();
    }, [props]);

    const titleExistsInPrimaryLanguage = !!title[defaultLanguage];

    if (userProviderStatus === 'loading') {
      return <CircularProgress />;
    }

    return (
      <Card variant="outlined">
        <CardHeader
          title={
            <Box display="flex" alignItems="center">
              <DragIndicatorIcon sx={{ mr: 1 }} />
              {t('Video')}
            </Box>
          }
          action={
            <Button
              variant="outlined"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={onDeleteSection}
              disabled={disabled}
            >
              {t('Delete')}
            </Button>
          }
        />
        <CardContent>
          <TranslatableTextField
            key={`video-title-${language}-${section.id}`}
            name={`video-title-${language}-${section.id}`}
            disabled={disabled}
            label={t('Video Section Title')}
            helperText={
              !titleExistsInPrimaryLanguage
                ? t('Title is required in primary language') +
                  ' ' +
                  `(${defaultLanguage})`
                : undefined
            }
            error={!titleExistsInPrimaryLanguage}
            language={language}
            type="title"
            setValue={setTitle}
            value={title}
            placeholder={t('Enter Title')}
          />
          <TranslatableEditor
            key={`video-description-${language}-${section.id}`}
            name={`video-description-${language}-${section.id}`}
            disabled={disabled}
            placeholder={t('Video Section Description')}
            value={description}
            setValue={setDescription}
            language={language}
            type="description"
          />

          {videoUrl && (
            <Card
              sx={{
                mt: 2,
                maxWidth: 345,
              }}
              key={videoUrl}
            >
              <CardMedia component="video" src={videoUrl} controls />
            </Card>
          )}
          {progress !== null && (
            <LinearProgress
              variant="determinate"
              value={progress}
              sx={{ mt: 2 }}
            />
          )}
          {uploadError && (
            <Alert severity="error">{t(uploadError.message)}</Alert>
          )}
          <Box sx={{ mt: 2 }}>
            <MuiFileInput
              id="video"
              label={t('Upload Video')}
              onChange={handleFileInputChange}
              inputProps={{ accept: 'video/*' }}
              value={videoFile}
              fullWidth
              disabled={progress !== null || disabled}
              clearIconButtonProps={{
                title: 'Remove',
                children: <CloseIcon fontSize="small" />,
              }}
            />
          </Box>
        </CardContent>
      </Card>
    );
  }
);

export default TaskPoolTaskVideoSectionEditor;
