import { TaskPoolTaskCategory } from './firebaseModels';
import useTypedCollection from './useTypedCollection';

export default function useTaskPoolTaskCategoryCollection(
  taskcategorytype: string | null = null,
  languages?: string[]
) {
  return useTypedCollection<TaskPoolTaskCategory>(
    taskcategorytype,
    'TaskPoolTaskCategories',
    languages
  );
}
