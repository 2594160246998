import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import LockResetIcon from '@mui/icons-material/LockReset';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useAuth } from 'reactfire';
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
} from 'firebase/auth';
import { CircularProgress } from '@mui/material';

type TChangePassword = {
  old_password: string;
  new_password: string;
  new_password_confirm: string;
};

/**
 * UserData component for changing the current user's password on the Settings page.
 * @component
 * @returns JSX.Element
 */
export function UserPassword() {
  const { t } = useTranslation();
  const auth = useAuth();
  const user = auth.currentUser;
  const { register, handleSubmit, reset } = useForm<TChangePassword>();
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [invalidPasswordMessage, setInvalidPasswordMessage] = useState<
    string | null
  >(null);
  const [error, setError] = useState<Error | null>(null);
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // clear fields on success
  useEffect(() => {
    if (success) {
      setPasswordsMatch(true);
      setInvalidPasswordMessage(null);
      reset();
    }
  }, [reset, success]);

  // console.log('changePasswordResponse', changePasswordResponse);
  // Handle the form submission
  const onSubmit = useCallback<SubmitHandler<TChangePassword>>(
    (data) => {
      if (!user) return;
      const { old_password, new_password, new_password_confirm } = data;
      const passwordsMatch = new_password === new_password_confirm;
      setPasswordsMatch(passwordsMatch);
      const message = isValidPassword(new_password);
      setInvalidPasswordMessage(message);
      if (!old_password || !passwordsMatch || message !== null) {
        return;
      }
      let credential = EmailAuthProvider.credential(
        user.email || '',
        old_password
      );

      setIsLoading(true);
      reauthenticateWithCredential(user, credential)
        .then(() => {
          return updatePassword(user, new_password)
            .then(() => {
              setIsLoading(false);
            })
            .catch((error) => {
              setError(error);
              setIsLoading(false);
            });
        })
        .then(() => {
          setSuccess(true);
          setIsLoading(false);
        })
        .catch((error) => {
          setError(error);
          setIsLoading(false);
        });
    },
    [user]
  );

  if (!user) {
    return <CircularProgress />;
  }

  return (
    <Paper variant="outlined" sx={{ my: 2, p: 2 }}>
      <Typography variant="h5" component="h2" sx={{ mb: 2 }}>
        {t('Change Password')}
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        sx={{ mt: 1 }}
      >
        <TextField
          margin="dense"
          required
          fullWidth
          type="password"
          label={t('Current Password')}
          placeholder={t('Enter Current Password')}
          autoComplete="password"
          {...register('old_password')}
        />
        <TextField
          error={!passwordsMatch}
          margin="dense"
          required
          fullWidth
          type="password"
          label={t('New Password')}
          placeholder={t('Enter New Password')}
          autoComplete="new-password"
          {...register('new_password')}
        />
        <TextField
          error={!passwordsMatch}
          margin="dense"
          required
          fullWidth
          type="password"
          label={t('Confirm New Password')}
          placeholder={t('Enter New Password Again')}
          autoComplete="new-password"
          {...register('new_password_confirm')}
        />
        {!passwordsMatch && (
          <Alert severity="error" sx={{ mt: 2 }}>
            <Typography>{t('Passwords do not match')}</Typography>
          </Alert>
        )}
        {invalidPasswordMessage && (
          <Alert severity="error" sx={{ mt: 2 }}>
            <Typography gutterBottom>{t('Invalid password')}</Typography>
            <Typography variant="body2" gutterBottom>
              {t(invalidPasswordMessage)}
            </Typography>
          </Alert>
        )}
        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            <Typography gutterBottom>{t('Password change failed')}</Typography>
            <br />
            <Typography variant="body2" gutterBottom>
              {t(error.message)}
            </Typography>
          </Alert>
        )}
        {success && (
          <Alert severity="success" onClose={() => setSuccess(false)}>
            {t('Password changed successfully')}
          </Alert>
        )}
        <Button
          disabled={isLoading}
          color="primary"
          type="submit"
          variant="contained"
          startIcon={<LockResetIcon />}
          sx={{ mt: 2 }}
        >
          {t('Change Password')}
        </Button>
      </Box>
    </Paper>
  );
}

/**
 * Ensure complexity of password
 *
 * @param password  The password to check
 * @returns null if the password is valid, string otherwise
 */
const isValidPassword = (password: string) => {
  // password can't be too short.
  if (password.length < 8) {
    return 'Password must be at least 8 characters long.';
  }
  // password should have at least one lowercase letter
  if (password.toUpperCase() === password) {
    return 'Password must contain at least one lowercase letter.';
  }
  // password should have at least one uppercase letter
  if (password.toLowerCase() === password) {
    return 'Password must contain at least one uppercase letter.';
  }
  // password should have at least one number
  if (!/\d/.test(password)) {
    return 'Password must contain at least one number.';
  }
  // password should have at least one special character
  if (!password.match(/[*@!#%&()^~{}]+/)) {
    return 'Password must contain at least one special character.';
  }
  return null;
};
