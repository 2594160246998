import {
  Dialog,
  Tabs,
  Tab,
  Box,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  Button,
  Chip,
  DialogTitle,
  DialogContent,
  IconButton,
} from '@mui/material';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { TaskPoolOption } from '../../hooks/useTaskPoolOptions';
import {
  CategoryGroupHeader,
  CategoryGroupItems,
} from '../TherapyPlanEditor/TherapyPlanEditorPhase';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';

export type TaskPoolSelectorProps = {
  taskPoolOptions: TaskPoolOption[];
  phaseTasksValue: TaskPoolOption[] | null;
  onChangePhaseTasks: (_: any, newValue: TaskPoolOption[]) => void;
};
export function TaskPoolSelector(props: TaskPoolSelectorProps) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const { taskPoolOptions, phaseTasksValue, onChangePhaseTasks } = props;

  if (!taskPoolOptions || phaseTasksValue === null) {
    return null;
  }

  // Get unique task types for tabs
  const taskTypes = Array.from(
    new Set(taskPoolOptions.map((option) => option.taskType))
  );

  // Group options by type and category
  const groupedOptions = taskPoolOptions.reduce((acc, option) => {
    if (!acc[option.taskType]) {
      acc[option.taskType] = {};
    }
    if (!acc[option.taskType][option.categoryTitle]) {
      acc[option.taskType][option.categoryTitle] = [];
    }
    acc[option.taskType][option.categoryTitle].push(option);
    return acc;
  }, {} as Record<string, Record<string, TaskPoolOption[]>>);

  const handleToggleTask = (task: TaskPoolOption) => {
    const isSelected = phaseTasksValue.some((t) => t.id === task.id);
    const newValue = isSelected
      ? phaseTasksValue.filter((t) => t.id !== task.id)
      : [...phaseTasksValue, task];
    onChangePhaseTasks(null, newValue);
  };

  // Map task types to translated labels
  const taskTypeLabels: Record<string, string> = {
    generic: t('Generic Tasks'),
    questionnaire: t('Questionnaires'),
    homework: t('Homework Tasks'),
    morning: t('Morning Tasks'),
    evening: t('Evening Tasks'),
    sleepRestriction: t('Sleep Restriction Tasks'),
  };

  // Add search filter function
  const filterTasks = (tasks: TaskPoolOption[]) => {
    if (!searchQuery) return tasks;
    const query = searchQuery.toLowerCase();
    return tasks.filter(
      (task) =>
        task.translatedTitle.toLowerCase().includes(query) ||
        task.docType.toLowerCase().includes(query)
    );
  };

  return (
    <>
      <Box
        onClick={() => setOpen(true)}
        sx={{
          mb: 2,
          minHeight: 56,
          border: '1px solid',
          borderColor: 'rgba(0, 0, 0, 0.23)',
          borderRadius: 1,
          p: 1,
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: 'action.hover',
          },
          display: 'flex',
          flexWrap: 'wrap',
          gap: 1,
          alignItems: 'flex-start',
        }}
      >
        {phaseTasksValue.length === 0 ? (
          <Box sx={{ color: 'text.secondary', p: 1 }}>{t('Add...')}</Box>
        ) : (
          phaseTasksValue.map((task) => (
            <Chip
              key={task.id}
              label={task.translatedTitle}
              onDelete={(e) => {
                e.stopPropagation();
                handleToggleTask(task);
              }}
            />
          ))
        )}
      </Box>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="md"
        fullWidth
        PaperProps={{ sx: { maxHeight: '80vh' } }}
      >
        <DialogTitle
          sx={{ m: 0, p: 2, borderBottom: 1, borderColor: 'divider' }}
        >
          {t('Select Tasks')}
          <IconButton
            aria-label="close"
            onClick={() => setOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ p: 0 }}>
          <Box
            sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
          >
            <Box sx={{ p: 2, borderBottom: 1, borderColor: 'divider' }}>
              <TextField
                fullWidth
                size="small"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder={t('Search tasks...')}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>

            <Tabs
              value={activeTab}
              onChange={(_, newValue) => setActiveTab(newValue)}
              variant="scrollable"
              sx={{ px: 2 }}
            >
              {taskTypes.map((type) => (
                <Tab key={type} label={taskTypeLabels[type] || t(type)} />
              ))}
            </Tabs>

            <Box sx={{ flex: 1, overflow: 'auto' }}>
              {taskTypes.map((taskType, index) => (
                <Box
                  key={taskType}
                  role="tabpanel"
                  hidden={activeTab !== index}
                  sx={{ p: 2 }}
                >
                  {Object.entries(groupedOptions[taskType] || {}).map(
                    ([category, tasks]) => {
                      const filteredTasks = filterTasks(tasks);
                      if (filteredTasks.length === 0) return null;

                      const categoryOption = tasks[0];
                      return (
                        <Box key={category} sx={{ mb: 3 }}>
                          <CategoryGroupHeader>
                            <div
                              style={{
                                display: 'inline-flex',
                                alignItems: 'center',
                                backgroundColor: 'white',
                                height: '2em',
                                fontWeight: 'bold',
                                padding: '0 8px',
                                borderRadius: 4,
                              }}
                            >
                              <div
                                style={{
                                  width: 16,
                                  height: 16,
                                  marginRight: 8,
                                  borderRadius: '50%',
                                  backgroundColor:
                                    categoryOption?.categoryColor || '#000000',
                                }}
                              />
                              {category}
                            </div>
                          </CategoryGroupHeader>
                          <List>
                            {filteredTasks.map((task) => (
                              <ListItem
                                key={task.id}
                                dense
                                button
                                onClick={() => handleToggleTask(task)}
                              >
                                <Checkbox
                                  edge="start"
                                  checked={phaseTasksValue.some(
                                    (t) => t.id === task.id
                                  )}
                                />
                                <ListItemText
                                  primary={task.translatedTitle}
                                  secondary={<Trans>{task.docType}</Trans>}
                                />
                              </ListItem>
                            ))}
                          </List>
                        </Box>
                      );
                    }
                  )}
                </Box>
              ))}
            </Box>
          </Box>
        </DialogContent>
        <Box sx={{ p: 2, display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={() => setOpen(false)}>{t('OK')}</Button>
        </Box>
      </Dialog>
    </>
  );
}
