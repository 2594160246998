import {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useCallback,
  ChangeEventHandler,
} from 'react';
import { useTranslation } from 'react-i18next';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import {
  TaskPoolTaskSection,
  TaskPoolTaskSectionType,
  TranslatableField,
} from '../firebase/firebaseModels';
import { CardHeader, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { SaveFuncParams, SectionEditorRef } from '../pages/TaskPoolTaskEditor';
import TranslatableTextField from './TranslatableTextField';
import TranslatableEditor from './TranslatableEditor';
import Button from '@mui/material/Button';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

type Props = {
  disabled?: boolean;
  section: TaskPoolTaskSection;
  language: string;
  defaultLanguage: string;
  onDelete: () => void;
};

/**
 * Editing the TaskPoolTask url section.
 */
const TaskPoolTaskURLSectionEditor = forwardRef<SectionEditorRef, Props>(
  (props: Props, reactRef) => {
    const { t } = useTranslation();
    const { disabled, section, language, defaultLanguage } = props;

    const [urlValue, setURLValue] = useState<string | null>('');
    const [title, setTitle] = useState<TranslatableField>({
      [defaultLanguage]: '',
    });
    const [description, setDescription] = useState<TranslatableField>({
      [defaultLanguage]: '',
    });

    useEffect(() => {
      if (section.url) {
        setURLValue(section.url);
        setTitle(section.title);
        if (section.description) {
          setDescription(section.description);
        }
      }
    }, [section]);

    const saveFunc = useCallback(async (): Promise<SaveFuncParams> => {
      if (!urlValue) {
        return {
          success: true,
          message: 'No url selected',
        };
      }
      const newSection = {
        ...section,
        title,
        description,
        url: urlValue,
      };

      return { success: true, message: '', newSection };
    }, [urlValue, section, title, description]);

    useImperativeHandle(reactRef, () => ({
      sectionType: 'url' as TaskPoolTaskSectionType,
      save: saveFunc,
    }));

    const onChangeUrl = useCallback<
      ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    >(
      ({ target }) => {
        const { value } = target;
        if (urlValue !== value) {
          setURLValue(value);
        }
      },
      [urlValue]
    );

    const onDeleteSection = useCallback(() => {
      props.onDelete();
    }, [props]);

    const titleExistsInPrimaryLanguage = !!title[defaultLanguage];

    return (
      <Card variant="outlined">
        <CardHeader
          title={
            <Box display="flex" alignItems="center">
              <DragIndicatorIcon sx={{ mr: 1 }} />
              {t('URL')}
            </Box>
          }
          action={
            <Button
              variant="outlined"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={onDeleteSection}
              disabled={disabled}
            >
              {t('Delete')}
            </Button>
          }
        />
        <CardContent>
          <TranslatableTextField
            key={`url-title-${language}-${section.id}`}
            name={`url-title-${language}-${section.id}`}
            disabled={disabled}
            label={t('URL Section Title')}
            helperText={
              !titleExistsInPrimaryLanguage
                ? t('Title is required in primary language') +
                  ' ' +
                  `(${defaultLanguage})`
                : undefined
            }
            error={!titleExistsInPrimaryLanguage}
            language={language}
            type="title"
            setValue={setTitle}
            value={title}
            placeholder={t('Enter Title')}
          />
          <TranslatableEditor
            key={`url-description-${language}-${section.id}`}
            name={`url-description-${language}-${section.id}`}
            disabled={disabled}
            placeholder={t('URL Section Description')}
            value={description}
            setValue={setDescription}
            language={language}
            type="description"
          />
          <Box sx={{ mt: 2 }}>
            <TextField
              key={`url-${language}`}
              name={`url-${language}`}
              disabled={disabled}
              label={t('URL')}
              placeholder={t("Enter URL (e.g. 'https://example.com')")}
              value={urlValue}
              onChange={onChangeUrl}
              fullWidth
              required
            />
          </Box>
        </CardContent>
      </Card>
    );
  }
);

export default TaskPoolTaskURLSectionEditor;
