// Import the functions you need from the SDKs you need

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// export const firebaseConfig = {
//   apiKey: 'AIzaSyCLf35P6oyh2qNIf3UQsXnFxeTIu98-p7A',
//   authDomain: 'oneiros-5ccbe.firebaseapp.com',
//   projectId: 'oneiros-5ccbe',
//   storageBucket: 'oneiros-5ccbe.appspot.com',
//   messagingSenderId: '652520433229',
//   appId: '1:652520433229:web:75d81bcb8c58ff6e821e04',
//   measurementId: 'G-NPSHTPWRN3',
// };

export const firebaseConfig = {
  apiKey: 'AIzaSyCLf35P6oyh2qNIf3UQsXnFxeTIu98-p7A',
  authDomain: 'oneiros-5ccbe.firebaseapp.com',
  projectId: 'oneiros-5ccbe',
  storageBucket: 'oneiros-5ccbe.appspot.com',
  messagingSenderId: '652520433229',
  appId: '1:652520433229:web:ff78114401567b2b821e04',
  measurementId: 'G-C8X7VYC0Y3',
};
