import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFirestore, useFunctions } from 'reactfire';
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import {
  doc,
  addDoc,
  collection,
  updateDoc,
  DocumentReference,
} from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { PortalUser, ServiceProvider } from '../firebase/firebaseModels';
import usePortalUser from '../firebase/usePortalUser';
import { usePortalUsers } from '../firebase/usePortalUsers';
import CreatedModifiedTable from '../components/CreatedModifiedTable';

type UserLevel = 'user' | 'admin' | 'superadmin' | 'reseller';

export default function PortalUserEditor() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams<{ portalUserId: string }>();
  const { portalUserId } = params;
  const functions = useFunctions();

  const firestore = useFirestore();
  const { isSuperAdmin, isOrganizationAdmin, providerRef } = usePortalUser();
  const {
    data: portalUsers,
    status: portalUsersStatus,
    providers,
  } = usePortalUsers();

  const [email, setEmail] = useState('');
  const [level, setLevel] = useState<UserLevel>('user');
  const [provider, setProvider] = useState<string | null>(null);
  const [updating, setUpdating] = useState(false);
  const [updateSuccessful, setUpdateSuccessful] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [language, setLanguage] = useState('en');
  const [sendingInvitation, setSendingInvitation] = useState(false);

  // Find existing user data if editing
  const existingUser =
    portalUserId !== 'new'
      ? portalUsers?.find((user) => user.id === portalUserId)
      : null;

  useEffect(() => {
    if (existingUser) {
      setEmail(existingUser.email || '');
      setLevel((existingUser.level as UserLevel) || 'user');
      setProvider(existingUser.provider?.id || null);
      setName(existingUser.name || '');
      setPhone(existingUser.phone || '');
      setLanguage(existingUser.language || 'en');
    } else if (providerRef?.id) {
      // Set default provider for new users
      setProvider(providerRef.id);
    }
  }, [existingUser, providerRef]);

  const updatePortalUser = useCallback(async () => {
    if (!email || updating) return;

    setUpdating(true);
    setUpdateSuccessful(false);
    setError(null);

    try {
      const portalUserRef = collection(firestore, 'PortalUser');
      const docData: Partial<PortalUser> = {
        email,
        level,
        name,
        phone,
        language,
        provider: provider
          ? (doc(
              firestore,
              'ServiceProvider',
              provider
            ) as DocumentReference<ServiceProvider>)
          : undefined,
      };

      if (portalUserId === 'new') {
        const newDocRef = await addDoc(portalUserRef, docData);
        navigate(`/settings/portalusers/${newDocRef.id}`);
      } else {
        const docRef = doc(firestore, 'PortalUser', portalUserId!);
        await updateDoc(docRef, docData);
      }

      setUpdateSuccessful(true);
    } catch (err) {
      setError(err as Error);
      console.error('Error updating portal user:', err);
    } finally {
      setUpdating(false);
    }
  }, [
    email,
    updating,
    firestore,
    portalUserId,
    level,
    provider,
    name,
    phone,
    language,
    navigate,
  ]);

  const handleSendInvitation = useCallback(async () => {
    if (!email || sendingInvitation) return;

    setSendingInvitation(true);
    setError(null);
    setUpdateSuccessful(false);

    try {
      const sendInvitation = httpsCallable(
        functions,
        'sendportaluserinvitation'
      );
      await sendInvitation({ portalUserId });
      setUpdateSuccessful(true);
    } catch (err) {
      setError(err as Error);
      console.error('Error sending invitation:', err);
    } finally {
      setSendingInvitation(false);
    }
  }, [email, sendingInvitation, functions, portalUserId]);

  const onSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      updatePortalUser();
    },
    [updatePortalUser]
  );

  if (portalUsersStatus === 'loading') {
    return <CircularProgress />;
  }

  if (!isSuperAdmin && !isOrganizationAdmin) {
    return (
      <Alert severity="error">
        {t('You do not have permission to edit users')}
      </Alert>
    );
  }

  return (
    <Box component="form" onSubmit={onSubmit} noValidate sx={{ mt: 1 }}>
      <Card sx={{ mt: 2 }} variant="outlined">
        <CardHeader title={t('User Details')} />
        <CardContent>
          <TextField
            margin="normal"
            required
            fullWidth
            label={t('Name')}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            label={t('Email')}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={!email}
            helperText={!email ? t('Email is required') : ''}
          />

          <TextField
            margin="normal"
            fullWidth
            label={t('Phone')}
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />

          <FormControl fullWidth margin="normal">
            <InputLabel>{t('Language')}</InputLabel>
            <Select
              value={language}
              onChange={(e) => setLanguage(e.target.value)}
              label={t('Language')}
            >
              <MenuItem value="en">{t('English')}</MenuItem>
              <MenuItem value="fi">{t('Finnish')}</MenuItem>
              <MenuItem value="sv">{t('Swedish')}</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel>{t('Role')}</InputLabel>
            <Select
              value={level}
              onChange={(e) => setLevel(e.target.value as UserLevel)}
              label={t('Role')}
            >
              <MenuItem value="user">{t('user')}</MenuItem>
              {(isSuperAdmin || isOrganizationAdmin) && (
                <MenuItem value="admin">{t('admin')}</MenuItem>
              )}
              {isSuperAdmin && (
                <>
                  <MenuItem value="superadmin">{t('superadmin')}</MenuItem>
                  <MenuItem value="reseller">{t('reseller')}</MenuItem>
                </>
              )}
              {!['user', 'admin', 'superadmin', 'reseller'].includes(level) && (
                <MenuItem value={level}>{t(level)}</MenuItem>
              )}
            </Select>
          </FormControl>

          {isSuperAdmin && (
            <FormControl fullWidth margin="normal">
              <InputLabel>{t('Provider')}</InputLabel>
              <Select
                value={provider || ''}
                onChange={(e) => setProvider(e.target.value)}
                label={t('Provider')}
              >
                <MenuItem value="">{t('None')}</MenuItem>
                {providers?.map((provider: ServiceProvider) => (
                  <MenuItem
                    key={provider.id}
                    value={provider.id}
                    sx={
                      provider.trialProvider
                        ? {
                            fontStyle: 'italic',
                            color: 'text.secondary',
                          }
                        : undefined
                    }
                  >
                    {provider.name}
                    {provider.trialProvider && ` (${t('Trial')})`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </CardContent>
      </Card>

      {error && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {error.message}
        </Alert>
      )}

      {updateSuccessful && (
        <Alert severity="success" sx={{ mt: 2 }}>
          {portalUserId === 'new'
            ? t('Update successful')
            : t('Invitation sent successfully')}
        </Alert>
      )}

      <Button
        type="submit"
        variant="contained"
        sx={{ mt: 2 }}
        disabled={updating || !email}
      >
        {updating ? <CircularProgress size={24} /> : t('Save')}
      </Button>

      {existingUser && (
        <Button
          variant="outlined"
          sx={{ mt: 2, ml: 2 }}
          onClick={handleSendInvitation}
          disabled={sendingInvitation || !email}
        >
          {sendingInvitation ? (
            <CircularProgress size={24} />
          ) : (
            t('Send Invitation')
          )}
        </Button>
      )}

      {existingUser && (
        <CreatedModifiedTable isNew={false} data={existingUser} />
      )}
    </Box>
  );
}
