import { useEffect, useState } from 'react';
import { useFirestore } from 'reactfire';
import { doc, onSnapshot, Timestamp } from 'firebase/firestore';
import { TaskPoolTaskCategory, TranslatableField } from './firebaseModels';
import useUserProvider from './useUserProvider';
import { getCurrentLanguageCode } from '../services/i18n';
import { useTheme } from '@mui/material';
import { useFirebaseUser } from './useFirebaseUser';
import { FirebaseError } from 'firebase/app';

type TTaskPoolTaskCategoryGroupData = {
  status: 'loading' | 'success' | 'error';
  error: Error | null;
  data: TaskPoolTaskCategory | null;
};

/**
 * Custom hook for getting a TaskPoolTaskCategoryTask from Firestore.
 * @param id The ID of the TaskPoolTaskCategoryTask to fetch.
 * @returns TTaskPoolTaskCategoryTaskData
 */
export default function useTaskPoolTaskCategoryDoc(
  id: string
): TTaskPoolTaskCategoryGroupData {
  const firestore = useFirestore();
  const { email } = useFirebaseUser();

  const {
    data: userProviderData,
    status: userProviderStatus,
    providerRef,
  } = useUserProvider();
  const theme = useTheme();

  const defaultLanguage = getCurrentLanguageCode();

  const [status, setStatus] =
    useState<TTaskPoolTaskCategoryGroupData['status']>('loading');
  const [data, setData] =
    useState<TTaskPoolTaskCategoryGroupData['data']>(null);
  const [error, setError] =
    useState<TTaskPoolTaskCategoryGroupData['error']>(null);
  const [nTimeouts, setNTimeouts] = useState(0);
  useEffect(() => {
    if (
      !userProviderData?.id ||
      !providerRef ||
      !email ||
      userProviderStatus !== 'success'
    ) {
      return;
    }

    if (id === 'new') {
      setStatus('success');
      const initialTaskPoolTaskCategory: TaskPoolTaskCategory = {
        title: { [defaultLanguage]: '' } as TranslatableField,
        description: { [defaultLanguage]: '' } as TranslatableField,
        color: theme.palette.text.primary,
        defaultLanguage,
        languages: [defaultLanguage],
        provider: providerRef,
        created: Timestamp.now(),
        createdBy: email,
        amOwner: true,
      };
      setData(initialTaskPoolTaskCategory);
      return;
    }

    const taskPoolTaskCategoryRef = doc(
      firestore,
      'TaskPoolTaskCategories',
      id
    );
    const unsubscribe = onSnapshot(
      taskPoolTaskCategoryRef,
      (doc) => {
        setNTimeouts(0);
        if (doc.exists()) {
          const id = doc.id;
          const amOwner = doc.data().createdBy === email;
          setData({ id, ...doc.data(), amOwner } as TaskPoolTaskCategory);
          setStatus('success');
        } else {
          setStatus('error');
          setError(new Error('TaskPoolTaskCategoryTask not found'));
        }
      },
      (error) => {
        setStatus('error');
        setError(error as FirebaseError);
        setData(null);

        console.error('Error getting TaskPoolTaskCategoryTask snapshot', error);
        // Incremental backoff for retrying
        const retryDelay = 1000 * (nTimeouts + 1);
        setTimeout(() => {
          console.log('useTaskPoolTaskCategoryDoc retrying after delay');
          setNTimeouts((prevTimeouts) => prevTimeouts + 1);
        }, retryDelay);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [
    defaultLanguage,
    email,
    firestore,
    id,
    nTimeouts,
    providerRef,
    theme.palette.text.primary,
    userProviderData?.id,
    userProviderStatus,
  ]);

  return { status, error, data };
}
