import { httpsCallable } from 'firebase/functions';
import { useFunctions } from 'reactfire';
import { useCallback } from 'react';

/**
 * Custom hook to invite users to the app via phone number.
 *
 * @returns {Function} A function that invites users with the following parameters:
 *   - phoneNumber: Phone number in E.164 format (e.g., '+1234567890')
 *   - language: Optional ISO language code (e.g., 'en', 'es')
 *   - timezone: Optional IANA timezone (e.g., 'America/New_York')
 */
export function useInviteAppUser() {
  const functions = useFunctions();

  const inviteAppUser = useCallback(
    /**
     * Invites a user to the app by sending them a message.
     *
     * @param {string} phoneNumber - The user's phone number in E.164 format
     * @param {string} [language] - Optional ISO language code for localization
     * @param {string} [timezone] - Optional IANA timezone identifier
     * @returns {Promise<{ success: boolean; licence_code: string }>} Result containing success status and licence code
     * @throws {Error} If the invitation fails to send
     */
    async (
      phoneNumber: string,
      language?: string,
      timezone?: string
    ): Promise<{
      success: boolean;
      licence_code: string;
      message?: string;
      user_id?: string;
    }> => {
      try {
        const inviteAppUserFunction = httpsCallable(functions, 'inviteappuser');

        const result = await inviteAppUserFunction({
          phone_number: phoneNumber,
          language: language,
          timezone: timezone,
        });

        return result.data as {
          success: boolean;
          licence_code: string;
          user_id?: string;
          message?: string;
        };
      } catch (error: any) {
        console.error('Error inviting user:', error.message);
        throw error;
      }
    },
    [functions]
  );

  return inviteAppUser;
}
