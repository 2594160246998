import { useTranslation } from 'react-i18next';
import { Typography, Button, CircularProgress } from '@mui/material';
import { useMemo } from 'react';
import {
  StatisticDay,
  StatisticsRowDataType,
  statisticTableLabelsAndKeys,
} from './types';
import { calculateRowData } from './calculateRowData';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { StatisticsTableCell } from './StatisticsTableCell';
import Box from '@mui/material/Box';
import { GridToolbar } from '@mui/x-data-grid';

type StatisticsTableTabProps = {
  statisticsSlice: StatisticDay[] | null;
  fromDateString?: string;
  toDateString?: string;
  maxDateAsStr: string;
};

export default function StatisticTableTab(props: StatisticsTableTabProps) {
  const { t } = useTranslation();
  const { statisticsSlice, fromDateString, toDateString, maxDateAsStr } = props;

  const statisticTableData: StatisticsRowDataType[] = useMemo(() => {
    if (!statisticsSlice) {
      return [];
    }
    return statisticTableLabelsAndKeys.map(
      ({ label, key, type, dismissZero, statisticsGroup }, idx) => {
        return {
          id: `statistic-${idx}`,
          label: t(label),
          statisticsGroup,
          type,
          rowNr: idx + 1,
          ...calculateRowData(
            type,
            statisticsSlice,
            key,
            maxDateAsStr,
            dismissZero
          ),
        };
      }
    );
  }, [maxDateAsStr, statisticsSlice, t]);

  const columns: GridColDef<StatisticsRowDataType>[] = useMemo(
    () =>
      [
        {
          field: 'rowNr',
          width: 70,
          headerName: t('Row #'),
        },
        {
          field: 'label',
          headerName: t('Label'),
          flex: 1,
        },
        {
          field: 'min',
          headerName: t('Min'),
          width: 90,
          renderCell: ({ row, colDef }) => {
            const { label, type, min, max, average, nValues } = row;
            return (
              <StatisticsTableCell
                key={`${row.id}-min`}
                type={type}
                label={label}
                index={0}
                min={min}
                max={max}
                average={average}
                nValues={nValues}
                colType="min"
                colDef={colDef}
              />
            );
          },
        },
        {
          field: 'max',
          headerName: t('Max'),
          width: 90,
          renderCell: ({ row, colDef }) => {
            const { label, type, min, max, average, nValues } = row;
            return (
              <StatisticsTableCell
                key={`${row.id}-max`}
                type={type}
                label={label}
                index={0}
                min={min}
                max={max}
                average={average}
                nValues={nValues}
                colType="max"
                colDef={colDef}
              />
            );
          },
        },
        {
          field: 'average',
          headerName: t('Average'),
          width: 90,
          renderCell: ({ row, colDef }) => {
            const { label, type, min, max, average, nValues } = row;
            return (
              <StatisticsTableCell
                key={`${row.id}-average`}
                type={type}
                label={label}
                index={0}
                min={min}
                max={max}
                average={average}
                nValues={nValues}
                colType="average"
                colDef={colDef}
              />
            );
          },
        },
        {
          field: 'nValues',
          headerName: t('N Values'),
          width: 90,
          renderCell: ({ value, colDef }) => (
            <Typography
              sx={{ width: colDef.computedWidth, display: 'inline-block' }}
            >
              {value ? `${value}` : t('No data')}
            </Typography>
          ),
        },
      ] as GridColDef<StatisticsRowDataType>[],
    [t]
  );

  return (
    <Box sx={{ width: '100%', mt: 2 }}>
      <DataGrid
        initialState={{
          sorting: {
            sortModel: [{ field: 'rowNr', sort: 'asc' }],
          },
        }}
        autoHeight
        density="compact"
        columns={columns}
        rows={statisticTableData}
        hideFooter
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        rowSelection={false}
        sx={{ width: '100%' }}
        slots={{
          toolbar: GridToolbar,
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
      />
    </Box>
  );
}
