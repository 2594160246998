import { TherapyPlan } from './firebaseModels';
import useTypedCollection from './useTypedCollection';

export default function useTaskPoolTaskCategoryCollection(
  taskcategorytype: string | null = null,
  languages?: string[]
) {
  return useTypedCollection<TherapyPlan>(
    taskcategorytype,
    'TherapyPlan',
    languages
  );
}
