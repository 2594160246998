import { useEffect, useState } from 'react';
import { useFirestore } from 'reactfire';
import {
  collection,
  onSnapshot,
  and,
  Query,
  query,
  where,
  or,
  QueryCompositeFilterConstraint,
} from 'firebase/firestore';
import useUserProvider from './useUserProvider';
import { useFirebaseUser } from './useFirebaseUser';
import { TypedDoc } from './firebaseModels';

type TypedCollectionState<T extends TypedDoc> = {
  status: 'loading' | 'success' | 'error';
  data: T[] | null;
  error: Error | null;
};

export default function useTypedCollection<T extends TypedDoc>(
  collectionType: string | null = 'default',
  collectionName: string,
  languages?: string[]
): TypedCollectionState<T> {
  const firestore = useFirestore();
  const { email } = useFirebaseUser();

  const { providerRef } = useUserProvider();

  const [status, setStatus] =
    useState<TypedCollectionState<T>['status']>('loading');
  const [data, setData] = useState<TypedCollectionState<T>['data']>(null);

  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (!email || !providerRef) {
      return;
    }
    const collectionRef = collection(firestore, collectionName);
    let collectionQueryConstraints: QueryCompositeFilterConstraint;
    switch (collectionType) {
      case 'own':
        collectionQueryConstraints = and(
          where('createdBy', '==', email),
          where('provider', '!=', null)
        );
        break;
      case 'organization':
        collectionQueryConstraints = and(
          where('provider', '==', providerRef),
          where('public', '==', true)
        );
        break;
      case 'organization_and_default':
        collectionQueryConstraints = or(
          and(where('provider', '==', null), where('public', '==', true)),
          and(where('provider', '==', providerRef), where('public', '==', true))
        );
        break;
      case null:
        collectionQueryConstraints = or(
          and(where('provider', '==', null), where('public', '==', true)),
          where('createdBy', '==', email),
          and(where('provider', '==', providerRef), where('public', '==', true))
        );
        break;
      default:
        collectionQueryConstraints = and(
          where('provider', '==', null),
          where('public', '==', true)
        );
        break;
    }

    if (languages && languages.length > 0) {
      collectionQueryConstraints = and(
        collectionQueryConstraints,
        where('languages', 'array-contains-any', languages)
      );
    }
    const collectionQuery = query(
      collectionRef,
      collectionQueryConstraints
    ) as Query<T>;
    setStatus('loading');
    const unsubscribe = onSnapshot(
      collectionQuery,
      (snapshot) => {
        const snapshotData: T[] = [];
        snapshot.forEach((doc) => {
          const docData = doc.data() as T;
          const amOwner = docData.createdBy === email;
          const snapshotDoc = {
            ...doc.data(),
            id: doc.id,
            amOwner,
          } as T;
          snapshotData.push(snapshotDoc);
        });
        setData(snapshotData);
        setStatus('success');
        setError(null);
      },
      (error) => {
        console.error('Error getting documents: ', error);
        setStatus('error');
        setError(error);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [
    collectionName,
    collectionType,
    email,
    firestore,
    languages,
    providerRef,
  ]);

  return { status, data, error };
}
