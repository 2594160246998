import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useState, useCallback } from 'react';
import { useStorage } from 'reactfire';
import useUserProvider from '../firebase/useUserProvider';
import usePortalUser from '../firebase/usePortalUser';

export function useUpload(file: File | null, basePath: string) {
  const [publicUrl, setPublicUrl] = useState<string | null>(null);

  const storage = useStorage();
  const { data: userProviderData } = useUserProvider();
  const { data: portalUserData } = usePortalUser();

  const uploadFile = useCallback(
    (callback?: (url: string | null) => void) => {
      if (!userProviderData?.id || !portalUserData?.adminUid) {
        return;
      }
      if (file) {
        const imageRef = ref(
          storage,
          `${userProviderData.id}/${portalUserData?.adminUid}/${basePath}/${file.name}`
        );
        const metadata = {
          contentType: file.type,
        };
        uploadBytes(imageRef, file, metadata)
          .then((snapshot) => {
            getDownloadURL(snapshot.ref).then((url) => {
              console.log('downloadURL', url);
              setPublicUrl(url);
              callback && callback(url);
            });
          })
          .catch((err) => {
            console.error(err);
            callback && callback(null);
          });
      } else {
        callback && callback(null);
      }
    },
    [userProviderData?.id, portalUserData?.adminUid, file, storage, basePath]
  );

  return { publicUrl, uploadFile };
}
