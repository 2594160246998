import { Timestamp } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { useFunctions } from 'reactfire';
import { useCallback, useState } from 'react';
import { useCalculateStatisticsDataHash } from './useCalculateStatisticsDataHash';
import usePatientCollection from '../firebase/usePatientCollection';
import { usePatient, useAppUser } from '../contexts/PatientContext';

const DISABLE_TIMEOUT = 1000; // one second

export function useRefreshPatientStatistics(
  therapyCoursePatientId: string | null,
  startDate: Timestamp | null,
  endDate: Timestamp | null
) {
  const { data: patientCollectionData } = usePatientCollection();
  const [lastRefresh, setLastRefresh] = useState<Date | null>(null);
  const [disabled, setDisabled] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const functions = useFunctions();
  const getStatistics = httpsCallable(functions, 'getstatistics');
  const statisticsHash = useCalculateStatisticsDataHash(
    therapyCoursePatientId,
    startDate,
    endDate
  );

  const patient = usePatient(therapyCoursePatientId);
  const appUser = useAppUser(patient);
  const appUserId = appUser?.id;

  const refreshPatientStatistics = useCallback(
    (forceRefresh = false, callback: (success: boolean) => void) => {
      if (
        !therapyCoursePatientId ||
        !statisticsHash ||
        !patientCollectionData ||
        !appUserId ||
        appUser.statistics_cache_updating ||
        disabled ||
        isUpdating
      ) {
        return;
      }

      const lastStatisticsCacheDate = appUser.statisticsCacheDate
        ? appUser.statisticsCacheDate[statisticsHash]
        : null;

      if (
        !forceRefresh &&
        lastStatisticsCacheDate &&
        lastStatisticsCacheDate.toMillis() > Date.now() - 1000 * 60 * 60
      ) {
        return;
      }

      // refresh statistics
      setLastRefresh(new Date());
      setDisabled(true);
      setIsUpdating(true);
      setTimeout(() => {
        setDisabled(false);
      }, DISABLE_TIMEOUT);
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      getStatistics({
        userId: appUserId,
        forceRefresh,
        timezone,
        statisticsHash,
        startDate: startDate?.toDate().toISOString(),
        endDate: endDate?.toDate().toISOString(),
      })
        .then(() => {
          callback(true);
        })
        .catch((error) => {
          console.error('Error refreshing patient statistics', error);
          callback(false);
        })
        .finally(() => {
          setIsUpdating(false);
        });
    },
    [
      therapyCoursePatientId,
      statisticsHash,
      patientCollectionData,
      appUserId,
      appUser?.statistics_cache_updating,
      appUser?.statisticsCacheDate,
      disabled,
      isUpdating,
      getStatistics,
      startDate,
      endDate,
    ]
  );

  return { refreshPatientStatistics, lastRefresh, disabled, isUpdating };
}
