import { useTranslation } from 'react-i18next';
import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CancelIcon from '@mui/icons-material/Cancel';
import { DynamicDialogProps } from './types';

/**
 * Custom DynamicDialog component. Extends MUI's {@link https://mui.com/api/dialog/ | Dialog}.
 *
 * @param props - The props of the component.
 * @returns The component's elements.
 */
export function DynamicDialog(props: DynamicDialogProps) {
  const { dialogData, open, handleCancel, handleConfirm } = props;
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{t(dialogData.title)}</DialogTitle>
      <DialogContent>
        {dialogData.description.map((line, index) => (
          <DialogContentText key={index} id={`line-${index}`}>
            {t(line)}
          </DialogContentText>
        ))}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCancel}
          variant="outlined"
          startIcon={<CancelIcon />}
        >
          {t(dialogData.cancel)}
        </Button>
        <Button
          onClick={handleConfirm}
          autoFocus
          startIcon={dialogData?.confirmIcon}
          variant="contained"
          color={dialogData?.confirmColor}
        >
          {t(dialogData.confirm)}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
