import { Trans, useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { SetStateAction } from 'react';

const LANGUAGES = [
  { code: 'en', name: 'English' },
  { code: 'fi', name: 'Finnish' },
  { code: 'sv', name: 'Swedish' },
];
export interface LanguageSelectorProps {
  disabled?: boolean;
  language: string;
  defaultLanguage: string;
  setLanguage: (value: SetStateAction<string>) => void;
  setDefaultLanguage: (value: SetStateAction<string>) => void;
}
export default function LanguageSelector(props: LanguageSelectorProps) {
  const { t } = useTranslation();

  const {
    disabled,
    language,
    defaultLanguage,
    setLanguage,
    setDefaultLanguage,
  } = props;

  const handleChange = (event: SelectChangeEvent) => {
    setLanguage(event.target.value);
  };

  const isDefaultLanguage = defaultLanguage === language;

  return (
    <Box sx={{ width: 150, mt: 2, ml: 'auto' }}>
      <FormControl fullWidth>
        <InputLabel id="language-select-label">{t('Language')}</InputLabel>
        <Select
          labelId="language-select-label"
          id="language-select"
          value={language}
          label="Language"
          onChange={handleChange}
        >
          {LANGUAGES.map((lang) => (
            <MenuItem key={lang.code} value={lang.code}>
              {defaultLanguage === lang.code ? (
                <em>
                  <Trans>{lang.name}</Trans>
                </em>
              ) : (
                <Trans>{lang.name}</Trans>
              )}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {isDefaultLanguage ? (
        <Box sx={{ mt: 0, textAlign: 'right' }}>
          <Typography variant="caption" color="text.secondary">
            {t('Primary Language')}
          </Typography>
        </Box>
      ) : (
        <Box sx={{ mt: 0, textAlign: 'right' }}>
          <Typography
            variant="caption"
            color={disabled ? 'text.disabled' : 'text.primary'}
            sx={{
              cursor: 'pointer',
              '&:hover': disabled ? {} : { textDecoration: 'underline' },
            }}
            onClick={() => !disabled && setDefaultLanguage(language)}
          >
            {t('Set as Primary Language')}
          </Typography>
        </Box>
      )}
    </Box>
  );
}
