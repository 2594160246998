import { useEffect, useState } from 'react';
import { useFirestore } from 'reactfire';
import uuid from 'react-native-uuid';
import {
  doc,
  getDoc,
  DocumentData,
  DocumentReference,
  onSnapshot,
  Timestamp,
} from 'firebase/firestore';
import {
  ServiceProvider,
  TherapyPlan,
  TherapyPlanPhase,
  TranslatableField,
} from './firebaseModels';
import useUserProvider from './useUserProvider';
import { getCurrentLanguageCode } from '../services/i18n';
import { useTheme } from '@mui/material';
import { useFirebaseUser } from './useFirebaseUser';

export const getEmptyTherapyPlan = (
  createdBy: string,
  defaultLanguage: string,
  serviceProviderId: string,
  providerRef: DocumentReference<ServiceProvider, DocumentData>
) => {
  const newId = uuid.v4() as string;
  const emptyPhases: TherapyPlanPhase[] = [
    {
      id: newId,
      title: { [defaultLanguage]: '' },
      order: 0,
      phaseTasks: [],
    },
  ];

  const emptyTherapyPlan: TherapyPlan = {
    title: { [defaultLanguage]: '' } as TranslatableField,
    description: { [defaultLanguage]: '' } as TranslatableField,
    phases: emptyPhases,
    serviceProviderId,
    defaultLanguage,
    languages: [defaultLanguage],
    createdBy,
    created: Timestamp.now(),
    defaultInterval: 7,
    provider: providerRef,
    amOwner: true,
    public: false,
  };
  return emptyTherapyPlan;
};

type TTherapyPlanData = {
  status: 'loading' | 'success' | 'error';
  error: Error | null;
  data: TherapyPlan | null;
};

/**
 * Custom hook for getting a TherapyPlanTask from Firestore.
 * @param id The ID of the TherapyPlanTask to fetch.
 * @returns TTherapyPlanTaskData
 */
export default function useTherapyPlanDoc(
  id: string,
  idToClone?: string
): TTherapyPlanData {
  const firestore = useFirestore();
  const { email } = useFirebaseUser();

  const {
    data: userProviderData,
    status: userProviderStatus,
    providerRef,
  } = useUserProvider();
  const theme = useTheme();

  const uiLanguage = getCurrentLanguageCode();

  const [status, setStatus] = useState<TTherapyPlanData['status']>('loading');
  const [data, setData] = useState<TTherapyPlanData['data']>(null);
  const [error, setError] = useState<TTherapyPlanData['error']>(null);
  const [nTimeouts, setNTimeouts] = useState(0);
  useEffect(() => {
    if (
      !userProviderData?.id ||
      !providerRef ||
      !email ||
      userProviderStatus !== 'success'
    ) {
      return;
    }

    if (id === 'new') {
      if (idToClone) {
        const duplicateDoc = doc(firestore, 'TherapyPlan', idToClone);
        getDoc(duplicateDoc).then((doc) => {
          setNTimeouts(0);
          const data = doc.data() as TherapyPlan;
          const initialTherapyPlan: TherapyPlan = {
            ...data,
            id: 'new',
            public: false,
            provider: providerRef,
            createdBy: email,
            created: Timestamp.now(),
            modifiedBy: null,
            modified: null,
            amOwner: true,
          };
          setData(initialTherapyPlan);
          setStatus('success');
        });
      } else {
        setStatus('success');
        const initialTherapyPlan = getEmptyTherapyPlan(
          email,
          uiLanguage,
          userProviderData.id,
          providerRef
        );
        setData(initialTherapyPlan);
      }
      return;
    }

    const taskPoolTaskCategoryRef = doc(firestore, 'TherapyPlan', id);
    const unsubscribe = onSnapshot(
      taskPoolTaskCategoryRef,
      (doc) => {
        setNTimeouts(0);
        if (doc.exists()) {
          const id = doc.id;
          const amOwner = doc.data().createdBy === email;
          setData({ id, ...doc.data(), amOwner } as TherapyPlan);
          setStatus('success');
        } else {
          setStatus('error');
        }
      },
      (error) => {
        setError(error);

        // Incremental backoff for retrying
        const retryDelay = 1000 * (nTimeouts + 1);
        setTimeout(() => {
          console.log('useTherapyPlanDoc retrying after delay');
          setNTimeouts((prevTimeouts) => prevTimeouts + 1);
        }, retryDelay);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [
    uiLanguage,
    email,
    firestore,
    id,
    providerRef,
    theme.palette.text.primary,
    userProviderData?.id,
    userProviderStatus,
    idToClone,
    nTimeouts,
  ]);

  return { status, error, data };
}
