import React from 'react';
import { Box } from '@mui/material';

/**
 * Renders the bedtime period (from going to bed to getting up).
 */
export default function BedtimePeriod(props: {
  bedtimeDecimal: number | null;
  gettinguptimeDecimal: number | null;
  dayHeight: number;
  hourWidth: number;
}) {
  const { bedtimeDecimal, gettinguptimeDecimal, dayHeight, hourWidth } = props;

  if (bedtimeDecimal === null || gettinguptimeDecimal === null) {
    return null;
  }
  if (bedtimeDecimal >= gettinguptimeDecimal) {
    return null;
  }

  return (
    <Box
      sx={{
        position: 'absolute',
        backgroundColor: '#c1d5ec',
        alignItems: 'center',
        justifyContent: 'center',
        height: dayHeight,
        left: `${bedtimeDecimal * hourWidth}%`,
        width: `${(gettinguptimeDecimal - bedtimeDecimal) * hourWidth}%`,
      }}
    />
  );
}
