import { useEffect, useState } from 'react';
import { useFirestore } from 'reactfire';
import { collection, query, getDocs, where, orderBy } from 'firebase/firestore';
import { TaskPoolTask } from '../firebase/firebaseModels';

type QuestionnaireEntry = {
  completedTime: Date;
  taskId: string;
  answers: Record<string, any>;
  questionnaireTitle: Record<string, string>;
  questionnaireId: string;
  language: string;
  totalPoints: number;
  totalMaxPoints: number;
  secondaryTotalPoints?: number;
  secondaryTotalMaxPoints?: number;
};

type GenericTaskEntry = {
  completedTime: Date;
  taskId: string;
  answers: Record<string, any>;
  genericTaskTitle: Record<string, string>;
  genericTaskId: string;
  language: string;
  totalPoints: number;
  totalMaxPoints: number;
  secondaryTotalPoints?: number;
  secondaryTotalMaxPoints?: number;
};

type TaskMarking = QuestionnaireEntry | GenericTaskEntry;

export function useTaskMarkingHistory(
  userId: string | null,
  task: TaskPoolTask | null
) {
  const [markings, setMarkings] = useState<TaskMarking[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const firestore = useFirestore();

  useEffect(() => {
    if (!userId || !task) {
      setLoading(false);
      return;
    }

    const fetchMarkings = async () => {
      try {
        setLoading(true);
        const collectionPath =
          task.type === 'questionnaire'
            ? `Diary/${userId}/questionnaires`
            : `Diary/${userId}/generictasks`;

        const markingsRef = collection(firestore, collectionPath);
        const markingsQuery = query(
          markingsRef,
          where(
            task.type === 'questionnaire' ? 'questionnaireId' : 'genericTaskId',
            '==',
            task.id
          ),
          orderBy('completedTime', 'desc')
        );

        const querySnapshot = await getDocs(markingsQuery);
        const taskMarkings: TaskMarking[] = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          completedTime: doc.data().completedTime.toDate(),
        })) as TaskMarking[];

        setMarkings(taskMarkings);
        setError(null);
      } catch (err) {
        setError(
          err instanceof Error ? err : new Error('Unknown error occurred')
        );
      } finally {
        setLoading(false);
      }
    };

    fetchMarkings();
  }, [userId, task, firestore]);

  return { markings, loading, error };
}
