import {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import {
  TaskPoolTaskSection,
  TaskPoolTaskSectionAnswer,
  TaskPoolTaskSectionOptions,
  TaskPoolTaskSectionQuestion,
  TaskPoolTaskSectionType,
  TranslatableField,
} from '../firebase/firebaseModels';
import {
  Button,
  CardHeader,
  IconButton,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { SectionEditorRef } from '../pages/TaskPoolTaskEditor';
import uuid from 'react-native-uuid';
import TranslatableTextField from './TranslatableTextField';
import TranslatableEditor from './TranslatableEditor';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

type Props = {
  disabled?: boolean;
  section: TaskPoolTaskSection;
  defaultLanguage: string;
  language: string;
  onDelete: () => void;
};

/**
 * Editing the TaskPoolTask question section.
 */
const TaskPoolTaskCheckboxSectionEditor = forwardRef<SectionEditorRef, Props>(
  (props: Props, reactRef) => {
    const { t } = useTranslation();
    const { disabled, section, language, defaultLanguage } = props;

    const [questionsValue, setQuestionsValue] = useState<
      TaskPoolTaskSectionQuestion[]
    >([
      {
        id: uuid.v4() as string,
        title: { [language]: '' },
      },
    ]);
    const [answersValue, setAnswersValue] = useState<
      TaskPoolTaskSectionAnswer[]
    >([
      {
        id: uuid.v4() as string,
        title: { [language]: '' },
        value: '',
      },
    ]);
    const [optionsValue, setOptionsValue] =
      useState<TaskPoolTaskSectionOptions>({ firstTimeOnly: false });

    const [title, setTitle] = useState<TranslatableField>({
      [defaultLanguage]: '',
      [language]: '',
    });
    const [description, setDescription] = useState<TranslatableField>({
      [defaultLanguage]: '',
      [language]: '',
    });

    useEffect(() => {
      if (section.questions) {
        setQuestionsValue(section.questions);
        setAnswersValue(
          section.answers || [
            {
              id: uuid.v4() as string,
              title: {
                [defaultLanguage]: '',
                [language]: '',
              },
              value: '',
            },
          ]
        );
        setOptionsValue(section.options || { firstTimeOnly: false });
        setTitle(section.title);
        if (section.description) {
          setDescription(section.description);
        }
      }
    }, [defaultLanguage, language, section]);

    useImperativeHandle(reactRef, () => ({
      sectionType: 'question' as TaskPoolTaskSectionType,
      async save() {
        if (questionsValue.length === 0) {
          return {
            success: true,
            message: 'No questions',
          };
        }
        const newSection = {
          ...section,
          title,
          description,
          questions: questionsValue,
          answers: answersValue,
          options: optionsValue,
        };
        return { success: true, message: 'Success', newSection };
      },
    }));

    const onDeleteSection = useCallback(() => {
      props.onDelete();
    }, [props]);
    const titleExistsInPrimaryLanguage = !!title[defaultLanguage];

    return (
      <Card variant="outlined">
        <CardHeader
          title={
            <Box display="flex" alignItems="center">
              <DragIndicatorIcon sx={{ mr: 1 }} />
              {t('Yes/No Question')}
            </Box>
          }
          action={
            <Button
              variant="outlined"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={onDeleteSection}
              disabled={disabled}
            >
              {t('Delete')}
            </Button>
          }
        />
        <CardContent>
          <TranslatableTextField
            key={`checkbox-${section.id}-title-${language}`}
            name={`checkbox-${section.id}-title-${language}`}
            disabled={disabled}
            label={t('Checkbox Section Title')}
            helperText={
              !titleExistsInPrimaryLanguage
                ? t('Title is required in primary language')
                : undefined
            }
            error={!titleExistsInPrimaryLanguage}
            language={language}
            type="title"
            setValue={setTitle}
            value={title}
            placeholder={t('Enter Title')}
          />
          <TranslatableEditor
            key={`checkbox-${section.id}-description-${language}`}
            name={`checkbox-${section.id}-description-${language}`}
            disabled={disabled}
            placeholder={t('Checkbox Section Description')}
            value={description}
            setValue={setDescription}
            language={language}
            type="description"
          />
          <Box mt={3}>
            <Typography variant="h6">{t('Questions')}</Typography>
            <List disablePadding dense sx={{ mt: 2 }}>
              {questionsValue.map((question, index) => {
                const { title: qTitle } = question;
                const qTitleExistsInPrimaryLanguage = !!qTitle[defaultLanguage];
                return (
                  <ListItem key={`question-${section.id}-${language}-${index}`}>
                    <TranslatableTextField
                      key={`${section.id}-question-${section.id}-${language}-${index}-ttf`}
                      name={`${section.id}-question-${section.id}-${language}-${index}-ttf`}
                      disabled={disabled}
                      label={t('Question Title')}
                      helperText={
                        !qTitleExistsInPrimaryLanguage
                          ? t('Title is required in primary language')
                          : undefined
                      }
                      error={!qTitleExistsInPrimaryLanguage}
                      language={language}
                      type="title"
                      onChange={(e) => {
                        const { value } = e.target;
                        setQuestionsValue((questions) =>
                          questions.map((q, i) =>
                            i === index
                              ? {
                                  ...q,
                                  title: {
                                    ...q.title,
                                    [language]: value,
                                  },
                                }
                              : q
                          )
                        );
                      }}
                      value={qTitle}
                      placeholder={t('Enter Title')}
                    />
                    <IconButton
                      key={`question-${section.id}-${language}-${index}-delete`}
                      aria-label={t('Delete')}
                      disabled={disabled}
                      onClick={() => {
                        setQuestionsValue((questions) =>
                          questions.filter((_, i) => i !== index)
                        );
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItem>
                );
              })}
            </List>
            <Button
              sx={{ ml: 2 }}
              variant="contained"
              disabled={disabled}
              onClick={() => {
                const newId = uuid.v4() as string;
                setQuestionsValue((questions) => [
                  ...questions,
                  {
                    id: newId,
                    title: {
                      [defaultLanguage]: '',
                      [language]: '',
                    },
                  },
                ]);
              }}
            >
              {t('Add Question')}
            </Button>
          </Box>
        </CardContent>
      </Card>
    );
  }
);

export default TaskPoolTaskCheckboxSectionEditor;
