import { useCallback, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from 'reactfire';

export default function Logout() {
  const logout = useLogout();
  useEffect(() => {
    logout();
  }, [logout]);

  return <Navigate to="/login" />;
}

export function useLogout() {
  const auth = useAuth();
  const logout = useCallback(async () => {
    if (auth.currentUser) {
      auth.signOut().then(() => {
        // clear all preloaded observables
        const reactFirePreloadedObservables = (
          globalThis as Record<string, unknown>
        )['_reactFirePreloadedObservables'] as Map<string, unknown> | undefined;
        if (reactFirePreloadedObservables) {
          Array.from(reactFirePreloadedObservables.keys())
            .filter((key) => key.includes('firestore'))
            .forEach((key) => reactFirePreloadedObservables.delete(key));
        }
        console.log('signed out');
      });
    }
  }, [auth]);
  return logout;
}
