import { useTranslation, Trans } from 'react-i18next';
import {
  Paper,
  Typography,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  TextField,
  Autocomplete,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import Alert from '@mui/material/Alert';
import { usePortalUsers } from '../firebase/usePortalUsers';
import { PortalUser, ServiceProvider } from '../firebase/firebaseModels';
import { useMemo, useState, useCallback, useEffect } from 'react';
import usePortalUser from '../firebase/usePortalUser';
import { Link, useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import AddIcon from '@mui/icons-material/Add';
import LinkIcon from '@mui/icons-material/Link';
import Tooltip from '@mui/material/Tooltip';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { doc, deleteDoc } from 'firebase/firestore';
import { useFirestore } from 'reactfire';
import { FirebaseError } from 'firebase/app';

export default function PortalUsers() {
  const { t, i18n } = useTranslation();
  const firestore = useFirestore();
  const {
    status,
    data,
    error: portalUsersError,
    providers,
  } = usePortalUsers() as {
    status: string;
    data: any;
    error: FirebaseError;
    providers: any;
  };
  const { providerRef, isSuperAdmin, isOrganizationAdmin } = usePortalUser();
  const currentLocale = i18n.language;
  const [roleFilter, setRoleFilter] = useState<string>('all');
  const [selectedProvider, setSelectedProvider] = useState<string>('all');

  useEffect(() => {
    if (providerRef?.id) {
      setSelectedProvider(providerRef.id);
    }
  }, [providerRef]);

  const navigate = useNavigate();
  const [error, setError] = useState<Error | null>(null);

  const columns: GridColDef<PortalUser>[] = [
    {
      field: 'email',
      headerName: t('Email'),
      flex: 1,
      valueGetter: (_, row) => row.email,
    },
    {
      field: 'displayName',
      headerName: t('Name'),
      width: 280,
      valueGetter: (_, row) => row.name,
    },
    {
      field: 'role',
      headerName: t('Role'),
      width: 150,
      valueGetter: (_, row) => t(row.level),
    },
    {
      field: 'created',
      type: 'dateTime',
      headerName: t('Created'),
      width: 180,
      valueGetter: (_, row) => row.created?.toDate(),
      renderCell: (params) => {
        return params.value?.toLocaleString(currentLocale) || '';
      },
    },
    {
      field: 'actions',
      type: 'actions',
      width: 100,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      getActions: ({ row }) => {
        const route = `/settings/portalusers/${row.id}`;
        const canEdit =
          (isSuperAdmin ||
            (isOrganizationAdmin && row.provider?.id === providerRef?.id)) &&
          (isSuperAdmin || row.level !== 'superadmin');

        return canEdit
          ? [
              <Tooltip title={t('Edit')}>
                <GridActionsCellItem
                  icon={<EditIcon />}
                  label={t('Edit')}
                  onClick={() => navigate(route)}
                  color="inherit"
                />
              </Tooltip>,
              <Tooltip title={t('Delete')}>
                <GridActionsCellItem
                  icon={<DeleteIcon />}
                  label={t('Delete')}
                  onClick={() => handleDelete(row.id!, row.email)}
                  color="inherit"
                />
              </Tooltip>,
            ]
          : [<></>];
      },
    },
  ];

  const filteredData = useMemo(() => {
    if (!data) return [];

    return data.filter((user: PortalUser) => {
      const matchesRole = roleFilter === 'all' || user.level === roleFilter;
      const matchesProvider =
        selectedProvider === 'all' || user.provider?.id === selectedProvider;

      return matchesRole && matchesProvider;
    });
  }, [data, roleFilter, selectedProvider]);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteId, setDeleteId] = useState<string | null>(null);
  const [dialogTitle, setDialogTitle] = useState<string | null>(null);
  const [dialogContent, setDialogContent] = useState<string | null>(null);

  const handleDelete = useCallback(
    (userId: string, userEmail: string) => {
      setDeleteId(userId);
      setDialogTitle(t('Delete User') + ' ' + userEmail);
      setDialogContent(t('Are you sure you want to delete this user?'));
      setDialogOpen(true);
    },
    [t]
  );

  const handleCloseDialog = useCallback(() => {
    setDialogOpen(false);
  }, []);

  const confirmDelete = useCallback(async () => {
    setDialogOpen(false);
    if (!deleteId) return;

    try {
      const userRef = doc(firestore, 'PortalUser', deleteId);
      await deleteDoc(userRef);
    } catch (err) {
      setError(err as Error);
      console.error('Error deleting user:', err);
    }
  }, [deleteId, firestore]);

  if (status === 'error') {
    return (
      <Paper variant="outlined" sx={{ my: { xs: 3 }, p: { xs: 2, md: 3 } }}>
        <Typography variant="h5" component="h2" sx={{ mb: 2 }}>
          {t('Portal Users')}
        </Typography>
        <Alert severity="error">
          <Typography variant="body1">{t(portalUsersError.message)}</Typography>
        </Alert>
      </Paper>
    );
  }

  if (
    status === 'loading' ||
    (!isSuperAdmin && !providerRef) ||
    !data ||
    (isSuperAdmin && !providers)
  ) {
    return <CircularProgress />;
  }

  return (
    <Paper variant="outlined" sx={{ my: { xs: 3 }, p: { xs: 2, md: 3 } }}>
      <Typography variant="h5" component="h2" sx={{ mb: 2 }}>
        {t('Portal Users')}
      </Typography>
      {error ? (
        <Alert severity="error" sx={{ mb: 2 }}>
          <Typography variant="body1">
            <Trans>There was an error loading the data</Trans>
          </Typography>
          {portalUsersError.code === 'permission-denied' && (
            <Typography variant="body2">
              <Trans>
                You do not have permission to view this data. Please contact
                your administrator.
              </Trans>
            </Typography>
          )}
        </Alert>
      ) : (
        <>
          <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
            <FormControl sx={{ minWidth: 200 }}>
              <InputLabel id="role-filter-label">
                {t('Filter by Role')}
              </InputLabel>
              <Select
                labelId="role-filter-label"
                value={roleFilter}
                label={t('Filter by Role')}
                onChange={(e) => setRoleFilter(e.target.value)}
              >
                <MenuItem value="all">{t('All Roles')}</MenuItem>
                <MenuItem value="admin">{t('admin')}</MenuItem>
                <MenuItem value="user">{t('user')}</MenuItem>
                {isSuperAdmin && (
                  <MenuItem value="superadmin">{t('superadmin')}</MenuItem>
                )}
              </Select>
            </FormControl>

            {isSuperAdmin && providers && (
              <FormControl sx={{ minWidth: 300 }}>
                <Autocomplete
                  value={
                    selectedProvider === 'all'
                      ? null
                      : providers.find(
                          (p: ServiceProvider) => p.id === selectedProvider
                        )
                  }
                  onChange={(_, newValue) => {
                    setSelectedProvider(newValue ? newValue.id : 'all');
                  }}
                  options={providers}
                  getOptionLabel={(option) =>
                    typeof option === 'string'
                      ? option
                      : option.name +
                        (option.trialProvider ? ` (${t('Trial')})` : '')
                  }
                  renderOption={(props, option) => (
                    <MenuItem
                      {...props}
                      key={option.id}
                      sx={
                        option.trialProvider
                          ? {
                              fontStyle: 'italic',
                              color: 'text.secondary',
                            }
                          : undefined
                      }
                    >
                      {option.name}
                      {option.trialProvider && ` (${t('Trial')})`}
                    </MenuItem>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('Provider')}
                      placeholder={t('Search provider')}
                    />
                  )}
                />
              </FormControl>
            )}
          </Stack>

          <DataGrid
            initialState={{
              sorting: {
                sortModel: [{ field: 'email', sort: 'desc' }],
              },
            }}
            autoHeight
            rows={filteredData}
            columns={columns}
            rowSelection={false}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
          />

          {(isSuperAdmin || isOrganizationAdmin) && (
            <Button
              component={Link}
              to="/settings/portalusers/new"
              variant="contained"
              startIcon={<AddIcon />}
              sx={{ mt: 2 }}
            >
              {t('New User')}
            </Button>
          )}

          <Dialog
            open={dialogOpen}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {dialogTitle || <Trans>Delete User</Trans>}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {dialogContent || (
                  <Trans>Are you sure you want to delete this user?</Trans>
                )}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog} color="primary">
                <Trans>Cancel</Trans>
              </Button>
              <Button onClick={confirmDelete} color="primary" autoFocus>
                <Trans>Confirm</Trans>
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Paper>
  );
}
