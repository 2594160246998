import React, { useMemo } from 'react';
import LaydownPeriod from './LaydownPeriod';
import DiaryPeriodLabel from './DiaryPeriodLabel';
import BedtimePeriod from './BedtimePeriod';
import SleeptimePeriod from './SleeptimePeriod';

/**
 * Renders a diary period component visualizing sleep-related time periods.
 * This component combines LaydownPeriod, BedtimePeriod, SleeptimePeriod, and DiaryPeriodLabel
 * to create a visual representation of sleep data.
 *
 * @param props.laydownDecimal - Time when the user laid down (in decimal hours)
 * @param props.bedtimeDecimal - Time when the user went to bed (in decimal hours)
 * @param props.gettinguptimeDecimal - Time when the user got up (in decimal hours)
 * @param props.dayHeight - Height of the day component in pixels
 * @param props.hourWidth - Width of an hour in pixels
 * @param props.sleeptimeDecimal - Time when the user fell asleep (in decimal hours)
 * @param props.wakeuptimeDecimal - Time when the user woke up (in decimal hours)
 * @param props.label - Label for the diary period
 */
export function DiaryPeriod(props: {
  laydownDecimal?: number | null;
  bedtimeDecimal?: number | null;
  gettinguptimeDecimal?: number | null;
  dayHeight: number;
  hourWidth: number;
  sleeptimeDecimal?: number | null;
  wakeuptimeDecimal?: number | null;
  label?: string | null;
}) {
  const {
    laydownDecimal = null,
    bedtimeDecimal = null,
    gettinguptimeDecimal = null,
    dayHeight,
    hourWidth,
    sleeptimeDecimal = null,
    wakeuptimeDecimal = null,
    label = null,
  } = props;

  const right = useMemo(() => {
    return (wakeuptimeDecimal || gettinguptimeDecimal || 24) * hourWidth;
  }, [wakeuptimeDecimal, gettinguptimeDecimal, hourWidth]);

  const left = useMemo(() => {
    return (sleeptimeDecimal || bedtimeDecimal || 0) * hourWidth;
  }, [sleeptimeDecimal, bedtimeDecimal, hourWidth]);

  const width = right - left;

  // render the diary period component
  return (
    <>
      <LaydownPeriod
        laydownDecimal={laydownDecimal}
        bedtimeDecimal={bedtimeDecimal}
        dayHeight={dayHeight}
        hourWidth={hourWidth}
      />
      <BedtimePeriod
        bedtimeDecimal={bedtimeDecimal}
        gettinguptimeDecimal={gettinguptimeDecimal}
        dayHeight={dayHeight}
        hourWidth={hourWidth}
      />
      <SleeptimePeriod
        sleeptimeDecimal={sleeptimeDecimal}
        wakeuptimeDecimal={wakeuptimeDecimal}
        dayHeight={dayHeight}
        hourWidth={hourWidth}
      />
      <DiaryPeriodLabel
        label={label}
        dayHeight={dayHeight}
        left={left}
        width={width}
      />
    </>
  );
}
