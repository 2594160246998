import { useEffect, useState } from 'react';
import { useFirestore } from 'reactfire';
import { doc, onSnapshot } from 'firebase/firestore';
import { StatisticsCache } from './firebaseModels';
import { FirebaseError } from 'firebase/app';

type TStatisticsCacheDocData = {
  status: 'loading' | 'success' | 'error';
  error: FirebaseError | null;
  data: StatisticsCache | null;
};

/**
 * Custom hook for getting a StatisticsCache from Firestore.
 * @param cacheDocId The ID of the StatisticsCache to fetch.
 * @returns TStatisticsCacheData
 */
export default function useStatisticsCacheDoc(
  cacheDocId: string | null,
  userId: string | null
): TStatisticsCacheDocData {
  const firestore = useFirestore();

  const [status, setStatus] =
    useState<TStatisticsCacheDocData['status']>('loading');
  const [data, setData] = useState<TStatisticsCacheDocData['data']>(null);
  const [error, setError] = useState<FirebaseError | null>(null);
  const [nTimeouts, setNTimeouts] = useState(0);
  useEffect(() => {
    if (!userId || !cacheDocId) {
      return;
    }

    const cacheRef = doc(
      firestore,
      'Users',
      userId,
      'StatisticsCache',
      cacheDocId
    );

    const unsubscribe = onSnapshot(
      cacheRef,
      (doc) => {
        setNTimeouts(0);
        if (doc.exists()) {
          setData(doc.data() as StatisticsCache);
          setStatus('success');
        } else {
          setStatus('success');
          setData(null);
        }
      },
      (error) => {
        setStatus('error');
        setError(error as FirebaseError);
        console.error('Error getting StatisticsCache: ', error);
        // Incremental backoff for retrying
        const retryDelay = 1000 * (nTimeouts + 1);
        setTimeout(() => {
          console.log('useStatisticsCacheDoc retrying after delay');
          setNTimeouts((prevTimeouts) => prevTimeouts + 1);
        }, retryDelay);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [firestore, cacheDocId, nTimeouts, userId]);

  return { status, error, data };
}
