import { UserData } from '../components/UserData';
import { UserPassword } from '../components/UserPassword';

export default function Settings() {
  return (
    <>
      <UserData />
      <UserPassword />
    </>
  );
}
