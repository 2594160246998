import {useMemo} from 'react';
import {getTimeAsDecimal} from './statUtil';

/**
 * Calculates the decimal values for the diary data.
 * @param laydowntime The laydown time.
 * @param cutDateAsStr The cut-off date as a string in the format 'YYYY-MM-DD'.
 * @param defaultDecimal The default decimal value.
 * @param bedtime The bedtime.
 * @param sleeptime The sleeptime.
 * @param wakeuptime The wakeuptime.
 * @param gettinguptime The gettinguptime.
 * @returns The decimal values for the diary data.
 */
export function useTimesAsDecimal(
  laydowntime: Date | null,
  cutDateAsStr: string,
  defaultDecimal: number,
  bedtime: Date | null,
  sleeptime: Date | null,
  wakeuptime: Date | null,
  gettinguptime: Date | null,
) {
  return useMemo(() => {
    const laydowntimeDecimal = getTimeAsDecimal(
      laydowntime,
      cutDateAsStr,
      defaultDecimal,
    );

    const bedtimeDecimal = getTimeAsDecimal(
      bedtime,
      cutDateAsStr,
      defaultDecimal,
    );
    const sleeptimeDecimal = getTimeAsDecimal(
      sleeptime,
      cutDateAsStr,
      defaultDecimal,
    );
    const wakeuptimeDecimal = getTimeAsDecimal(
      wakeuptime,
      cutDateAsStr,
      defaultDecimal,
    );
    const gettinguptimeDecimal = getTimeAsDecimal(
      gettinguptime,
      cutDateAsStr,
      defaultDecimal,
    );
    return {
      laydowntimeDecimal,
      bedtimeDecimal,
      gettinguptimeDecimal,
      sleeptimeDecimal,
      wakeuptimeDecimal,
    };
  }, [
    laydowntime,
    cutDateAsStr,
    defaultDecimal,
    bedtime,
    sleeptime,
    wakeuptime,
    gettinguptime,
  ]);
}
