import { useTranslation } from 'react-i18next';
import { Grid, Typography, useTheme } from '@mui/material';
import { useCallback } from 'react';
import {
  TherapyCourse,
  TherapyCoursePhase,
} from '../../firebase/firebaseModels';
import { getTranslatableField } from '../../services/i18n';
import { LoadingButton } from '@mui/lab';
import MoveDownIcon from '@mui/icons-material/MoveDown';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import EndCourseIcon from '@mui/icons-material/Stop';

type PhaseControlsProps = {
  phase: TherapyCoursePhase;
  phaseIndex: number;
  course: TherapyCourse;
  onActivatePhase: (phase: TherapyCoursePhase, index: number) => void;
  onConfirmEndCourse: () => void;
  loading?: boolean;
};

export default function PhaseControls(props: PhaseControlsProps) {
  const { t } = useTranslation();
  const { palette } = useTheme();

  const { phase, phaseIndex, course, onActivatePhase, loading } = props;

  const courseIsActive = course.ongoingPhase !== null;
  const courseCompleted = course.completed;
  const ongoingPhase = course.ongoingPhase == null ? -1 : course.ongoingPhase;

  const isOngoingPhase = ongoingPhase === phaseIndex;
  // const isFirstPhase = phaseIndex === 0;
  const isLastPhase = phaseIndex === course.phases.length - 1;
  const isCompletedPhase = ongoingPhase > phaseIndex;

  const { startDate, endDate: phaseEndDate } = phase;

  const nextPhase = !isLastPhase ? course.phases[phaseIndex + 1] : null;
  const nextPhaseStartDate = nextPhase?.startDate;

  const endDate = isLastPhase ? phaseEndDate : nextPhaseStartDate;

  const activatePhaseButtonDisabled =
    isOngoingPhase ||
    (ongoingPhase !== phaseIndex - 1 && ongoingPhase !== phaseIndex + 1);

  const activatePhase = useCallback(() => {
    onActivatePhase(phase, phaseIndex);
  }, [onActivatePhase, phase, phaseIndex]);

  const startDateString = startDate
    ? startDate.toDate().toLocaleDateString()
    : '';
  const endDateString = endDate ? endDate.toDate().toLocaleDateString() : '';

  const dateIntervalString = `${startDateString} - ${endDateString}`;

  const endDateIsInThePast = endDate && endDate.toMillis() < Date.now();

  const translatedTitle =
    getTranslatableField(phase.title) ||
    t('Phase {{phase}}', { phase: phaseIndex + 1 });
  return (
    <>
      <Typography variant="h6">{translatedTitle}</Typography>
      <Typography variant="body2" color="textSecondary">
        {courseCompleted
          ? t('Course completed')
          : isOngoingPhase
          ? t('Ongoing phase')
          : isCompletedPhase
          ? t('Completed phase')
          : t('Upcoming phase')}
      </Typography>

      <Grid container sx={{ mt: 2 }}>
        <Grid item sm={6} md={5} lg={3}>
          <Typography variant="body2">{t('Planned Date Interval')}</Typography>
        </Grid>
        <Grid item sm={6} md={7} lg={9}>
          <Typography
            variant="body2"
            sx={
              isOngoingPhase && endDateIsInThePast
                ? { color: palette.warning.main }
                : undefined
            }
          >
            {dateIntervalString}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {isOngoingPhase && endDateIsInThePast && (
            <Typography variant="body2" color={palette.warning.main}>
              {t('End date is in the past')}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <LoadingButton
            variant={
              isCompletedPhase || courseCompleted ? 'outlined' : 'contained'
            }
            color="primary"
            loading={loading}
            onClick={activatePhase}
            disabled={activatePhaseButtonDisabled || loading}
            startIcon={isCompletedPhase ? <MoveUpIcon /> : <MoveDownIcon />}
            sx={{ mt: 2 }}
          >
            {t('Activate this phase')}
          </LoadingButton>
          {isLastPhase && isOngoingPhase && (
            <LoadingButton
              variant="contained"
              color="primary"
              loading={loading}
              onClick={props.onConfirmEndCourse}
              disabled={!courseIsActive || loading}
              startIcon={<EndCourseIcon />}
              sx={{ mt: 2, ml: 2 }}
            >
              {t('Complete therapy course')}
            </LoadingButton>
          )}
        </Grid>
      </Grid>
    </>
  );
}
