import { useTranslation } from 'react-i18next';
import { localeFormatTime, minutesToHoursAndMinutes } from '../../utils';
import Typography from '@mui/material/Typography';
import { GridStateColDef } from '@mui/x-data-grid/models/colDef/gridColDef';
import { useMemo } from 'react';

type Props = {
  type: 'time' | 'duration' | 'percentage';
  label: string;
  index: number;
  min: number | Date | null;
  max: number | Date | null;
  average?: number | Date | null;
  nValues?: number;
  colType: 'min' | 'max' | 'average';
  colDef?: GridStateColDef;
};
export function StatisticsTableCell(props: Props) {
  const { i18n } = useTranslation();

  const { type, min, max, average, colDef } = props;
  const { computedWidth } = colDef || {};

  let { minText, maxText, averageText } = useMemo(() => {
    let minText = '';
    let maxText = '';
    let averageText = '';
    switch (type) {
      case 'time': {
        minText = localeFormatTime(i18n.language, min);
        maxText = localeFormatTime(i18n.language, max);
        averageText = localeFormatTime(i18n.language, average);
        break;
      }
      case 'duration': {
        minText = min !== null ? minutesToHoursAndMinutes(min as number) : '';
        maxText = max !== null ? minutesToHoursAndMinutes(max as number) : '';
        averageText =
          average !== null ? minutesToHoursAndMinutes(average as number) : '';
        break;
      }
      case 'percentage': {
        minText = min !== null ? `${Math.round(min as number)}%` : '';
        maxText = max !== null ? `${Math.round(max as number)}%` : '';
        averageText =
          average !== null ? `${Math.round(average as number)}%` : '';
        break;
      }
      default:
        break;
    }
    return { minText, maxText, averageText };
  }, [i18n.language, min, max, average, type]);

  switch (props.colType) {
    case 'min': {
      return (
        <Typography sx={{ width: computedWidth, display: 'inline-block' }}>
          {minText}
        </Typography>
      );
    }
    case 'max': {
      return (
        <Typography sx={{ width: computedWidth, display: 'inline-block' }}>
          {maxText}
        </Typography>
      );
    }
    case 'average': {
      return (
        <Typography sx={{ width: computedWidth, display: 'inline-block' }}>
          {averageText}
        </Typography>
      );
    }
    default:
      return null;
  }
}
