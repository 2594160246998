import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { useState, useCallback } from 'react';
import { useStorage } from 'reactfire';
import useUserProvider from '../firebase/useUserProvider';

type ProgressiveUploadParams = {
  onFinished?: (url: string) => void;
  onError?: (error: Error) => void;
  onProgress?: (progress: number) => void;
};
export function useProgressiveUpload(file: File | null, baseUrl: string) {
  const storage = useStorage();
  const [progress, setProgress] = useState<number | null>(null);
  const [error, setError] = useState<Error | null>(null);

  const { data: userProviderData } = useUserProvider();

  const progressiveUpload = useCallback(
    async (params?: ProgressiveUploadParams) => {
      if (!userProviderData?.id || !file) {
        return;
      }
      const { onFinished, onError, onProgress } = params || {};

      const storageRef = ref(
        storage,
        `${userProviderData?.id}/${baseUrl}/${file.name}`
      );

      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(progress);
          onProgress && onProgress(progress);
          console.log('Upload is ' + progress + '% done');
        },
        (error) => {
          onError && onError(error);
          setError(error);
          console.error('Error uploading audio', error);
        },
        () => {
          setProgress(null);
        }
      );
      const snapshot = await uploadTask;
      const url = await getDownloadURL(snapshot.ref);
      onFinished && onFinished(url);
      return url;
    },
    [userProviderData?.id, file, storage, baseUrl]
  );

  return { progressiveUpload, progress, error };
}
