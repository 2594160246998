import { useMemo } from 'react';
import {
  TaskPoolTask,
  TaskPoolTaskCategory,
  TherapyPlan,
  PortalUser,
} from '../firebase/firebaseModels';
import { useTranslation } from 'react-i18next';

export function useCreatedModified(
  data: TaskPoolTask | TaskPoolTaskCategory | TherapyPlan | PortalUser | null
): {
  createdBy: string;
  created: Date | string;
  modifiedBy: string | undefined;
  modified: Date | undefined;
} {
  const { t } = useTranslation();

  return useMemo(() => {
    const createdBy = data?.createdBy || t('Administrator');
    const created = data?.created?.toDate() || t('Unknown Date');
    const modifiedBy = data?.modifiedBy || undefined;
    const modified = data?.modified?.toDate() || undefined;
    return {
      createdBy,
      created,
      modifiedBy,
      modified,
    };
  }, [data?.created, data?.createdBy, data?.modified, data?.modifiedBy, t]);
}
