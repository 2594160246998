import {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {
  TaskPoolTaskSection,
  TaskPoolTaskSectionType,
  TranslatableField,
} from '../firebase/firebaseModels';
import { Box, CardHeader } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { SaveFuncParams, SectionEditorRef } from '../pages/TaskPoolTaskEditor';
import TranslatableTextField from './TranslatableTextField';
import TranslatableEditor from './TranslatableEditor';
import Button from '@mui/material/Button';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
type Props = {
  disabled?: boolean;
  section: TaskPoolTaskSection;
  language: string;
  defaultLanguage: string;
  onDelete: () => void;
};

/**
 * Editing the TaskPoolTask Info Popup section.
 */
const TaskPoolTaskInfoPopupSectionEditor = forwardRef<SectionEditorRef, Props>(
  (props: Props, reactRef) => {
    const { t } = useTranslation();
    const { disabled, section, language, defaultLanguage } = props;

    const [content, setContent] = useState<TranslatableField>({
      [defaultLanguage]: '',
      [language]: '',
    });
    const [title, setTitle] = useState<TranslatableField>({
      [defaultLanguage]: '',
      [language]: '',
    });
    const [description, setDescription] = useState<TranslatableField>({
      [defaultLanguage]: '',
      [language]: '',
    });

    useEffect(() => {
      if (section.content) {
        setContent(section.content as TranslatableField);
        setTitle(section.title);
        if (section.description) {
          setDescription(section.description);
        }
      }
    }, [section]);

    const saveFunc = useCallback(async (): Promise<SaveFuncParams> => {
      if (!content) {
        return {
          success: false,
          message: 'No content',
        };
      }
      const newSection = {
        ...section,
        title,
        description,
        content,
      };
      return { success: true, message: '', newSection };
    }, [content, section, title, description]);

    useImperativeHandle(reactRef, () => ({
      sectionType: 'infoPopup' as TaskPoolTaskSectionType,
      save: saveFunc,
    }));

    const onDeleteSection = useCallback(() => {
      props.onDelete();
    }, [props]);

    const titleExistsInPrimaryLanguage = !!title[defaultLanguage];

    return (
      <Card variant="outlined">
        <CardHeader
          title={
            <Box display="flex" alignItems="center">
              <DragIndicatorIcon sx={{ mr: 1 }} />
              {t('Info Popup')}
            </Box>
          }
          action={
            <Button
              variant="outlined"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={onDeleteSection}
              disabled={disabled}
            >
              {t('Delete')}
            </Button>
          }
        />
        <CardContent>
          <TranslatableTextField
            key={`infoPopup-title-${language}-${section.id}`}
            name={`infoPopup-title-${language}-${section.id}`}
            disabled={disabled}
            label={t('Info Popup Section Title')}
            helperText={
              !titleExistsInPrimaryLanguage
                ? t('Title is required in primary language')
                : undefined
            }
            error={!titleExistsInPrimaryLanguage}
            language={language}
            type="title"
            setValue={setTitle}
            value={title}
            placeholder={t('Enter Title')}
          />
          <TranslatableEditor
            key={`infoPopup-description-${language}-${section.id}`}
            name={`infoPopup-description-${language}-${section.id}`}
            disabled={disabled}
            placeholder={t('Info Popup Section Description')}
            value={description}
            setValue={setDescription}
            language={language}
            type="description"
          />
          <TranslatableEditor
            key={`infoPopup-content-${language}-${section.id}`}
            name={`infoPopup-content-${language}-${section.id}`}
            disabled={disabled}
            placeholder={t('Info Popup Section Content')}
            value={content}
            setValue={setContent}
            language={language}
            type="content"
          />
        </CardContent>
      </Card>
    );
  }
);

export default TaskPoolTaskInfoPopupSectionEditor;
