import { Trans, useTranslation } from 'react-i18next';
import {
  Alert,
  Box,
  CircularProgress,
  Typography,
  Link as MuiLink,
  AlertTitle,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { doc, updateDoc, Timestamp } from 'firebase/firestore';
import { useCallback, useEffect, useState } from 'react';
import { TherapyCourse, TherapyCoursePhase } from '../firebase/firebaseModels';
import { useParams } from 'react-router-dom';
import useTherapyCourseDoc from '../firebase/useTherapyCourseDoc';
import useUserProvider from '../firebase/useUserProvider';
import { useFirebaseUser } from '../firebase/useFirebaseUser';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { TabContext, TabPanel } from '@mui/lab';
import { useFirestore } from 'reactfire';
// import { useUpdatePatientCourses } from '../firebase/useUpdatePatientCourses';
import { DynamicDialog } from '../components/DynamicDialog';
import { DynamicDialogData } from '../components/types';
import { Link } from 'react-router-dom';
import PhasePatients from '../components/TherapyCourseOverview/PhasePatients';
import PhaseControls from '../components/TherapyCourseOverview/PhaseControls';

const dialogDatas: { [key: string]: DynamicDialogData } = {
  confirm: {
    title: 'Confirm',
    description: ['Are you sure you want to end this therapy course?'],
    cancel: 'Cancel',
    confirm: 'Confirm',
    confirmColor: 'error',
    confirmIcon: null,
  },
  restart: {
    title: 'Confirm',
    description: ['Are you sure you want to restart this therapy course?'],
    cancel: 'Cancel',
    confirm: 'Confirm',
    confirmColor: 'error',
    confirmIcon: null,
  },
};

export default function TherapyCourseOverview() {
  const { t } = useTranslation();
  const { therapyCourseId } = useParams<{ therapyCourseId: string }>();
  const firestore = useFirestore();

  const { email } = useFirebaseUser();
  const {
    data: userProviderData,
    error: userProviderError,
    status: userProviderStatus,
  } = useUserProvider();
  const [dialogType, setDialogType] = useState<'confirm' | 'restart'>(
    'confirm'
  );
  const [dialogData, setDialogData] = useState<DynamicDialogData>(
    dialogDatas.confirm
  );
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const {
    status: therapyCourseStatus,
    data: therapyCourseData,
    error: therapyCourseError,
  } = useTherapyCourseDoc(therapyCourseId || null);
  const [currentPhase, setCurrentPhase] = useState<number | null>(null);
  const [currentPatient, setCurrentPatient] = useState<number | null>(null);

  const [updating, setUpdating] = useState(false);
  const [updateSuccessful, setUpdateSuccessful] = useState(false);

  // const { updatePatientCourses, updateError, resetUpdateStatus } =
  //   useUpdatePatientCourses();

  const handlePhaseChange = useCallback(
    (phase: TherapyCoursePhase, index: number) => {
      if (
        !therapyCourseId ||
        !therapyCourseData ||
        !userProviderData?.id ||
        !email ||
        updating
      ) {
        return;
      }
      const hasNextPhase = index < therapyCourseData.phases.length - 1;
      const nextPhase = hasNextPhase
        ? therapyCourseData.phases[index + 1]
        : null;
      const nextPhaseStartDate = nextPhase?.startDate || null;
      const phaseEndDate = phase?.endDate;

      const updateData: Partial<TherapyCourse> = {
        ongoingPhase: index,
        nextPhaseStartDate: nextPhaseStartDate,
        phaseEndDate: phaseEndDate,
        completed: false,
        completedDate: null,
      };

      const therapyCourseRef = doc(
        firestore,
        'ServiceProvider',
        userProviderData.id,
        'UserData',
        email,
        'TherapyCourse',
        therapyCourseId
      );
      setUpdating(true);
      updateDoc(therapyCourseRef, updateData)
        .then(async () => {
          // const therapyCourse = {
          //   ...therapyCourseData,
          //   ...updateData,
          //   id: therapyCourseId,
          // } as TherapyCourse;
          // delete therapyCourse.patients;
          // if (therapyCourseData.patients) {
          //   await updatePatientCourses(
          //     therapyCourse,
          //     therapyCourseData.patients
          //   );
          // }
          setUpdating(false);
          setUpdateSuccessful(true);
        })
        .catch((error) => {
          console.error('Error updating document: ', error);
          setUpdating(false);
        });
    },
    [
      therapyCourseData,
      userProviderData?.id,
      email,
      therapyCourseId,
      updating,
      firestore,
    ]
  );

  const handleConfirmEndCourse = useCallback(() => {
    if (
      !therapyCourseId ||
      !therapyCourseData ||
      !userProviderData?.id ||
      !email ||
      updating
    ) {
      return;
    }

    const updateData: Partial<TherapyCourse> = {
      ongoingPhase: null,
      completed: true,
      completedDate: Timestamp.now(),
      nextPhaseStartDate: null,
      phaseEndDate: null,
    };

    const therapyCourseRef = doc(
      firestore,
      'ServiceProvider',
      userProviderData.id,
      'UserData',
      email,
      'TherapyCourse',
      therapyCourseId
    );
    setUpdating(true);
    updateDoc(therapyCourseRef, updateData)
      .then(async () => {
        // const therapyCourse = {
        //   ...therapyCourseData,
        //   ...updateData,
        //   id: therapyCourseId,
        // } as TherapyCourse;
        // delete therapyCourse.patients;
        // if (therapyCourseData.patients) {
        //   await updatePatientCourses(therapyCourse, therapyCourseData.patients);
        // }
        setUpdating(false);
        setUpdateSuccessful(true);
      })
      .catch((error) => {
        console.error('Error updating document: ', error);
        setUpdating(false);
      });
  }, [
    therapyCourseData,
    userProviderData?.id,
    email,
    therapyCourseId,
    updating,
    firestore,
  ]);

  const handleConfirm = useCallback(() => {
    setConfirmDialogOpen(false);
    if (dialogType === 'confirm') {
      handleConfirmEndCourse();
    } else {
      handlePhaseChange(therapyCourseData?.phases[0] as TherapyCoursePhase, 0);
    }
  }, [
    dialogType,
    handleConfirmEndCourse,
    handlePhaseChange,
    therapyCourseData,
  ]);

  const { ongoingPhase, phases } = therapyCourseData || {};

  const onActivatePhase = useCallback(
    (phase: TherapyCoursePhase, index: number) => {
      if (!therapyCourseData) return;
      if (therapyCourseData.completed) {
        setDialogType('restart');
        setDialogData(dialogDatas['restart']);
        setConfirmDialogOpen(true);
        return;
      } else {
        handlePhaseChange(phase, index);
      }
    },
    [handlePhaseChange, therapyCourseData]
  );

  const onConfirmEndCourse = useCallback(() => {
    setDialogType('confirm');
    setDialogData(dialogDatas['confirm']);
    setConfirmDialogOpen(true);
  }, []);
  useEffect(() => {
    if (!therapyCourseData) return;
    if (currentPhase === null) {
      if (ongoingPhase !== null && ongoingPhase !== undefined) {
        setCurrentPhase(ongoingPhase);
      } else {
        setCurrentPhase(0);
      }
    }
  }, [currentPhase, therapyCourseData, ongoingPhase, phases]);

  const editRoute = `/therapycourses/${therapyCourseId}`;

  if (userProviderStatus === 'error') {
    return (
      <Alert severity="error">
        <AlertTitle>{t('Error loading therapy course')}</AlertTitle>
        <Typography variant="body1" component="p">
          {t('User provider data not found')}
        </Typography>
        <Typography variant="body1" component="p">
          {userProviderError?.message}
        </Typography>
      </Alert>
    );
  }

  if (userProviderStatus === 'loading') {
    return <CircularProgress sx={{ mt: 2 }} />;
  }
  return (
    <>
      {(!therapyCourseId || therapyCourseStatus === 'loading') && (
        <CircularProgress sx={{ mt: 2 }} />
      )}
      {updateSuccessful && (
        <Alert
          onClose={() => setUpdateSuccessful(false)}
          severity="success"
          sx={{ mt: 2 }}
        >
          {t('Therapy course updated')}
        </Alert>
      )}
      {therapyCourseError && (
        <Alert
          onClose={() => setUpdateSuccessful(false)}
          severity="error"
          sx={{ mt: 2 }}
        >
          <Trans>{therapyCourseError.message}</Trans>
        </Alert>
      )}
      {/* {updateError && (
        <Alert severity="error" sx={{ mt: 2 }} onClose={resetUpdateStatus}>
          <AlertTitle>{t('Error updating therapy course')}</AlertTitle>
          <Typography variant="body1" component="p">
            {t('An error occurred while updating the therapy course')}
          </Typography>
          <Typography variant="body1" component="p">
            {updateError.message}
          </Typography>
        </Alert>
      )} */}
      {therapyCourseId && therapyCourseStatus === 'success' && (
        <>
          <Box sx={{ mt: 2 }}>
            <EditIcon color="primary" sx={{ verticalAlign: 'middle' }} />
            <MuiLink component={Link} to={editRoute} sx={{ ml: 1 }}>
              {t('Edit Therapy Course')}
            </MuiLink>
          </Box>
          <TabContext value={`${currentPhase || 0}`}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
                backgroundColor: 'background.paper',
                mt: 2,
              }}
            >
              <Tabs
                aria-label="phases"
                value={`${currentPhase || 0}`}
                variant="scrollable"
                sx={{ overflowX: 'auto' }}
              >
                {phases?.map((phase, index) => (
                  <Tab
                    key={phase.id}
                    label={`${t('Phase')} ${index + 1}`}
                    onClick={() => setCurrentPhase(index)}
                    value={`${index}`}
                    sx={{
                      minWidth: 100,
                      fontWeight: ongoingPhase === index ? 'bold' : 'normal',
                    }}
                  />
                ))}
              </Tabs>
            </Box>
            <Box sx={{ backgroundColor: 'background.paper' }}>
              {phases?.map((phase, index) => (
                <TabPanel value={`${index}`} key={phase.id}>
                  <PhaseControls
                    phase={phase}
                    phaseIndex={index}
                    course={therapyCourseData as TherapyCourse}
                    onActivatePhase={onActivatePhase}
                    onConfirmEndCourse={onConfirmEndCourse}
                    loading={updating}
                  />
                </TabPanel>
              ))}
            </Box>
          </TabContext>
          {phases && (
            <PhasePatients
              key={`${currentPhase || 0}`}
              therapyCourseId={therapyCourseId}
              phase={phases[currentPhase || 0]}
              phaseIndex={currentPhase || 0}
              course={therapyCourseData as TherapyCourse}
              currentPatient={currentPatient}
              setCurrentPatient={setCurrentPatient}
            />
          )}
        </>
      )}
      <DynamicDialog
        dialogData={dialogData}
        open={confirmDialogOpen}
        handleCancel={() => setConfirmDialogOpen(false)}
        handleConfirm={handleConfirm}
      />
    </>
  );
}
