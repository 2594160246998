import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { Trans } from 'react-i18next';
import { useCreatedModified } from '../hooks/useCreatedModified';
import {
  TaskPoolTask,
  TaskPoolTaskCategory,
  TherapyPlan,
  PortalUser,
} from '../firebase/firebaseModels';

type CreatedModifiedTableProps = {
  isNew: boolean;
  data: TaskPoolTask | TaskPoolTaskCategory | TherapyPlan | PortalUser | null;
};

export default function CreatedModifiedTable(props: CreatedModifiedTableProps) {
  const { isNew, data } = props;
  const { created, createdBy, modified, modifiedBy } = useCreatedModified(data);

  return (
    <Table
      sx={{ minWidth: 650, mt: 2 }}
      size="small"
      aria-label="info table"
      padding="none"
    >
      {!isNew && created && (
        <TableRow>
          <TableCell
            sx={{ minWidth: 80, borderBottom: 0 }}
            size="small"
            variant="head"
          >
            <Typography variant="caption">
              <Trans>Created</Trans>
            </Typography>
          </TableCell>
          <TableCell
            sx={{ minWidth: 130, width: '100%', borderBottom: 0 }}
            size="small"
          >
            <Typography variant="caption">
              {`${created.toLocaleString()} (${createdBy})`}
            </Typography>
          </TableCell>
        </TableRow>
      )}

      {modified && (
        <TableRow>
          <TableCell size="small" variant="head" sx={{ borderBottom: 0 }}>
            <Typography variant="caption">
              <Trans>Modified</Trans>
            </Typography>
          </TableCell>
          <TableCell sx={{ borderBottom: 0 }} size="small">
            <Typography variant="caption">
              {`${modified.toLocaleString()} (${modifiedBy})`}
            </Typography>
          </TableCell>
        </TableRow>
      )}
    </Table>
  );
}
