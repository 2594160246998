import React, { createContext, useEffect, useMemo, useState } from 'react';
import {
  DocumentData,
  DocumentReference,
  onSnapshot,
} from 'firebase/firestore';
import { ServiceProvider } from '../firebase/firebaseModels';
import usePortalUser from '../firebase/usePortalUser';
import { FirebaseError } from 'firebase/app';

export type TUserProvider = {
  status: 'loading' | 'error' | 'success';
  error: Error | null;
  data: ServiceProvider | null;
  userData: ReturnType<typeof usePortalUser>['data'];
  providerRef: DocumentReference<ServiceProvider, DocumentData> | null;
};

export const UserProviderContext = createContext<TUserProvider | null>(null);

export function UserProviderProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [status, setStatus] = useState<TUserProvider['status']>('loading');
  const [data, setData] = useState<TUserProvider['data']>(null);
  const [error, setError] = useState<TUserProvider['error']>(null);
  const [nTimeouts, setNTimeouts] = useState(0);

  const {
    data: portalUserData,
    status: userStatus,
    error: userError,
  } = usePortalUser();

  useEffect(() => {
    if (userStatus === 'error') {
      setStatus('error');
      setError(userError);
    }
  }, [userStatus, userError]);

  const providerRef = useMemo(
    () => portalUserData?.provider || null,
    [portalUserData]
  );

  useEffect(() => {
    if (!providerRef || !portalUserData) {
      return;
    }

    const unsubscribe = onSnapshot(
      providerRef,
      (doc) => {
        setNTimeouts(0);
        if (doc.exists()) {
          setData({
            ...(doc.data() as ServiceProvider),
            id: doc.id,
          });
          setStatus('success');
        } else {
          setStatus('error');
          setError(new Error('Provider not found'));
        }
      },
      (error) => {
        setStatus('error');
        setError(error as FirebaseError);
        setData(null);

        console.error('Error getting UserProvider snapshot', error);
        // Incremental backoff for retrying
        const retryDelay = 1000 * (nTimeouts + 1);
        setTimeout(() => {
          console.log('UserProviderProvider retrying after delay');
          setNTimeouts((prevTimeouts) => prevTimeouts + 1);
        }, retryDelay);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [providerRef, portalUserData, nTimeouts]);

  const value = { status, error, data, userData: portalUserData, providerRef };

  return (
    <UserProviderContext.Provider value={value}>
      {children}
    </UserProviderContext.Provider>
  );
}
