import React from 'react';
import { Box } from '@mui/material';

/**
 * Renders the laydown period (from laying down to going to bed).
 */
export default function LaydownPeriod(props: {
  laydownDecimal: number | null;
  bedtimeDecimal: number | null;
  dayHeight: number;
  hourWidth: number;
}) {
  const { laydownDecimal, bedtimeDecimal, dayHeight, hourWidth } = props;

  if (
    laydownDecimal === null ||
    bedtimeDecimal === null ||
    laydownDecimal >= bedtimeDecimal
  ) {
    return null;
  }

  return (
    <Box
      sx={{
        position: 'absolute',
        height: dayHeight,
        left: `${laydownDecimal * hourWidth}%`,
        width: `${(bedtimeDecimal - laydownDecimal) * hourWidth}%`,
        backgroundColor: '#f0f0f0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    />
  );
}
