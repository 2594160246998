import React from 'react';
import { Box } from '@mui/material';

/**
 * Renders the sleep time period (from falling asleep to waking up).
 */
export default function SleeptimePeriod(props: {
  sleeptimeDecimal: number | null;
  wakeuptimeDecimal: number | null;
  dayHeight: number;
  hourWidth: number;
}) {
  const { sleeptimeDecimal, wakeuptimeDecimal, dayHeight, hourWidth } = props;

  if (
    sleeptimeDecimal === null ||
    wakeuptimeDecimal === null ||
    sleeptimeDecimal >= wakeuptimeDecimal
  ) {
    return null;
  }

  return (
    <Box
      sx={{
        position: 'absolute',
        height: dayHeight,
        left: `${sleeptimeDecimal * hourWidth}%`,
        width: `${(wakeuptimeDecimal - sleeptimeDecimal) * hourWidth}%`,
        backgroundColor: '#3f71b3',
      }}
    />
  );
}
