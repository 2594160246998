import { useContext } from 'react';
import { PortalUserContext, TUserData } from '../contexts/PortalUserContext';

export default function usePortalUser(): TUserData {
  const context = useContext(PortalUserContext);
  if (context === null) {
    throw new Error('usePortalUser must be used within a PortalUserProvider');
  }
  return context;
}
