import * as React from 'react';
const fillColor = '#5E72E4';

/**
 * The "Oneiros" logo in SVG format.
 *
 * @param props  SVG props
 * @returns  JSX.Element
 */
const SVGLogo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 334 58"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_103_258)">
      <mask
        id="mask0_103_258"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="334"
        height="58"
      >
        <path d="M333.76 0H0V57.36H333.76V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_103_258)">
        <path
          d="M111.13 23.11C113.22 14.53 120.04 9.68 129.07 9.68C138.1 9.68 145.03 14.41 147.12 23.11H111.13ZM110.8 32.46H158.12V28.61C158.12 12.11 146.23 0 129.06 0C111.89 0 99.79 12.11 99.79 28.62C99.79 45.13 111.9 57.23 129.06 57.23C141.72 57.23 151.62 50.74 155.8 40.61H144.24C141.05 45.34 136.1 47.54 129.05 47.54C119.58 47.54 112.32 42.15 110.78 32.46M168.53 57.35H178.99V0H168.53V57.36V57.35ZM321.97 16.72H332.32C332.11 7.37 324.08 0 311.09 0C298.1 0 289.74 7.26 289.74 17.94C289.74 30.38 301.19 32.14 310.65 33.35C317.47 34.23 323.2 34.89 323.2 40.06C323.2 44.35 319.13 47.76 311.2 47.76C303.27 47.76 299.42 44.57 299.2 40.06H288.63C288.96 49.96 297.54 57.23 311.19 57.23C324.84 57.23 333.75 50.08 333.75 39.07C333.75 26.63 322.19 24.87 312.73 23.66C306.02 22.78 300.29 22.23 300.29 17.06C300.29 12.77 304.25 9.47 311.19 9.47C318.13 9.47 321.65 12.55 321.98 16.73"
          fill={props.fill || fillColor}
        />
        <path
          d="M28.61 10.24V0C12.81 0 0 12.81 0 28.61C0 44.41 12.81 57.23 28.62 57.23C44.43 57.23 57.15 44.5 57.23 28.77H46.99C46.91 38.85 38.71 47 28.62 47C18.53 47 10.24 38.77 10.24 28.62C10.24 18.47 18.46 10.24 28.61 10.24Z"
          fill={props.fill || fillColor}
        />
        <path
          d="M44.32 5.04C44.32 2.26 42.06 0 39.28 0C36.5 0 34.24 2.26 34.24 5.04C34.24 7.82 36.5 10.08 39.28 10.08C42.06 10.08 44.32 7.82 44.32 5.04Z"
          fill={props.fill || fillColor}
        />
        <path
          d="M92.8 57.23V22.91C92.8 10.26 82.54 0 69.89 0C57.24 0 46.98 10.26 46.98 22.91H57.24C57.24 15.92 62.9 10.26 69.89 10.26C76.88 10.26 82.54 15.92 82.54 22.91V57.23H92.79H92.8Z"
          fill={props.fill || fillColor}
        />
        <path
          d="M252.67 10.24V0C268.47 0 281.29 12.81 281.29 28.61C281.29 44.41 268.48 57.23 252.67 57.23C236.86 57.23 224.14 44.5 224.06 28.77H234.3C234.38 38.85 242.58 47 252.67 47C262.76 47 271.05 38.77 271.05 28.62C271.05 18.47 262.82 10.24 252.67 10.24Z"
          fill={props.fill || fillColor}
        />
        <path
          d="M236.97 5.04C236.97 2.26 239.23 0 242.01 0C244.79 0 247.05 2.26 247.05 5.04C247.05 7.82 244.79 10.08 242.01 10.08C239.23 10.08 236.97 7.82 236.97 5.04Z"
          fill={props.fill || fillColor}
        />
        <path
          d="M188.49 57.23V22.91C188.49 10.26 198.75 0 211.4 0C224.05 0 234.31 10.26 234.31 22.91H224.05C224.05 15.92 218.39 10.26 211.4 10.26C204.41 10.26 198.75 15.92 198.75 22.91V57.23H188.5H188.49Z"
          fill={props.fill || fillColor}
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_103_258">
        <rect width="334" height="58" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SVGLogo;
