import { Typography, SxProps, Theme } from '@mui/material';
import usePortalUser from '../firebase/usePortalUser';

interface UserNameProps {
  sx?: SxProps<Theme>;
}

/**
 * Displays the current user's name.
 *
 * @returns  JSX.Element
 */
export default function UserName({ sx }: UserNameProps) {
  const { data: portalUserData, status: userStatus } = usePortalUser();

  if (userStatus !== 'success') {
    return null;
  }

  return (
    <Typography variant="body1" component="p" sx={sx}>
      {portalUserData?.name}
    </Typography>
  );
}
