import React, { useMemo } from 'react';
import { getDateDiaryData, getTimeAsDecimal } from './statUtil';
import { DiaryPeriod } from './DiaryPeriod';
import { StatisticDay } from '../TherapyCourseOverview/types';

/**
 * Renders the main diary period for a given date, including bedtime, sleeptime, wakeuptime, and gettinguptime.
 * @param statisticDate The date to render the diary period for.
 * @param cutDateAsStr The cut-off date as a string in the format 'YYYY-MM-DD'.
 * @param statistics The array of statistics to search for the given date.
 * @param dayHeight The height of the day in pixels.
 * @param hourWidth The width of an hour in pixels.
 * @param isNextDay Whether the diary period is for the next day.
 * @returns The rendered diary period component.
 */
export function WakePeriod({
  statisticDate,
  cutDateAsStr,
  statistics,
  dayHeight,
  hourWidth,
}: {
  statisticDate: Date;
  cutDateAsStr: string;
  statistics: StatisticDay[];
  dayHeight: number;
  hourWidth: number;
}) {
  return useMemo(() => {
    const { sleeptime, wakeuptime, night_nonsleep, wakeups } = getDateDiaryData(
      statisticDate,
      statistics
    );

    if (!sleeptime || !wakeuptime || !night_nonsleep) {
      return null;
    }

    const midSleepTime = new Date(
      (sleeptime.getTime() + wakeuptime.getTime()) / 2
    );
    const halfNonsleepMs = night_nonsleep * 30 * 1000; // Convert minutes to milliseconds
    const nonsleepStart = new Date(midSleepTime.getTime() - halfNonsleepMs);
    const nonsleepEnd = new Date(midSleepTime.getTime() + halfNonsleepMs);

    const defaultDecimal = 0;
    const nonsleepStartDecimal = getTimeAsDecimal(
      nonsleepStart,
      cutDateAsStr,
      defaultDecimal
    );
    const nonsleepEndDecimal = getTimeAsDecimal(
      nonsleepEnd,
      cutDateAsStr,
      defaultDecimal
    );

    return (
      <DiaryPeriod
        key={`${cutDateAsStr}-wakeperiod`}
        bedtimeDecimal={nonsleepStartDecimal}
        gettinguptimeDecimal={nonsleepEndDecimal}
        dayHeight={dayHeight}
        hourWidth={hourWidth}
        label={wakeups ? `${wakeups}` : undefined}
      />
    );
  }, [statisticDate, cutDateAsStr, statistics, dayHeight, hourWidth]);
}
