import { useTranslation, Trans } from 'react-i18next';
import {
  Box,
  TextField,
  Typography,
  Autocomplete,
  Grid,
  Alert,
  Tooltip,
  CircularProgress,
  Tabs,
  Tab,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import AddIcon from '@mui/icons-material/Add';
import { useCallback, useMemo, useState } from 'react';
import { doc, DocumentReference } from 'firebase/firestore';
import { Patient } from '../firebase/firebaseModels';
import { useAddPatient } from '../firebase/useAddPatient';
import usePatientCollection from '../firebase/usePatientCollection';
import { useFirestore } from 'reactfire';
import useUserProvider from '../firebase/useUserProvider';
import { useInviteAppUser } from '../firebase/useInviteAppUser';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { styled } from '@mui/material/styles';
import { getCurrentLanguageCode } from '../services/i18n';
import PendingIcon from '@mui/icons-material/Pending';

interface PatientManagerProps {
  patients: DocumentReference<Patient>[];
  setPatients: (patients: DocumentReference<Patient>[]) => void;
  email: string | null;
  isGroup?: boolean;
  onPatientChange?: (patients: DocumentReference<Patient>[]) => void;
  disabled?: boolean;
}

interface PatientOption {
  id: string;
  label: string;
  pending?: boolean;
}

const StyledPhoneInput = styled(PhoneInput)`
  .PhoneInput {
    width: 100%;
  }

  .PhoneInputInput {
    height: 56px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;
    padding: 16.5px 14px;
    font-family: inherit;
    font-size: 1rem;
    width: 100%;
    background: none;

    &:hover {
      border-color: rgba(0, 0, 0, 0.87);
    }

    &:focus {
      outline: none;
      border-width: 2px;
      border-color: ${(props) => props.theme.palette.primary.main};
      padding: 15.5px 13px;
    }

    &:disabled {
      background-color: rgba(0, 0, 0, 0.12);
      border-color: rgba(0, 0, 0, 0.38);
    }
  }
`;

export default function PatientManager({
  patients,
  setPatients,
  email,
  isGroup = false,
  onPatientChange,
  disabled = false,
}: PatientManagerProps) {
  const { t } = useTranslation();
  const firestore = useFirestore();
  const { data: userProviderData } = useUserProvider();
  const [licenceCode, setLicenceCode] = useState('');
  const [remark, setRemark] = useState('');
  const [addMethod, setAddMethod] = useState(0);
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>('');
  const [inviteError, setInviteError] = useState<string | null>(null);
  const [inviteWarning, setInviteWarning] = useState<string | null>(null);

  const inviteAppUser = useInviteAppUser();

  const { status: patientCollectionStatus, data: patientCollectionData } =
    usePatientCollection();

  // console.log('patientCollectionData', patientCollectionData);
  const {
    addPatient,
    addPatientError,
    isUpdating: isAddingPatient,
  } = useAddPatient(email);

  const onAddPatient = useCallback(
    async (licenceCode: string, remark: string) => {
      addPatient(licenceCode, remark, (newPatient) => {
        setLicenceCode('');
        setRemark('');
        setPatients([...patients, newPatient]);
        if (onPatientChange) {
          onPatientChange([...patients, newPatient]);
        }
      });
    },
    [addPatient, patients, setPatients, onPatientChange]
  );

  const patientOptions = useMemo(
    () =>
      patientCollectionData
        ?.filter((p) => p.id)
        .map((p) => ({
          id: p.id as string,
          label: `${p.licenceCode} / ${p.remark}`,
          pending: p.pending,
        })) || [],
    [patientCollectionData]
  );

  const patientValues = useMemo(() => {
    if (!patientCollectionData) {
      return isGroup ? [] : { id: '', label: '' };
    }

    if (!isGroup) {
      const p = patientCollectionData.find((pcp) => pcp.id === patients[0]?.id);
      return {
        id: p?.id || '',
        label: p?.id ? `${p?.licenceCode} / ${p?.remark}` : '',
      };
    }

    return patients
      .map((patient) => {
        const p = patientCollectionData.find((pcp) => pcp.id === patient.id);
        if (!p) {
          return { id: '', label: '' };
        }
        return {
          id: p.id as string,
          label: `${p.licenceCode} / ${p.remark}`,
        };
      })
      .filter((p) => p.id !== '');
  }, [patientCollectionData, patients, isGroup]);

  const handlePatientChange = useCallback(
    (event: React.SyntheticEvent, newValue: any) => {
      if (!userProviderData || !email) {
        return;
      }
      const newPatients = isGroup
        ? newValue.map(
            (v: { id: string }) =>
              doc(
                firestore,
                'ServiceProvider',
                userProviderData.id,
                'UserData',
                email,
                'Patient',
                v.id
              ) as DocumentReference<Patient>
          )
        : newValue?.id
        ? [
            doc(
              firestore,
              'ServiceProvider',
              userProviderData.id,
              'UserData',
              email,
              'Patient',
              newValue.id
            ) as DocumentReference<Patient>,
          ]
        : [];

      setPatients(newPatients);
      if (onPatientChange) {
        onPatientChange(newPatients);
      }
    },
    [userProviderData, isGroup, firestore, email, setPatients, onPatientChange]
  );

  const handleInviteUser = useCallback(async () => {
    if (!phoneNumber) return;
    try {
      setInviteError(null);
      setInviteWarning(null);
      const result = await inviteAppUser(
        phoneNumber,
        getCurrentLanguageCode(),
        Intl.DateTimeFormat().resolvedOptions().timeZone
      );
      if (result.success) {
        onAddPatient(result.licence_code, remark);
        setPhoneNumber('');
        if (result.message) {
          setInviteWarning(result.message);
        }
      } else if (result.message) {
        setInviteError(result.message);
      }
    } catch (error: any) {
      console.error(error);
      setInviteError(error.message);
    }
  }, [phoneNumber, remark, inviteAppUser, onAddPatient]);

  const defaultCountry = useMemo(() => {
    const langCode = getCurrentLanguageCode();
    switch (langCode) {
      case 'fi':
        return 'FI';
      case 'sv':
        return 'SE';
      default:
        return 'US';
    }
  }, []);

  const hasPendingPatients = useMemo(() => {
    if (!patientCollectionData) return false;
    return patients.some((patient) => {
      const patientData = patientCollectionData.find(
        (p) => p.id === patient.id
      );
      return patientData?.pending;
    });
  }, [patients, patientCollectionData]);

  if (!userProviderData) {
    return <CircularProgress />;
  }

  return (
    <Box>
      <Typography variant="body1" sx={{ mb: 2 }}>
        {t(
          'Add a patient to the course either by inviting them or by selecting an existing patient.'
        )}
      </Typography>

      <Autocomplete<PatientOption, boolean>
        multiple={isGroup}
        id="patients-outlined"
        loading={patientCollectionStatus === 'loading'}
        options={patientOptions}
        value={patientValues}
        onChange={handlePatientChange}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        disabled={disabled}
        renderOption={(props, option) => (
          <li {...props}>
            {option.pending && (
              <PendingIcon
                sx={{
                  mr: 1,
                  color: 'warning.main',
                  fontSize: '1.2rem',
                }}
              />
            )}
            {option.label}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            name="patients"
            variant="outlined"
            label={t('Patients')}
            placeholder={t('Add...')}
          />
        )}
      />

      {hasPendingPatients && (
        <Alert severity="warning" sx={{ mt: 2 }}>
          {t(
            'One or more selected patients have not yet registered in the app.'
          )}
        </Alert>
      )}

      <Tabs
        value={addMethod}
        onChange={(_, newValue) => setAddMethod(newValue)}
        variant="fullWidth"
        sx={{
          mb: 3,
          mt: 3,
          bgcolor: 'background.paper',
          borderRadius: 1,
          '& .MuiTab-root': {
            color: 'text.secondary',
            '&.Mui-selected': {
              color: 'primary.contrastText',
              bgcolor: 'primary.main',
            },
          },
        }}
      >
        <Tab label={t('Invite New Patient')} />
        <Tab label={t('Add Existing Patient')} />
      </Tabs>

      {addMethod === 0 ? (
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <StyledPhoneInput
                international
                defaultCountry={defaultCountry}
                value={phoneNumber}
                onChange={(value: string | undefined) =>
                  setPhoneNumber(value || '')
                }
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={9}>
              <TextField
                name="personal-identifier"
                label={t('Personal Identifier')}
                value={remark}
                onChange={(event) => setRemark(event.target.value)}
                fullWidth
                disabled={disabled}
              />
            </Grid>
          </Grid>
          <LoadingButton
            disabled={!phoneNumber || disabled}
            loading={isAddingPatient}
            variant="contained"
            startIcon={<AddIcon />}
            sx={{ mt: 2 }}
            onClick={handleInviteUser}
          >
            {t('Invite Patient')}
          </LoadingButton>
          {inviteError && (
            <Alert severity="error" sx={{ mt: 2 }}>
              <Trans>{inviteError}</Trans>
            </Alert>
          )}
          {inviteWarning && (
            <Alert severity="warning" sx={{ mt: 2 }}>
              <Trans>{inviteWarning}</Trans>
            </Alert>
          )}
        </Box>
      ) : (
        <>
          <Typography variant="caption" sx={{ mt: 2 }}>
            {t('or enter manually')}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Tooltip
                title={t('The license code that has given to the patient')}
              >
                <TextField
                  name="licence-code"
                  label={t('License Code to Add')}
                  value={licenceCode}
                  onChange={(event) => setLicenceCode(event.target.value)}
                  fullWidth
                  disabled={disabled}
                />
              </Tooltip>
            </Grid>
            <Grid item xs={9}>
              <Tooltip
                title={t(
                  'Information used to distinguish or identify an individual, without necessarily being as specific or formal as full legal names or government-issued identification numbers.'
                )}
              >
                <TextField
                  name="personal-identifier"
                  label={t('Personal Identifier')}
                  value={remark}
                  onChange={(event) => setRemark(event.target.value)}
                  fullWidth
                  disabled={disabled}
                />
              </Tooltip>
            </Grid>
          </Grid>
          <LoadingButton
            disabled={!licenceCode || disabled}
            loading={isAddingPatient}
            variant="contained"
            startIcon={<AddIcon />}
            sx={{ mt: 2 }}
            onClick={() => onAddPatient(licenceCode, remark)}
          >
            {t('Add Patient')}
          </LoadingButton>
        </>
      )}

      {addPatientError && (
        <Alert severity="error" sx={{ mt: 2 }}>
          <Trans>{addPatientError.message}</Trans>
        </Alert>
      )}
    </Box>
  );
}
